import { fetchMedia } from "api";
import {
  genderAtom,
  mediaStatusQuery,
  riskStatusQuery,
  timestampsQuery,
  userStatusQuery,
} from "components";
import { MediaType } from "constant";
import { MediaListParams } from "dto";
import { selector } from "recoil";
import { setRecoil } from "recoil-nexus";
import { getErrorMessage } from "utils/functions";
import { errorState, pageSizeAtom, requestIdState } from "./common";
import { pageAtom } from "./search";

export const photosResQuery = selector({
  key: "photos/resp",
  get: async ({ get }) => {
    get(requestIdState);

    const timestamps = get(timestampsQuery);

    const params: MediaListParams = {
      page: get(pageAtom),
      pageSize: get(pageSizeAtom),
      genders: [get(genderAtom)],
      status: get(mediaStatusQuery),
      types: [MediaType.Photo],
      userStatus: get(userStatusQuery),
      userRiskStatus: get(riskStatusQuery),
      createdStart: timestamps.createdStart,
      createdEnd: timestamps.createdEnd,
    };

    try {
      const resp = await fetchMedia(params);
      return resp.data;
    } catch (error) {
      setRecoil(errorState, getErrorMessage(error));
    }
  },
});

export const photosQuery = selector({
  key: "photos",
  get: ({ get }) => {
    return get(photosResQuery)?.data ?? [];
  },
});

export const photosTotalQuery = selector({
  key: "photos/total",
  get: ({ get }) => {
    return get(photosResQuery)?.page?.total ?? 0;
  },
});

export const voicesRespQuery = selector({
  key: "voices/resp",
  get: async ({ get }) => {
    get(requestIdState);

    const params: MediaListParams = {
      page: get(pageAtom),
      pageSize: get(pageSizeAtom),
      genders: [get(genderAtom)],
      status: get(mediaStatusQuery),
      types: [MediaType.Voice],
    };

    try {
      const resp = await fetchMedia(params);
      return resp.data;
    } catch (error) {
      setRecoil(errorState, getErrorMessage(error));
    }
  },
});

export const voicesQuery = selector({
  key: "voices",
  get: ({ get }) => {
    return get(voicesRespQuery)?.data ?? [];
  },
});

export const voicesTotalQuery = selector({
  key: "voices/total",
  get: ({ get }) => {
    return get(voicesRespQuery)?.page?.total ?? 0;
  },
});
