import {
  Button,
  FormControl,
  FormHelperText,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  Text,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import dayjs from "dayjs";
import { useRef, useState } from "react";
import { useSetRecoilState } from "recoil";
import { getErrorMessage } from "utils/functions";
import { blockCIDR } from "./ip.api";
import { cidrsRequestIdAtom } from "./ip.state";

export function AddCIDRToBlacklistButton() {
  const toast = useToast();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const initialRef = useRef(null);

  const [isLoading, toggleLoading] = useState(false);
  const [cidr, setCidr] = useState("");

  const setRequestId = useSetRecoilState(cidrsRequestIdAtom);

  const handleBlock = async () => {
    toggleLoading(true);

    try {
      await blockCIDR(cidr);
      toggleLoading(false);
      setRequestId(dayjs().valueOf());
      setCidr("");
      onClose();
    } catch (error) {
      // handle error
      toggleLoading(false);
      toast({
        title: getErrorMessage(error),
        status: "error",
      });
    }
  };

  return (
    <>
      <Button onClick={onOpen}>Add CIDR</Button>

      <Modal
        isOpen={isOpen}
        onClose={onClose}
        initialFocusRef={initialRef}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Block CIDR</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <FormControl>
              <FormLabel>CIDR</FormLabel>
              <Input
                placeholder="0.0.0.0/0 or 2001:0000::/0"
                value={cidr}
                onChange={(e) => setCidr(e.target.value)}
                ref={initialRef}
              />
              <FormHelperText>
                <Stack>
                  <Text>Example </Text>
                  <Text>Ipv4: 129.168.50.1/24</Text>
                  <Text>Ipv6: 2001:0000:0000/24</Text>
                </Stack>
              </FormHelperText>
            </FormControl>
          </ModalBody>

          <ModalFooter>
            <Button
              mr={3}
              variant="ghost"
              onClick={onClose}>
              Cancel
            </Button>
            <Button
              isLoading={isLoading}
              onClick={handleBlock}>
              Block
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}
