import { startCase } from "lodash";

// 从给定的url中获取path的最后一部分
// url: https://google.com/abc?def=ghi
// return: abc?def=ghi
export const getLastComponentsFromUrl = (url: string): string => {
  return url.substring(url.lastIndexOf("/") + 1);
};

// 从href中获取最后的url分段
// input: /abc/def?ghi=jkm
// output: def
export function getLastSegmentFromHref(href: string): string {
  const pathname = new URL(href).pathname;
  return getLastSegmentFrom(pathname);
}

// 从头pathname中获取最后的url组件分段
// input: /abc/def?ghi=jkm
// output: def
export function getLastSegmentFrom(pathname: string): string {
  const parts = pathname.split("/");
  const lastSegment = parts.pop() || parts.pop();
  return lastSegment ?? "";
}

export const getTitleFromPathname = (pathname: string) => {
  return startCase(getLastSegmentFrom(pathname));
};

export const openInNewTab = (url: string) => {
  window.open(url, "_blank");
};
