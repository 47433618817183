import { HStack, ListItem, Text, UnorderedList } from "@chakra-ui/react";
import { ReportStatus } from "constant";
import { ReportItem } from "dto";
import { timestampMsToDateStr } from "utils/functions";
import { UserId } from "./link/UserId";

export function ReportsList(props: ReportsListProps) {
  return (
    <UnorderedList
      spacing={2}
      p={4}>
      {props.reports.map((item, index) => (
        <ListItem
          key={index}
          opacity={item.status === ReportStatus.Pending ? "1.0" : "0.4"}>
          <Text>{item.reason}</Text>
          <HStack
            fontSize="xs"
            opacity="0.6">
            <Text>By</Text>
            <UserId
              uid={item.uid}
              isExternal
            />
            <Text>@{timestampMsToDateStr(item.createdAt)}</Text>
          </HStack>
        </ListItem>
      ))}
    </UnorderedList>
  );
}
interface ReportsListProps {
  reports: ReportItem[];
}
