import { Box, RangeSlider, RangeSliderFilledTrack, RangeSliderThumb, RangeSliderTrack, Tooltip } from "@chakra-ui/react";
import { number, object } from "@recoiljs/refine";
import { useState } from "react";
import { atom, useRecoilState } from "recoil";
import { syncEffect } from "recoil-sync";

interface ScoreRange {
  min: number,
  max: number
}

const refiner = object({
  min: number(),
  max: number()
})

export const riskScoreRangeAtom = atom<ScoreRange>({
  key: "riskScoreRange",
  default: { min: 0, max: 1.1 },
  effects: [syncEffect({ refine: refiner })]
});

// RiskScoreFilter
export function RiskScoreFilter() {
  const [range, setRange] = useRecoilState(riskScoreRangeAtom);

  const [localRange, setLocalRange] = useState<number[]>([range.min, range.max])

  return (
    <Box>
      <RangeSlider
        w="200px"
        // eslint-disable-next-line
        aria-label={['min', 'max']}
        min={0}
        max={1.1}
        step={0.01}
        defaultValue={localRange}
        onChange={setLocalRange}
        onChangeEnd={() => setRange({ min: localRange[0], max: localRange[1] })}
        mx={4}
      >
        <RangeSliderTrack>
          <RangeSliderFilledTrack />
        </RangeSliderTrack>

        <Tooltip
          hasArrow
          placement="top"
          label={`${localRange[0]}`}>
          <RangeSliderThumb index={0} />
        </Tooltip>

        <Tooltip
          hasArrow
          placement="top"
          label={`${localRange[1]}`}>
          <RangeSliderThumb index={1} />
        </Tooltip>
      </RangeSlider>
    </Box>
  );
}