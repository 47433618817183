import { axiosInstance } from "api/common/axios";
import { ApiURI } from "api/common/url";
import {
  IAPBoost,
  IAPBoostsParams,
  IAPNotification,
  IAPNotificationsParams,
  IAPProductRecord,
  IAPProductRecordsParams,
  IAPSubscription,
  IAPSubscriptionsParams,
  IAPSuperFlip,
  IAPSuperFlipsParams,
  IAPTransaction,
  IAPTransactionsParams,
  IAPTransferRecord,
  IAPTransferRecordsParams,
  Resp,
} from "dto";

export const fetchIAPSubscriptions = (params: IAPSubscriptionsParams) => {
  return axiosInstance.post<Resp<IAPSubscription[]>>(
    ApiURI.IAPSubscriptionsSearch,
    params
  );
};

export const fetchIAPBoosts = (params: IAPBoostsParams) => {
  return axiosInstance.post<Resp<IAPBoost[]>>(ApiURI.IAPBoostsSearch, params);
};

export const fetchIAPSuperFlips = (params: IAPSuperFlipsParams) => {
  return axiosInstance.post<Resp<IAPSuperFlip[]>>(
    ApiURI.IAPSuperFlipsSearch,
    params
  );
};

export const fetchIAPTransactions = (params: IAPTransactionsParams) => {
  return axiosInstance.post<Resp<IAPTransaction[]>>(
    ApiURI.IAPTransactionsSearch,
    params
  );
};
export const fetchIAPProductRecords = (params: IAPProductRecordsParams) => {
  return axiosInstance.post<Resp<IAPProductRecord[]>>(
    ApiURI.IAPProductRecordsSearch,
    params
  );
};
export const fetchIAPTransferRecords = (params: IAPTransferRecordsParams) => {
  return axiosInstance.post<Resp<IAPTransferRecord[]>>(
    ApiURI.IAPTransferRecordsSearch,
    params
  );
};
export const fetchIAPNotifications = (params: IAPNotificationsParams) => {
  return axiosInstance.post<Resp<IAPNotification[]>>(
    ApiURI.IAPNotificationsSearch,
    params
  );
};
