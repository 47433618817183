import { Heading } from "@chakra-ui/react";
import { Suspense } from "react";
import { useLocation } from "react-router-dom";
import { RecoilValueReadOnly, useRecoilValue } from "recoil";
import { totalStateAtom } from "state";
import { getTitleFromPathname } from "utils/functions";
import { TotalView } from "../TotalView";

export interface MainHeaderProps {
  totalState?: RecoilValueReadOnly<number>;
}

export function MainHeader(props: MainHeaderProps) {
  const location = useLocation();

  const defaultState = useRecoilValue(totalStateAtom);
  const totalState = props.totalState ?? defaultState;

  return (
    <Heading>
      {getTitleFromPathname(location.pathname)}

      {totalState && (
        <Suspense fallback={<>...</>}>
          <TotalView state={totalState} />
        </Suspense>
      )}
    </Heading>
  );
}
