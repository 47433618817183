import { Box, Input } from "@chakra-ui/react";
import { useRecoilState } from "recoil";
import { uidAtom } from "state";

export function SearchUID() {
  const [uid, setUID] = useRecoilState(uidAtom);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;

    const isNum = !isNaN(Number(value));

    if (isNum) {
      setUID(+value);
    }
  };

  return (
    <Box>
      <Input
        placeholder="Search by UID"
        value={uid !== 0 ? uid : undefined}
        onChange={handleChange}
      />
    </Box>
  );
}
