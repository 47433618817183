import {
  Button,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  Heading,
  HStack,
  IconButton,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Select,
  Spacer,
  Stack,
  Text,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { yupResolver } from "@hookform/resolvers/yup";
import { CIDRActiveStatus } from "constant";
import { CIDRToBlock } from "dto";
import { Controller, useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { editBlockedCIDR } from "./ip.api";
import { cidrQuery, cidrsRequestIdAtom } from "./ip.state";

import dayjs from "dayjs";
import { useRef, useState } from "react";
import { AiFillEdit } from "react-icons/ai";
import { getErrorMessage } from "utils/functions";
import * as yup from "yup";

export function EditCIDRButton({ id }: { id: number }) {
  const toast = useToast();

  const { isOpen, onOpen, onClose } = useDisclosure();
  const initialRef = useRef(null);

  const cachedCidr = useRecoilValue(cidrQuery(id));

  const cachedCidrStr = cachedCidr?.ip + "/" + (cachedCidr?.mask ?? 0);
  const cachedStatus = cachedCidr?.status ?? CIDRActiveStatus.Disabled;

  const [cidrStr, setCidrStr] = useState(cachedCidrStr);
  const [status, setStatus] = useState(cachedStatus);

  const [isLoading, toggleLoading] = useState(false);

  const setRequestId = useSetRecoilState(cidrsRequestIdAtom);

  if (!cachedCidr) return <></>;

  const handleBlock = async () => {
    toggleLoading(true);

    const arr = cidrStr.split("/");
    const cidrToBlock: CIDRToBlock = {
      id: cachedCidr.id,
      ip: arr[0],
      mask: parseInt(arr[1]),
      status,
    };

    try {
      await editBlockedCIDR(cidrToBlock);

      toggleLoading(false);
      setRequestId(dayjs().valueOf());
      onClose();
    } catch (error) {
      // handle error
      toggleLoading(false);
      toast({
        title: getErrorMessage(error),
        status: "error",
      });
    }
  };

  return (
    <>
      <IconButton
        icon={<AiFillEdit />}
        aria-label={"AiFillEdit"}
        variant="outline"
        onClick={onOpen}
      />

      <Modal
        isOpen={isOpen}
        onClose={onClose}
        initialFocusRef={initialRef}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Edit CIDR</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <FormControl>
              <FormLabel>CIDR</FormLabel>
              <Input
                placeholder="0.0.0.0/0 or 2001:0000::/0"
                value={cidrStr}
                onChange={(e) => setCidrStr(e.target.value)}
                ref={initialRef}
              />
              <FormHelperText>
                <Stack>
                  <Text>Example </Text>
                  <Text>Ipv4: 129.168.50.1/24</Text>
                  <Text>Ipv6: 2001:0000:0000/24</Text>
                </Stack>
              </FormHelperText>
            </FormControl>
            <FormControl>
              <FormLabel>Status</FormLabel>
              <Select
                value={status}
                onChange={(v) => setStatus(parseInt(v.target.value))}>
                <option value="0">Disable</option>
                <option value="1">Enable</option>
              </Select>
            </FormControl>
          </ModalBody>

          <ModalFooter>
            <Button
              mr={3}
              variant="ghost"
              onClick={onClose}>
              Cancel
            </Button>
            <Button
              mr={3}
              isLoading={isLoading}
              onClick={handleBlock}>
              Save
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}
const schema = yup
  .object({
    cidr: yup.string().required(),
  })
  .required();

interface CreateCIDRForm {
  cidr: string;
}

export function EditCIDRPage() {
  // router hooks
  const navigate = useNavigate();

  // get cidrId
  const params = useParams();
  const cidrId = parseInt(params.cidrId!);

  //get ip version from search params

  // state
  const cidr = useRecoilValue(cidrQuery(cidrId));

  // form hooks
  const { control, formState, handleSubmit } = useForm<CreateCIDRForm>({
    // 绑定yup验证规则
    resolver: yupResolver(schema),
  });

  // toast hooks
  const toast = useToast();

  // loading
  if (!cidr) {
    return <div>loading...</div>;
  }

  const onSubmit = handleSubmit(async (data) => {
    try {
      // 未修改内容
      if (data.cidr === [cidr.ip, cidr.mask].join("/")) {
        return;
      }

      // 拆分ip和mask
      const array = data.cidr.split("/");

      let params: CIDRToBlock = {
        id: cidr.id,
        mask: parseInt(array[1]),
      };

      // 提交
      await editBlockedCIDR(params);

      // toast
      toast({
        title: "Edit success",
        status: "success",
      });
      // back
      navigate(-1);
    } catch (error) {
      toast({
        title: "Edit failed",
        description: getErrorMessage(error),
        status: "error",
      });
    }
  });

  const handleStatusChange = async () => {
    try {
      // api
      const params: CIDRToBlock = {
        id: cidr.id,
        ip: cidr.ip,
        status:
          cidr.status === CIDRActiveStatus.Enabled
            ? CIDRActiveStatus.Disabled
            : CIDRActiveStatus.Enabled,
      };
      await editBlockedCIDR(params);

      // toast
      toast({
        title:
          cidr.ip +
          "/" +
          (cidr.mask ?? 0) +
          (cidr.status === CIDRActiveStatus.Disabled
            ? " enabled"
            : " disabled"),
        status: "success",
      });
      // navigate back
      navigate(-1);
    } catch (error) {
      const action =
        cidr.status === CIDRActiveStatus.Disabled ? "Enable" : "Disable";
      toast({
        title: action + " failed",
        description: getErrorMessage(error),
        status: "error",
      });
    }
  };

  return (
    <Stack spacing={8}>
      <Heading>Edit Member</Heading>

      <form onSubmit={onSubmit}>
        <Stack spacing={8}>
          <Controller
            name="cidr"
            control={control}
            defaultValue={cidr.ip + "/" + cidr.mask ?? 0}
            render={({ field }) => (
              <FormControl
                maxW="400px"
                // isRequired
                isInvalid={!!formState.errors.cidr}>
                <FormLabel htmlFor="cidr">CIDR</FormLabel>
                <Input
                  type="text"
                  id="cidr"
                  placeholder="0.0.0.0/0 or 2001:0000::/0"
                  {...field}
                />
                <FormHelperText>
                  <Stack>
                    <Text>Example </Text>
                    <Text>Ipv4: 129.168.50.1/24</Text>
                    <Text>Ipv6: 2001:0000:0000/24</Text>
                  </Stack>
                </FormHelperText>
                <FormErrorMessage>
                  {formState.errors.cidr?.message}
                </FormErrorMessage>
              </FormControl>
            )}
          />

          <HStack>
            {/* 取消按钮 */}
            <Button
              onClick={() => navigate(-1)}
              variant="ghost">
              Cancel
            </Button>
            {/* 保存按钮 */}
            <Button
              isLoading={formState.isSubmitting}
              type="submit">
              Save
            </Button>
            <Spacer />
            {/* 禁用/启用按钮，仅禁用或启用状态可见*/}
            {[CIDRActiveStatus.Enabled, CIDRActiveStatus.Disabled].includes(
              cidr.status
            ) && (
              <Button
                onClick={handleStatusChange}
                colorScheme="red">
                {cidr.status === CIDRActiveStatus.Disabled
                  ? "Enable"
                  : "Disable"}
              </Button>
            )}
          </HStack>
        </Stack>
      </form>
    </Stack>
  );
}
