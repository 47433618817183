import {
  Button,
  FormControl,
  FormHelperText,
  FormLabel,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Select,
  Textarea,
  useDisclosure,
} from "@chakra-ui/react";
import dayjs from "dayjs";
import _ from "lodash";
import { Suspense, useRef, useState } from "react";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { requestIdState } from "state";
import { addWord } from "./word.api";
import { WordType } from "./word.enum";
import { wordsQuery } from "./word.state";

export function AddWordButton() {
  const { isOpen, onOpen, onClose } = useDisclosure();
  return (
    <>
      <Button onClick={onOpen}>Add Word</Button>
      <Suspense>
        <AddWordButtonModal
          isOpen={isOpen}
          onClose={onClose}
        />
      </Suspense>
    </>
  );
}

export function AddWordButtonModal({
  isOpen,
  onClose,
}: {
  isOpen: boolean;
  onClose: () => void;
}) {
  const initialRef = useRef(null);

  const [wordType, setWordType] = useState(WordType.Spam);
  const [text, setText] = useState("");
  const [loading, setLoading] = useState(false);

  const words = useRecoilValue(wordsQuery);
  const refresh = useSetRecoilState(requestIdState);

  const handleSave = async (data: string[]) => {
    setLoading(true);
    // 本地去重
    const uniq = _.uniq(data);

    // 排除已添加数据
    const diff = _.difference(
      uniq,
      words.map((i) => i.word)
    );

    if (diff.length > 0) {
      try {
        await addWord(
          diff.map((item) => {
            const word = { type: wordType, word: item };
            return word;
          })
        );
        refresh(dayjs().valueOf());
      } catch (error) {
        // handle error
      }
    }

    setLoading(false);
    onClose();
    setText("");
  };

  return (
    <>
      <Modal
        initialFocusRef={initialRef}
        isOpen={isOpen}
        onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Add Word</ModalHeader>
          <ModalCloseButton />
          <ModalBody pb={2}>
            <FormControl mb={2}>
              <FormLabel>Select Word Type</FormLabel>
              <Select
                value={wordType.toString()}
                onChange={(e) =>
                  setWordType(parseInt(e.target.value) as WordType)
                }>
                <option value="0">Spam</option>
                <option value="1">Offensive</option>
              </Select>
            </FormControl>
            <FormControl>
              <FormLabel>Words to highlight</FormLabel>
              <Textarea
                ref={initialRef}
                h="400px"
                value={text}
                onChange={(e) => setText(e.target.value)}
              />
              <FormHelperText>Each word per line</FormHelperText>
            </FormControl>
          </ModalBody>

          <ModalFooter>
            <Button
              mr={3}
              onClick={onClose}
              variant="outline">
              Cancel
            </Button>
            <Button
              isLoading={loading}
              onClick={() =>
                // console.log(text.split("\n").filter((str) => !!str))
                handleSave(text.split("\n").filter((str) => !!str))
              }>
              Save
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}
