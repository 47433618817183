import { Stack, StackProps, useColorModeValue } from "@chakra-ui/react";

interface DescriptionStackProps {
  isSelected?: boolean
}

export function DescriptionStack(props: DescriptionStackProps & StackProps) {
  const { isSelected, children, ...restProps } = props;

  const borderColor = useColorModeValue("gray.100", "gray.700")
  const hoverColor = useColorModeValue("gray.200", "gray.600")

  return (
    <Stack
      bg={isSelected ? "red.100" : undefined}
      _hover={{ borderColor: hoverColor }}
      cursor="pointer"
      alignItems="left"
      spacing={2}
      p={2}
      border="1px"
      borderColor={borderColor}
      borderRadius={"10px"}
      justifyContent="space-between"
      {...restProps}>
      {children}
    </Stack>
  );
}
