import {
  Box,
  Spinner,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import { CustomTr, UserId } from "components";
import { PurchaseFrom } from "constant";
import { IAPSubscription } from "dto";
import { Suspense } from "react";
import { useRecoilValue } from "recoil";
import { subscriptionsQuery } from "state";
import { timestampMsToDateStr } from "utils/functions";

export function SubscriptionsTable() {
  return (
    <Suspense fallback={<Spinner />}>
      <Box overflowX="scroll">
        <Table variant="simple">
          <SubscriptionsTableHead />
          <SubscriptionsTableBody />
        </Table>
      </Box>
    </Suspense>
  );
}

function SubscriptionsTableHead() {
  return (
    <Thead>
      <Tr>
        <Th>#</Th>
        <Th>Purchase From</Th>
        <Th>User</Th>
        <Th>Original Transaction ID</Th>
        <Th>Product</Th>
        <Th>Purchase Date</Th>
        <Th>Expires Date</Th>
        <Th>Auto Renew</Th>
        <Th>Status</Th>
        <Th>Created At</Th>
        <Th>Updated At</Th>
      </Tr>
    </Thead>
  );
}

function SubscriptionsTableBody() {
  const subscriptions = useRecoilValue(subscriptionsQuery);

  return (
    <Tbody>
      {subscriptions.map((subscription, index) => (
        <SubscriptionsTableCell
          key={index}
          subscription={subscription}
        />
      ))}
    </Tbody>
  );
}

interface SubscriptionsTableCellProps {
  subscription: IAPSubscription;
}

function SubscriptionsTableCell(props: SubscriptionsTableCellProps) {
  const { subscription } = props;
  return (
    <CustomTr>
      <Td>{subscription.id}</Td>
      <Td>{PurchaseFrom[subscription.purchaseFrom]}</Td>
      <Td>
        <UserId uid={subscription.uid} />
      </Td>
      <Td>{subscription.originalTransactionId}</Td>
      <Td>{subscription.productId}</Td>
      <Td>{timestampMsToDateStr(subscription.purchaseDate)}</Td>
      <Td>{timestampMsToDateStr(subscription.expiresDate)}</Td>
      <Td textTransform={"capitalize"}>
        {String(Boolean(subscription.autoRenew))}
      </Td>
      <Td>{subscription.notificationType}</Td>
      <Td>{timestampMsToDateStr(subscription.createdAt)}</Td>
      <Td>{timestampMsToDateStr(subscription.updatedAt)}</Td>
    </CustomTr>
  );
}
