import {
  AspectRatio,
  HStack,
  Image,
  Spacer,
  Spinner,
  Stack,
  Text,
  useColorModeValue,
  Wrap,
  WrapItem,
} from "@chakra-ui/react";
import { ImageLink } from "components";
import { Suspense } from "react";
import { useRecoilValue } from "recoil";
import { blockedImagesQuery } from "./image.state";

export function BlockedImageWrapper() {
  return (
    <Suspense fallback={<Spinner />}>
      <BlockedImagesWrap />
    </Suspense>
  );
}

function BlockedImagesWrap() {
  const blockedImages = useRecoilValue(blockedImagesQuery(-1));

  const borderColor = useColorModeValue("gray.100", "gray.700");

  return (
    <Wrap spacing="1px">
      {blockedImages?.map((blockedImage, index) => (
        <WrapItem key={index}>
          <Stack
            w="188px"
            border="1px"
            borderColor={borderColor}
            spacing={0}>
            <ImageLink
              image={blockedImage.media}
              _hover={{ opacity: 0.9 }}>
              <AspectRatio ratio={10 / 16}>
                <Image src={blockedImage.media.url} />
              </AspectRatio>
            </ImageLink>
            <HStack p={2}>
              <Text>Hit: {blockedImage.hit}</Text>
              <Spacer />
            </HStack>
          </Stack>
        </WrapItem>
      ))}
    </Wrap>
  );
}
