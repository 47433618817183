import { Spinner, Wrap, WrapItem } from "@chakra-ui/react";
import { VoiceCard } from "components";
import { Suspense } from "react";
import { useRecoilValue } from "recoil";
import { voicesQuery } from "state";

export function VoicesPage() {
  return (
    <Suspense fallback={<Spinner />}>
      <VoicesGrid />
    </Suspense>
  );
}

function VoicesGrid() {
  const voices = useRecoilValue(voicesQuery);

  return (
    <Wrap spacing={1}>
      {voices.map((voice, index) => (
        <WrapItem key={index}>
          <VoiceCard voice={voice} />
        </WrapItem>
      ))}
    </Wrap>
  );
}
