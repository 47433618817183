import { number, string } from "@recoiljs/refine";
import { SearchParam, timeWindowOptions } from "constant";
import { TimestampParams } from "dto";
import { atom, selector } from "recoil";
import { syncEffect } from "recoil-sync";
import { RouterURI } from "router/RouterURI";

import { convertTimeWindowToTimestamps } from "utils/functions";

// pageAtom 当前页码 state
export const pageAtom = atom<number>({
  key: SearchParam.Page,
  default: 1,
  effects: [syncEffect({ refine: number() })],
});

export const uidAtom = atom<number>({
  key: SearchParam.UID,
  default: 0,
  effects: [syncEffect({ refine: number() })],
});

export const uuidAtom = atom<string>({
  key: SearchParam.UUID,
  default: "",
  effects: [syncEffect({ refine: string() })],
});

export const shortUUIDAtom = atom<string>({
  key: SearchParam.ShortUUID,
  default: "",
  effects: [syncEffect({ refine: string() })],
});

// imeiAtom 用户设备id state
export const imeiAtom = atom<string>({
  key: SearchParam.IMEI,
  default: "",
  effects: [syncEffect({ refine: string() })],
});

// statusAtom 状态筛选器，url中查询状态的索引值
export const statusAtom = atom<string>({
  key: SearchParam.Status,
  default: "",
  effects: [syncEffect({ refine: string() })],
});

export const timeWindowState = atom<string>({
  key: SearchParam.TimeWindow,
  default: timeWindowOptions[0],
  effects: [syncEffect({ refine: string() })],
});

export const timeWindowRangeQuery = selector<TimestampParams>({
  key: "tw_ts",
  get: ({ get }) => {
    return convertTimeWindowToTimestamps(get(timeWindowState));
  },
});

export const profileRouteURIState = atom<RouterURI | undefined>({
  key: "profileRouteURI",
  default: undefined,
});
