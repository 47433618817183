import { HStack, Spacer, Stack } from "@chakra-ui/react";
import {
  CIDRStatusFilter,
  CustomTabProps,
  CustomTabs,
  MainHeader,
  Pagination,
  tabAtom,
} from "components";
import { IPVersion } from "constant";
import {
  AddCIDRToBlacklistButton,
  AddISPToBlacklistButton,
  BlockedImageWrapper,
  CIDRsTabPanel,
  DetectCIDRRuleButton,
  ISPsTableWrapper,
  cidrsTotalQuery,
  ispsTotalQuery,
} from "features";
import { blockedImagesTotalQuery } from "features/blacklist/image/image.state";
import { RecoilValueReadOnly, useRecoilValue } from "recoil";

const tabs: CustomTabProps[] = [
  { title: "Image" },
  { title: "ISP" },
  { title: "IP" },
];

export function BlacklistPage() {
  const currentTab = useRecoilValue(tabAtom);

  const option = options.find((opt) => opt.tab === currentTab);

  return (
    <Stack>
      {/* Header */}
      <HStack>
        <MainHeader totalState={option?.totalState} />
        <Spacer />

        {option?.components.map((Component, index) => (
          <Component key={index} />
        ))}
      </HStack>

      <CustomTabs tabs={tabs}>
        <BlockedImageWrapper />
        <ISPsTableWrapper />
        <CIDRsTabPanel />
      </CustomTabs>

      <Pagination state={option?.totalState} />
    </Stack>
  );
}

interface LayoutOption {
  tab: number;
  components: (() => JSX.Element)[];
  totalState?: RecoilValueReadOnly<number>;
}

const options: LayoutOption[] = [
  {
    tab: 0,
    components: [],
    totalState: blockedImagesTotalQuery(-1),
  },
  {
    tab: 1,
    components: [AddISPToBlacklistButton],
    totalState: ispsTotalQuery(""),
  },
  {
    tab: 2,
    components: [
      DetectCIDRRuleButton,
      AddCIDRToBlacklistButton,
      CIDRStatusFilter,
    ],
    totalState: cidrsTotalQuery(IPVersion.IPv4),
  },
  {
    tab: 3,
    components: [
      DetectCIDRRuleButton,
      AddCIDRToBlacklistButton,
      CIDRStatusFilter,
    ],
    totalState: cidrsTotalQuery(IPVersion.IPv6),
  },
];
