import { Button } from "@chakra-ui/react";
import { editUser } from "api";
import { DeviceStatus, RiskStatus } from "constant";
import { User } from "dto";
import { updatedDevicesAtom } from "features/device";
import { useState } from "react";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import { setRecoil } from "recoil-nexus";
import { effectiveDeviceQuery, errorState, updatedProfilesAtom } from "state";
import { getErrorMessage } from "utils/functions";

export type UnderageButtonProps = {
  user: User;
};

export function UnderageButton(props: UnderageButtonProps) {
  const { user } = props;

  const [isSubmitting, setIsSubmitting] = useState(false);

  const [updatedProfiles, setUpdatedProfiles] =
    useRecoilState(updatedProfilesAtom);
  const setUpdatedDevices = useSetRecoilState(updatedDevicesAtom);
  const effectiveDevice = useRecoilValue(effectiveDeviceQuery);

  const syncLocal = () => {
    setUpdatedProfiles((curr) => {
      const updatedProfiles = new Map(curr.map((item) => [item.uid, item]));

      const profile = updatedProfiles.get(user.uid) ?? user;

      updatedProfiles.set(user.uid, {
        ...profile,
        riskStatus: RiskStatus.Underage,
      });

      return Array.from(updatedProfiles.values());
    });

    if (effectiveDevice) {
      setUpdatedDevices((curr) => {
        const updatedDevicesMap = new Map(curr.map((item) => [item.id, item]));

        updatedDevicesMap.set(effectiveDevice.id, {
          ...effectiveDevice,
          status: DeviceStatus.Underage,
        });
        return Array.from(updatedDevicesMap.values());
      });
    }
  };

  // handlers
  const handleSubmit = async () => {
    setIsSubmitting(true);

    try {
      // api
      await editUser(user.uid, {
        riskStatus: RiskStatus.Underage,
        deviceStatus: DeviceStatus.Underage,
      });

      syncLocal();
    } catch (error) {
      setRecoil(errorState, getErrorMessage(error));
    } finally {
      setIsSubmitting(false);
    }
  };

  const targetProfile =
    updatedProfiles.find((item) => item.uid === user.uid) ?? user;
  if (targetProfile.riskStatus === RiskStatus.Underage) return <></>;

  return (
    <Button
      size="xs"
      pointerEvents="auto"
      variant={"outline"}
      onClick={handleSubmit}
      isLoading={isSubmitting}>
      Underage
    </Button>
  );
}
