import { fetchDevices } from "api";
import { deviceStatusQuery, timestampsQuery } from "components";
import { Device, DevicesParams, Resp } from "dto";
import { atom, selector } from "recoil";
import { setRecoil } from "recoil-nexus";
import { userInfoQuery } from "state";
import { getErrorMessage } from "utils/functions";
import { errorState, pageSizeAtom, requestIdState } from "../../state/common";
import { imeiAtom, pageAtom } from "../../state/search";

export const devicesRespQuery = selector<Resp<Device[]> | undefined>({
  key: "devices/resp",
  get: async ({ get }) => {
    get(requestIdState);

    const page = get(pageAtom);
    const pageSize = get(pageSizeAtom);
    const imei = get(imeiAtom);
    const status = get(deviceStatusQuery);

    const params: DevicesParams = {
      page,
      pageSize,
      status,
    };

    if (imei) {
      params.imei = imei;
    } else {
      const timestamps = get(timestampsQuery);
      params.createdStart = timestamps.createdStart;
      params.createdEnd = timestamps.createdEnd;
    }

    try {
      const resp = await fetchDevices(params);
      return resp.data;
    } catch (error) {
      setRecoil(errorState, getErrorMessage(error));
    }
  },
});

export const devicesQuery = selector<Device[]>({
  key: "devices",
  get: ({ get }) => {
    return get(devicesRespQuery)?.data ?? [];
  },
});

export const devicesTotalQuery = selector<number>({
  key: "devices/total",
  get: ({ get }) => {
    return get(devicesRespQuery)?.page?.total ?? 0;
  },
});

export const deviceQuery = selector({
  key: "device",
  get: ({ get }) => {
    const user = get(userInfoQuery);

    const device = user?.device;

    if (device) {
      // user?.userExpandInfo.version 为旧数据的app version
      const osVersion = device.osVersion ?? user.userExpandInfo.version;

      // user?.userExpandInfo.channel 为旧数据的app version
      const bundleId = device.bundleId ?? user.userExpandInfo.channel;

      return { ...device, osVersion, bundleId, uid: user.uid };
    } else {
      return undefined;
    }
  },
});

export const updatedDevicesAtom = atom<Device[]>({
  key: "devices/updated",
  default: [],
});
