import { number } from "@recoiljs/refine";
import { ReviewStatus } from "constant";
import { atom, selector } from "recoil";
import { syncEffect } from "recoil-sync";
import { FilterSelector, FilterSelectorOption } from "./FilterSelector";

const statuses = [ReviewStatus.Reviewed, ReviewStatus.InReview];

const options = statuses.map((status, index) => {
  const option: FilterSelectorOption = {
    label: ReviewStatus[status],
    value: index + 1,
  };
  return option;
});

const reviewStatusSelectorValueAtom = atom<number>({
  key: "reviewStatus/SelectorValue",
  default: 0,
  effects: [syncEffect({ refine: number() })],
});

export const reviewStatusQuery = selector<ReviewStatus[] | undefined>({
  key: "reviewStatus",
  get: ({ get }) => {
    const index = get(reviewStatusSelectorValueAtom);
    return index ? [statuses[index - 1]] : undefined;
  },
});

export function ReviewStatusFilter() {
  return (
    <FilterSelector
      placeholder="Review Status"
      state={reviewStatusSelectorValueAtom}
      options={options}
    />
  );
}
