import { number } from "@recoiljs/refine";
import { CIDRActiveStatus } from "constant";
import { atom, selector } from "recoil";
import { syncEffect } from "recoil-sync";
import { FilterSelector, FilterSelectorOption } from "./FilterSelector";

const statuses = [CIDRActiveStatus.Enabled, CIDRActiveStatus.Disabled];

const options = statuses.map((status, index) => {
  const option: FilterSelectorOption = {
    label: CIDRActiveStatus[status],
    value: index + 1,
  };
  return option;
});

const cidrStatusAtom = atom<number>({
  key: "cidr_s",
  default: 1, // 从1开始
  effects: [syncEffect({ refine: number() })],
});

export const cidrStatusQuery = selector<CIDRActiveStatus | undefined>({
  key: "cidrStatus",
  get: ({ get }) => {
    const value = get(cidrStatusAtom);
    return value ? statuses[value - 1] : undefined;
  },
});

export function CIDRStatusFilter() {
  return (
    <FilterSelector
      placeholder="Select Status"
      state={cidrStatusAtom}
      options={options}
    />
  );
}
