import { atom, selector } from "recoil";
import { fetchVersionWhitelists } from "./version-whitelist.api";
import { ReleaseStatus } from "./version-whitelist.interface";

export const whitelistBuildNumberQuery = selector({
  key: "whitelistBuildNumberQuery",
  get: ({ get }) => {
    return 12;
  },
});

export const whitelistReleaseStatusState = atom({
  key: "releaseStatusState",
  default: ReleaseStatus["In Review"],
  effects: [
    ({ setSelf, onSet, trigger }) => {
      if (trigger === "get") {
        setSelf(ReleaseStatus.Published);
      }
      onSet((newValue) => {
        console.log(newValue);
      });
    },
  ],
});

export const versionWhitelistSettingsRespQuery = selector({
  key: "versionWhitelistSettingsRespQuery",
  get: async () => {
    try {
      const resp = await fetchVersionWhitelists();
      return resp.data;
    } catch (error) {
      // handle error
    }
  },
});

export const versionWhitelistSettingsQuery = selector({
  key: "versionWhitelistSettingsQuery",
  get: ({ get }) => {
    const resp = get(versionWhitelistSettingsRespQuery);

    if (resp?.error) {
      // handle error
    }

    return resp?.data ?? [];
  },
});
