import { Box, Spacer, Stack } from "@chakra-ui/react";
import {
  BioStatusFilter,
  DateFilter,
  GenderTabs,
  HeaderStack,
  MainHeader,
  MediaStatusFilter,
  Pagination,
  QuestionStatusFilter,
  ReportStatusFilter,
  RiskStatusFilter,
  UserStatusFilter,
} from "components";
import { OpenAllReports } from "features";
import { SubmitProfilesButton } from "features/user/actions";
import { Outlet, useLocation } from "react-router-dom";
import { RecoilValueReadOnly } from "recoil";
import { RouterURI } from "router/RouterURI";
import {
  bioListToReviewQuery,
  bioListTotalQuery,
  photosToReviewQuery,
  photosTotalQuery,
  profilesTotalQuery,
  questionsToReviewQuery,
  questionsTotalQuery,
  reportsTotalQuery,
  usersToReviewQuery,
  voicesToReviewQuery,
  voicesTotalQuery,
} from "state";

export function ReviewLayout() {
  const location = useLocation();

  const opt = options.find((opt) => opt.pathname === location.pathname);

  const Button = opt?.submitButton;

  return (
    <Stack>
      <HeaderStack>
        <MainHeader totalState={opt?.totalState} />

        <Spacer />

        {opt?.trainingItems.map((Comp, index) => (
          <Comp key={index} />
        ))}
        {Button && <Button />}
      </HeaderStack>

      <GenderTabs />

      <Box overflowX="scroll">
        <Outlet />
      </Box>

      <Pagination state={opt?.totalState} />
    </Stack>
  );
}

interface LayoutOption {
  pathname: RouterURI;
  trainingItems: (() => JSX.Element)[];
  totalState?: RecoilValueReadOnly<number>;
  submitButton?: () => JSX.Element;
}

const options: LayoutOption[] = [
  {
    pathname: RouterURI.Normal,
    trainingItems: [],
    totalState: profilesTotalQuery,
    submitButton: () => <SubmitProfilesButton state={usersToReviewQuery} />,
  },
  {
    pathname: RouterURI.Confirmed,
    trainingItems: [],
    totalState: profilesTotalQuery,
    submitButton: () => <SubmitProfilesButton state={usersToReviewQuery} />,
  },
  {
    pathname: RouterURI.Suspended,
    trainingItems: [],
    totalState: profilesTotalQuery,
    submitButton: () => <SubmitProfilesButton state={usersToReviewQuery} />,
  },
  {
    pathname: RouterURI.Banned,
    trainingItems: [],
    totalState: profilesTotalQuery,
    submitButton: () => <SubmitProfilesButton state={usersToReviewQuery} />,
  },
  {
    pathname: RouterURI.Bio,
    trainingItems: [BioStatusFilter],
    totalState: bioListTotalQuery,
    submitButton: () => <SubmitProfilesButton state={bioListToReviewQuery} />,
  },
  {
    pathname: RouterURI.Questions,
    trainingItems: [QuestionStatusFilter],
    totalState: questionsTotalQuery,
    submitButton: () => <SubmitProfilesButton state={questionsToReviewQuery} />,
  },
  {
    pathname: RouterURI.Voices,
    trainingItems: [MediaStatusFilter],
    totalState: voicesTotalQuery,
    submitButton: () => <SubmitProfilesButton state={voicesToReviewQuery} />,
  },
  {
    pathname: RouterURI.Reports,
    trainingItems: [ReportStatusFilter, OpenAllReports],
    totalState: reportsTotalQuery,
  },
  {
    pathname: RouterURI.Photos,
    trainingItems: [
      MediaStatusFilter,
      UserStatusFilter,
      RiskStatusFilter,
      DateFilter,
    ],
    totalState: photosTotalQuery,
    submitButton: () => <SubmitProfilesButton state={photosToReviewQuery} />,
  },
];
