import {
  Avatar,
  HStack,
  Spacer,
  Spinner,
  Stack,
  Table,
  Tag,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import {
  CustomTr,
  DateFilter,
  GenderStatusesFilter,
  HeaderStack,
  MainHeader,
  MediaStatusTag,
  Pagination,
  RiskStatusBadge,
  RiskStatusFilter,
  UserId,
  UserIdWrapper,
  UserStatusFilter,
  UserStatusTag,
} from "components";
import { Gender, ReviewStatus } from "constant";
import { User } from "dto";
import { Suspense } from "react";
import { useRecoilValue } from "recoil";
import { OpenAllUsers } from "routes/user";
import { usersQuery, usersTotalQuery } from "state";
import { timestampMsToAge, timestampMsToDateStr } from "utils/functions";

export function UsersPage() {
  return (
    <Stack>
      <HeaderStack>
        <MainHeader totalState={usersTotalQuery} />

        <Spacer />
        <Suspense fallback={<></>}>
          <OpenAllUsers />
        </Suspense>
        <GenderStatusesFilter />
        <UserStatusFilter />
        <RiskStatusFilter />
        <DateFilter />
      </HeaderStack>

      <Suspense fallback={<Spinner />}>
        <UsersTable />
      </Suspense>

      <Pagination state={usersTotalQuery} />
    </Stack>
  );
}

function UsersTable() {
  const users = useRecoilValue(usersQuery);

  return (
    <Table variant="simple">
      <Thead>
        <Tr>
          <Th>UID</Th>
          <Th>Avatar</Th>
          <Th>Gender</Th>
          <Th>Age</Th>
          <Th>City</Th>
          <Th>Country Code</Th>
          <Th>Status & Verify</Th>
          <Th>Risk</Th>
          <Th>Created At</Th>
          <Th>Updated At</Th>
        </Tr>
      </Thead>

      <Tbody>
        {users.map((user, index) => (
          <UsersTableCell
            key={index}
            user={user}
          />
        ))}
      </Tbody>
    </Table>
  );
}

interface UsersTableCellProps {
  user: User;
}

function UsersTableCell(props: UsersTableCellProps) {
  const { user } = props;

  return (
    <CustomTr>
      <Td w={1}>
        <UserId
          uid={user.uid}
          isExternal
        />
      </Td>
      <Td w={1}>
        <UserIdWrapper
          uid={user.uid}
          isExternal>
          <Avatar
            name={user.uuid}
            src={user.photos && user.photos[0].url}
          />
        </UserIdWrapper>
      </Td>
      <Td w={1}>{user.gender ? Gender[user.gender] : "--"}</Td>
      <Td w={1}>{user.birthday ? timestampMsToAge(user.birthday) : "--"}</Td>
      <Td w={1}>{user.currGeoInfo?.city || "--"}</Td>
      <Td w={1}>
        {user.currGeoInfo?.countryCode || user.currGeoInfo?.address || "--"}
      </Td>

      <Td w={1}>
        <HStack>
          <UserStatusTag status={user.status} />
          {user.verify && <MediaStatusTag status={user.verify?.status} />}
        </HStack>
      </Td>

      <Td w={1}>
        <HStack>
          <RiskStatusBadge user={user} />
          {user.reviewStatus === ReviewStatus.InReview && (
            <Tag
              size="sm"
              whiteSpace="nowrap">
              In Review
            </Tag>
          )}
        </HStack>
      </Td>
      <Td
        w={1}
        whiteSpace="nowrap">
        {timestampMsToDateStr(user.createdAt)}
      </Td>
      <Td
        w={1}
        whiteSpace="nowrap">
        {timestampMsToDateStr(user.updatedAt)}
      </Td>
    </CustomTr>
  );
}
