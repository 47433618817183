import {
  Box,
  Spinner,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import { CustomTr, UserId } from "components";
import { PurchaseFrom } from "constant";
import { IAPTransferRecord } from "dto";
import { Suspense } from "react";
import { useRecoilValue } from "recoil";
import { transferRecordsQuery } from "state";
import { timestampMsToDateStr } from "utils/functions";

export function SubscriptionTransfer() {
  return (
    <Suspense fallback={<Spinner />}>
      <Box overflowX="scroll">
        <Table variant="simple">
          <TransferRecordsTableHead />
          <TransferRecordsTableBody />
        </Table>
      </Box>
    </Suspense>
  );
}

function TransferRecordsTableHead() {
  return (
    <Thead>
      <Tr>
        <Th>#</Th>
        <Th>Original Transaction ID</Th>
        <Th>From</Th>
        <Th>To</Th>
        <Th>Created At</Th>
        <Th>Updated At</Th>
      </Tr>
    </Thead>
  );
}

function TransferRecordsTableBody() {
  const transferRecords = useRecoilValue(transferRecordsQuery);
  return (
    <Tbody>
      {transferRecords.map((transferRecord, index) => (
        <TransferRecordsTableCell
          key={index}
          transferRecord={transferRecord}
        />
      ))}
    </Tbody>
  );
}

interface TransferRecordsTableCellProps {
  transferRecord: IAPTransferRecord;
}

function TransferRecordsTableCell(props: TransferRecordsTableCellProps) {
  const { transferRecord } = props;

  const isFromPurchase = transferRecord.fromUid < 0;

  return (
    <CustomTr>
      <Td>{transferRecord.id}</Td>
      <Td>{transferRecord.originalTransactionId}</Td>
      <Td>
        {isFromPurchase &&
          "Purchased from " + PurchaseFrom[Math.abs(transferRecord.fromUid)]}
        {!isFromPurchase && <UserId uid={transferRecord.fromUid} />}
      </Td>
      <Td>
        <UserId uid={transferRecord.toUid} />
      </Td>
      <Td>{timestampMsToDateStr(transferRecord.createdAt)}</Td>
      <Td>{timestampMsToDateStr(transferRecord.updatedAt)}</Td>
    </CustomTr>
  );
}
