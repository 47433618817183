import {
  IconButton,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  useDisclosure,
} from "@chakra-ui/react";
import { GrChat } from "react-icons/gr";
import { useResetRecoilState } from "recoil";
import { currentSessionIdAtom, timeWindowState } from "state";
import { ChatsPage } from "./ChatsPage";

export function ViewChatsButton() {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const clearSessionID = useResetRecoilState(currentSessionIdAtom);
  const clearTimeWindow = useResetRecoilState(timeWindowState);

  const handleClose = () => {
    onClose();
    // cleanup
    clearSessionID();
    clearTimeWindow();
  };

  return (
    <>
      <IconButton
        onClick={onOpen}
        icon={<GrChat />}
        aria-label={"Chats"}>
        View Chats
      </IconButton>

      <Modal
        onClose={handleClose}
        size="full"
        scrollBehavior="inside"
        motionPreset="none"
        isOpen={isOpen}>
        <ModalOverlay />
        <ModalContent>
          <ModalCloseButton zIndex={99} />
          <ModalBody p={0}>
            <ChatsPage />
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
}
