import { Stack, Text } from "@chakra-ui/react";
import { Logo } from "components";
import { RouterURI } from "router/RouterURI";
import { SidebarCapital } from "./SidebarCapital";
import { SidebarNavLink } from "./SidebarNavLink";

export function Sidebar() {
  // background color

  return (
    <Stack
      as="aside"
      spacing={0}>
      <Logo />

      <SidebarNavLink to={RouterURI.Home}>
        <Text>Dashboard</Text>
      </SidebarNavLink>

      <>
        <SidebarCapital title="review profiles" />

        <SidebarNavLink to={RouterURI.Normal} />
        <SidebarNavLink to={RouterURI.Confirmed} />
        <SidebarNavLink to={RouterURI.Suspended} />
        <SidebarNavLink to={RouterURI.Banned} />
      </>
      <>
        <SidebarCapital title="content" />

        <SidebarNavLink to={RouterURI.Bio} />
        <SidebarNavLink to={RouterURI.Questions} />
        <SidebarNavLink to={RouterURI.Voices} />
        <SidebarNavLink to={RouterURI.Reports} />
        <SidebarNavLink to={RouterURI.Photos} />
      </>

      <>
        <SidebarCapital title="Data" />
        <SidebarNavLink to={RouterURI.Users} />
        <SidebarNavLink to={RouterURI.Devices} />
      </>

      <>
        <SidebarCapital title="Purchase" />

        <SidebarNavLink to={RouterURI.PurchaseOverview} />
        <SidebarNavLink to={RouterURI.ReceiptTransactions} />
        <SidebarNavLink to={RouterURI.ProductTransactions} />
        <SidebarNavLink to={RouterURI.SubscriptionTransfer} />
        <SidebarNavLink to={RouterURI.PurchaseNotifications} />
      </>

      <>
        <SidebarCapital title="Manage" />

        <SidebarNavLink to={RouterURI.Members} />
        <SidebarNavLink to={RouterURI.HighlightWords} />
        <SidebarNavLink to={RouterURI.BlackList} />
        <SidebarNavLink to={RouterURI.Settings} />
      </>
    </Stack>
  );
}
