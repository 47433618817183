import { Button, useToast } from "@chakra-ui/react";
import dayjs from "dayjs";
import { Media } from "dto";
import { Suspense, useState } from "react";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { getErrorMessage } from "utils/functions";
import { blockImage, unblockImage } from "./image.api";
import { blockedImagesQuery, blockedImagesRequestIdAtom } from "./image.state";

export function BlockImageButton({ image }: { image: Media }) {
  const toast = useToast();
  const [isLoading, toggleLoading] = useState(false);

  const isBlocked = useRecoilValue(blockedImagesQuery(image.id)).length > 0;

  const setRequestId = useSetRecoilState(blockedImagesRequestIdAtom);

  const handleBlock = async () => {
    console.log(isBlocked);
    toggleLoading(true);
    console.log(image);

    try {
      await blockImage(image.id);
      toggleLoading(false);
      setRequestId(dayjs().valueOf());
    } catch (error) {
      // handle error
      toggleLoading(false);
      toast({
        title: getErrorMessage(error),
        status: "error",
      });
    }
  };

  const handleUnblock = async () => {
    console.log(isBlocked);
    console.log(image);

    if (!isBlocked) return;

    toggleLoading(true);

    try {
      await unblockImage(image.id);
      toggleLoading(false);
      setRequestId(dayjs().valueOf());
    } catch (error) {
      // handle error
      toggleLoading(false);
      toast({
        title: getErrorMessage(error),
        status: "error",
      });
    }
  };

  return (
    <>
      {isBlocked ? (
        <Button
          size="sm"
          colorScheme="green"
          variant="ghost"
          onClick={handleUnblock}
          isLoading={isLoading}>
          Unblock
        </Button>
      ) : (
        <Button
          size="sm"
          colorScheme="red"
          variant="ghost"
          onClick={handleBlock}
          isLoading={isLoading}>
          Block
        </Button>
      )}
    </>
  );
}

export function BlockImageButtonWrapper({ image }: { image: Media }) {
  return (
    <Suspense
      fallback={
        <Button
          size="sm"
          opacity={0}
        />
      }>
      <BlockImageButton image={image} />
    </Suspense>
  );
}
