import { axiosInstance } from "api/common/axios";
import { ApiURI } from "api/common/url";
import {
  MessagesParams,
  MessagesResponse,
  Resp,
  SessionsParams,
  SessionsResponse,
} from "dto";

export function fetchSessions(params: SessionsParams) {
  return axiosInstance.post<Resp<SessionsResponse>>(
    ApiURI.FetchSessions,
    params
  );
}

export function fetchMessages(params: MessagesParams) {
  return axiosInstance.post<Resp<MessagesResponse>>(
    ApiURI.FetchMessages,
    params
  );
}
