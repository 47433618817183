
import { QuestionStatus } from "constant";
import { StatusColor } from "./color";
import { NewStatusTag } from "./NewStatusTag";

const colors: StatusColor[] = [
  { status: QuestionStatus.Approved, color: "green" },
  { status: QuestionStatus.Pending, color: "red" },
  { status: QuestionStatus.Rejected, color: "gray" },
  { status: QuestionStatus.Deleted, color: "gray" },
];

interface QuestionStatusTagProps {
  status: QuestionStatus;
}

// export 
export function QuestionStatusTag(props: QuestionStatusTagProps) {

  const getColor = () => {
    return colors.find(color => color.status as QuestionStatus === props.status)?.color;
  };

  return (
    <NewStatusTag
      title={QuestionStatus[props.status]}
      color={getColor()}
      isDeleted={props.status === (QuestionStatus.Deleted)} />
  );
}

