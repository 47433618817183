import { axiosInstance } from "api/common/axios";
import { ApiURI } from "api/common/url";
import { CacheKey } from "constant";
import { Member, MemberToCreate, ParamsMembers, Resp } from "dto";
import { getAuth } from "firebase/auth";

// 获取idToken
export const getIdToken = async () => {
  // 获取是否需要强制刷新token的标识
  const isForce =
    window.localStorage.getItem(CacheKey.NeedForceRefreshIdToken) !== null;

  // 获取idToken，因auth hooks强制拦截等待firebase初始化，此处强制转为User | null 为User
  const idToken = await getAuth().currentUser!.getIdToken(isForce);

  // 获取成功后强制移除needForceRefreshIdToken标记
  window.localStorage.removeItem(CacheKey.NeedForceRefreshIdToken);

  // 返回
  return idToken;
};

export const signOutFromFirebase = () => {
  return getAuth().signOut();
};

export function createMember(values: MemberToCreate) {
  return axiosInstance.post(ApiURI.AdminUserCreate, values);
}

export function fetchMember(id: number) {
  return axiosInstance.get<Resp<Member>>(ApiURI.AdminUserDetails + "/" + id);
}

export function editMember(id: number, username: string) {
  return axiosInstance.put(ApiURI.AdminUserEdit + "/" + id, {
    username: username,
  });
}

export function toggleMemberStatus(id: number) {
  return axiosInstance.put(ApiURI.AdminUserDisable + "/" + id);
}

export const deleteMember = (id: number) => {
  return axiosInstance.put(ApiURI.AdminUserDelete + "/" + id);
};

export function fetchMembers(params: ParamsMembers) {
  return axiosInstance.post<Resp<Member[]>>(ApiURI.AdminUsersSearch, params);
}
