import { Spinner, Table, Tbody, Td, Th, Thead, Tr } from "@chakra-ui/react";
import { CustomTr, UserId } from "components";
import { PurchaseFrom } from "constant";
import { IAPTransaction } from "dto";
import { Suspense } from "react";
import { useRecoilValue } from "recoil";
import { transactionsAtom } from "state";
import { timestampMsToDateStr } from "utils/functions";

export function TransactionsTable() {
  return (
    <Suspense fallback={<Spinner />}>
      <Table variant="simple">
        <TransactionsTableHead />
        <TransactionsTableBody />
      </Table>
    </Suspense>
  );
}

function TransactionsTableHead() {
  return (
    <Thead>
      <Tr>
        <Th>#</Th>
        <Th>Purchase From</Th>
        <Th>Original Transaction Id</Th>
        <Th>Transaction ID</Th>
        <Th>Product</Th>
        <Th>Purchase Date</Th>
        <Th>Expires Date</Th>
        <Th>Note</Th>
        <Th>Buyer</Th>
        <Th>Created At</Th>
        <Th>Updated At</Th>
      </Tr>
    </Thead>
  );
}

function TransactionsTableBody() {
  const transactions = useRecoilValue(transactionsAtom);
  return (
    <Tbody>
      {transactions.map((transaction, index) => (
        <TransactionsTableCell
          key={index}
          transaction={transaction}
        />
      ))}
    </Tbody>
  );
}

interface TransactionsTableCellProps {
  transaction: IAPTransaction;
}

function TransactionsTableCell(props: TransactionsTableCellProps) {
  const { transaction } = props;

  return (
    <CustomTr>
      <Td>{transaction.id}</Td>
      <Td>{PurchaseFrom[transaction.purchaseFrom]}</Td>
      <Td>{transaction.originalTransactionId ?? "--"}</Td>
      <Td>{transaction.transactionId}</Td>
      <Td>{transaction.productId}</Td>
      <Td>{timestampMsToDateStr(transaction.purchaseDate)}</Td>
      <Td>
        {transaction.expiresDate
          ? timestampMsToDateStr(transaction.expiresDate)
          : "--"}
      </Td>
      <Td>{transaction.note ?? "--"}</Td>
      <Td>
        <UserId uid={transaction.uid} />
      </Td>
      <Td>{timestampMsToDateStr(transaction.createdAt)}</Td>
      <Td>{timestampMsToDateStr(transaction.updatedAt)}</Td>
    </CustomTr>
  );
}
