import {
  Avatar,
  HStack,
  Stack,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import { MessageType } from "constant";
import { Session } from "dto";

import { useRecoilState } from "recoil";
import { currentSessionIdAtom } from "state";
import { timestampMsToDateStr } from "utils/functions";

export function ChannelCell(props: ChannelCellProps) {
  const grayBg = useColorModeValue("gray.100", "gray.600");

  const [currentSessionId, setCurrentSessionId] =
    useRecoilState(currentSessionIdAtom);

  const { session } = props;

  const getLastMessageTitle = () => {
    if (!session.lastMsg) return "NULL";

    const message = session.lastMsg;

    switch (message.type) {
      case MessageType.Text:
        return message.body;
      case MessageType.Photo:
        return "[Image]";
      case MessageType.Audio:
        return "[Audio]";
      case MessageType.Video:
        return "[Video]";
      case MessageType.Location:
        return "[Location]";
      case MessageType.Notification:
        return "[Notification]";
      case MessageType.File:
        return "[File]";
      case MessageType.Custom:
        return "[Custom]";
      default:
        return "NULL";
    }
  };

  return (
    <HStack
      p={2}
      bg={props.session.accid === currentSessionId ? grayBg : ""}
      borderRadius={4}
      onClick={() => setCurrentSessionId(props.session.accid)}
      _hover={{ backgroundColor: grayBg, cursor: "pointer" }}>
      <Avatar
        name={props.session.accid}
        src={props.session.avatarUrl}
      />
      <Stack spacing={0}>
        <Text
          w="200px"
          whiteSpace="nowrap"
          overflow="hidden"
          textOverflow="ellipsis">
          {getLastMessageTitle()}
        </Text>
        <Text
          fontSize="12px"
          opacity={0.5}>
          {props.session.lastMsg?.time
            ? timestampMsToDateStr(props.session.lastMsg.time)
            : "null"}
        </Text>
      </Stack>
    </HStack>
  );
}

interface ChannelCellProps {
  session: Session;
}
