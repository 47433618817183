import { HStack, Text } from "@chakra-ui/react";
import { number } from "@recoiljs/refine";
import { PurchaseFrom, SearchParam } from "constant";
import { atom } from "recoil";
import { syncEffect } from "recoil-sync";
import { FilterSelector, FilterSelectorOption } from "./FilterSelector";

const options = [
  PurchaseFrom.All,
  PurchaseFrom.iOS,
  PurchaseFrom.Android,
  PurchaseFrom.Console,
].map((platform) => {
  const option: FilterSelectorOption = {
    label: PurchaseFrom[platform],
    value: platform,
  };
  return option;
});

export const purchaseFromAtom = atom({
  key: SearchParam.PurchaseFrom,
  default: PurchaseFrom.All,
  effects: [syncEffect({ refine: number() })],
});

export function PurchaseFromFilter() {
  return (
    <HStack>
      <Text>Purchase From: </Text>
      <FilterSelector
        state={purchaseFromAtom}
        options={options}
      />
    </HStack>
  );
}
