export enum RouterURI {
  Home = "/",
  Login = "/login",
  Confirm = "/confirm",

  Dashboard = "/dashboard",

  Normal = "/profiles/normal",
  Confirmed = "/profiles/Confirmed",
  Suspended = "/profiles/suspended",
  Banned = "/profiles/banned",

  Photos = "/photos",
  Voices = "/voices",
  Bio = "/bio",
  Questions = "/questions",
  Reports = "/reports",

  /* Data section */
  Users = "/users",
  Devices = "/devices",

  PurchaseOverview = "/purchase/overview",
  ReceiptTransactions = "/purchase/receipt_transactions",
  ProductTransactions = "/purchase/product_transactions",
  SubscriptionTransfer = "/purchase/subscription_transfer",
  PurchaseNotifications = "/purchase/notifications",

  /* Manage section */
  Members = "/members",
  HighlightWords = "/highlight_words",
  BlackList = "/blacklist",
  Settings = "/settings",
}
