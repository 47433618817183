import {
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  HStack,
  Input,
  Spacer,
  Stack,
  Text,
  useToast,
} from "@chakra-ui/react";
import { yupResolver } from "@hookform/resolvers/yup";
import { deleteMember, editMember, toggleMemberStatus } from "api";
import { MemberStatus } from "constant";
import { MemberToCreate } from "dto";
import { Controller, useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { memberQuery } from "state";
import { getErrorMessage } from "utils/functions";
import * as yup from "yup";

const schema = yup
  .object({
    username: yup.string().required(),
    email: yup.string().email().required(),
  })
  .required();

export function EditMemberPage() {
  // router hooks
  const navigate = useNavigate();
  const params = useParams();

  const memberId = parseInt(params.memberId!);

  // state
  const member = useRecoilValue(memberQuery(memberId));

  // form hooks
  const { control, formState, handleSubmit } = useForm<MemberToCreate>({
    // 绑定yup验证规则
    resolver: yupResolver(schema),
  });

  // toast hooks
  const toast = useToast();

  if (!member) {
    return <>...</>;
  }

  const onSubmit = handleSubmit(async (data) => {
    try {
      // 提交修改
      await editMember(memberId, data.username);
      // toast
      toast({
        title: "Edit success",
        status: "success",
      });
      // back
      navigate(-1);
    } catch (error) {
      toast({
        title: "Edit member failed",
        description: getErrorMessage(error),
        status: "error",
      });
    }
  });

  const handleStatusChange = async () => {
    try {
      // api
      await toggleMemberStatus(memberId);
      // toast
      toast({
        title:
          member.username +
          (member.status === MemberStatus.Disabled ? " enabled" : " disabled"),
        status: "success",
      });
      // navigate back
      navigate(-1);
    } catch (error) {
      toast({
        title:
          (member.status === MemberStatus.Disabled ? "Enable" : "Disable") +
          " member failed",
        description: getErrorMessage(error),
        status: "error",
      });
    }
  };

  const handleDelete = async () => {
    try {
      // api
      await deleteMember(memberId);
      // toast
      toast({
        title: `Delete member success`,
        status: "success",
      });
      // navigate back
      navigate(-1);
    } catch (error) {
      toast({
        title: "Delete member failed",
        description: getErrorMessage(error),
        status: "error",
      });
    }
  };

  return (
    <Stack spacing={8}>
      <Heading>Edit Member</Heading>

      <form onSubmit={onSubmit}>
        <Stack spacing={8}>
          <Controller
            name="username"
            defaultValue={member?.username}
            control={control}
            render={({ field }) => (
              <FormControl
                // isRequired
                isInvalid={!!formState.errors.username}>
                <FormLabel htmlFor="username">Username</FormLabel>
                <Input
                  maxW="375px"
                  type="text"
                  id="username"
                  placeholder="Username"
                  {...field}
                />
                <FormErrorMessage>
                  {formState.errors.username?.message}
                </FormErrorMessage>
              </FormControl>
            )}
          />

          <Controller
            name="email"
            control={control}
            defaultValue={member?.mail}
            render={({ field }) => (
              <FormControl
                // isRequired
                isDisabled
                isInvalid={!!formState.errors.email}>
                <FormLabel htmlFor="email">Email</FormLabel>
                <HStack spacing={4}>
                  <Input
                    maxW="375px"
                    type="text"
                    id="email"
                    placeholder="Email"
                    {...field}
                  />
                  <Text>{MemberStatus[member.status]}</Text>
                </HStack>

                <FormErrorMessage>
                  {formState.errors.email?.message}
                </FormErrorMessage>
              </FormControl>
            )}
          />

          <HStack>
            {/* 取消按钮 */}
            <Button onClick={() => navigate(-1)}>Cancel</Button>
            {/* 保存按钮 */}
            <Button
              isLoading={formState.isSubmitting}
              type="submit">
              Save
            </Button>
            <Spacer />
            {/* 删除按钮, pending或disabled状态展示 */}
            {[MemberStatus.Disabled, MemberStatus.Pending].includes(
              member.status
            ) && (
              <Button
                colorScheme="red"
                onClick={handleDelete}>
                Delete
              </Button>
            )}
            {/* 禁用/启用按钮，仅禁用或启用状态可见*/}
            {[MemberStatus.Enabled, MemberStatus.Disabled].includes(
              member.status
            ) && (
              <Button onClick={handleStatusChange}>
                {member.status === MemberStatus.Disabled ? "Enable" : "Disable"}
              </Button>
            )}
          </HStack>
        </Stack>
      </form>
    </Stack>
  );
}
