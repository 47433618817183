import {
  AlertDialog,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
  IconButton,
  useDisclosure,
} from "@chakra-ui/react";
import dayjs from "dayjs";
import { useRef, useState } from "react";
import { AiTwotoneDelete } from "react-icons/ai";
import { useSetRecoilState } from "recoil";
import { requestIdState } from "state";
import { delWord } from "./word.api";

export function DeleteKeywordButton({ id }: { id: number }) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const cancelRef = useRef(null);

  const [isLoading, toggleLoading] = useState(false);
  const refresh = useSetRecoilState(requestIdState);

  const handleUnblock = async () => {
    toggleLoading(true);

    // 接口请求unblock
    try {
      await delWord(id);
    } catch (error) {
      // TODO: handle error
    }

    // end loading
    toggleLoading(false);

    // close
    onClose();

    // 刷新数据
    refresh(dayjs().valueOf());
  };

  return (
    <>
      <IconButton
        variant="ghost"
        colorScheme="red"
        onClick={onOpen}
        icon={<AiTwotoneDelete />}
        aria-label={"AiTwotoneDelete"}
      />

      <AlertDialog
        isOpen={isOpen}
        leastDestructiveRef={cancelRef}
        onClose={onClose}>
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader
              fontSize="lg"
              fontWeight="bold">
              Are you sure you want to delete this keyword?
            </AlertDialogHeader>

            <AlertDialogFooter>
              <Button
                ref={cancelRef}
                variant="ghost"
                onClick={onClose}>
                Cancel
              </Button>
              <Button
                colorScheme="red"
                isLoading={isLoading}
                onClick={handleUnblock}
                ml={3}>
                Delete
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </>
  );
}
