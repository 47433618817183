import { Box, HStack, useRadio, useRadioGroup, UseRadioProps } from "@chakra-ui/react";
import { SearchParam, timeWindowOptions } from "constant";
import { useRecoilState } from "recoil";
import { timeWindowState } from "state";

export function DateRangeRadioGroup() {

    const [timeWindow, setTimeWindow] = useRecoilState(timeWindowState)

    const { getRootProps, getRadioProps } = useRadioGroup({
        name: SearchParam.TimeWindow,
        defaultValue: timeWindow,
        onChange: setTimeWindow
    });

    const group = getRootProps();

    return (
        <HStack {...group}>
            {timeWindowOptions.map((value) => {

                const radio = getRadioProps({ value });

                return (
                    <RadioCard key={value} {...radio}>
                        {value}
                    </RadioCard>
                );
            })}
        </HStack>
    );
}

function RadioCard(props: UseRadioProps & React.AriaAttributes & React.DOMAttributes<HTMLInputElement>) {
    const { getInputProps, getCheckboxProps } = useRadio(props);

    const input = getInputProps();
    const checkbox = getCheckboxProps();

    return (
        <Box as='label'>
            <input {...input} />
            <Box
                {...checkbox}
                cursor='pointer'
                borderWidth='1px'
                borderRadius='md'
                boxShadow='md'
                _checked={{
                    bg: 'teal.600',
                    color: 'white',
                    borderColor: 'teal.600',
                }}
                _focus={{
                    boxShadow: 'outline',
                }}
                px={1}
                py={0}
            >
                {props.children}
            </Box>
        </Box>
    );
}
