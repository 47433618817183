import { axiosInstance } from "api/common/axios";
import { ApiURI } from "api/common/url";
import { DeviceStatus } from "constant";
import { DevicesParams, RespDevices } from "dto";

export function fetchDevices(params: DevicesParams) {
  return axiosInstance.post<RespDevices>(ApiURI.DevicesSearch, params);
}

type DeviceToUpdate = {
  status?: DeviceStatus;
};

export function editDevice(imei: string, deviceToUpdate: DeviceToUpdate) {
  return axiosInstance.put(ApiURI.DeviceEdit + "/" + imei, deviceToUpdate);
}
