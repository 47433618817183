import { Box, Link, useColorModeValue } from "@chakra-ui/react";
import React from "react";
import {
  Link as RouterLink,
  LinkProps,
  useMatch,
  useResolvedPath,
} from "react-router-dom";
import { useResetRecoilState } from "recoil";
import { imeiAtom, pageAtom, shortUUIDAtom, uidAtom, uuidAtom } from "state";
import { getTitleFromPathname } from "utils/functions";

// SidebarNavLink 自定义侧边栏NavLink
export function SidebarNavLink(
  props: LinkProps & React.RefAttributes<HTMLAnchorElement>
) {
  // 提取to和children属性
  const { to, children, ...restProps } = props;

  // 判断path是否匹配
  const resolved = useResolvedPath(to);
  const match = useMatch({ path: resolved.pathname, end: true });

  // active状态背景色
  const activatedBgColor = useColorModeValue("purple.200", "purple.600");

  //reset
  const resetPage = useResetRecoilState(pageAtom);
  const resetUID = useResetRecoilState(uidAtom);
  const resetShortUUID = useResetRecoilState(shortUUIDAtom);
  const resetUUID = useResetRecoilState(uuidAtom);
  const resetIMEI = useResetRecoilState(imeiAtom);

  const handleClick = () => {
    resetPage();
    resetShortUUID();
    resetUUID();
    resetUID();
    resetIMEI();
  };

  return (
    <Link
      as={RouterLink}
      to={to}
      {...restProps}
      onClick={handleClick}
      _hover={{ bg: activatedBgColor }}>
      <Box
        bg={match ? activatedBgColor : ""}
        px={4}
        py={2}>
        {children ?? getTitleFromPathname(resolved.pathname)}
      </Box>
    </Link>
  );
}
