import dayjs from "dayjs";
import { atom, selectorFamily } from "recoil";
import { setRecoil } from "recoil-nexus";
import { errorState } from "state";
import { getErrorMessage } from "utils/functions";
import { getIPDetails, getIPScore } from "./api";

export const ipScoreRequestIdState = atom({
  key: "ipScore/requestId",
  default: dayjs().valueOf(),
});

const ipScoreRespQuery = selectorFamily({
  key: "ipScore/resp",
  get:
    (ip: string) =>
    async ({ get }) => {
      get(ipScoreRequestIdState);

      try {
        const resp = await getIPScore(ip);

        return resp.data;
      } catch (error) {
        setRecoil(errorState, getErrorMessage(error));
      }
    },
});

export const ipScoreQuery = selectorFamily({
  key: "ipScore",
  get:
    (ip: string) =>
    ({ get }) =>
      get(ipScoreRespQuery(ip))?.data,
});

export const ipDetailRequestIdState = atom({
  key: "ipDetail/requestId",
  default: dayjs().valueOf(),
});

export const ipDetailRespQuery = selectorFamily({
  key: "ipDetail/resp",
  get:
    (ip: string) =>
    async ({ get }) => {
      get(ipDetailRequestIdState);
      try {
        const resp = await getIPDetails(ip);

        return resp.data;
      } catch (error) {
        setRecoil(errorState, getErrorMessage(error));
      }
    },
});

export const ipDetailQuery = selectorFamily({
  key: "ipDetail",
  get:
    (ip: string) =>
    ({ get }) =>
      get(ipDetailRespQuery(ip))?.data,
});
