import {
  Avatar,
  Button,
  HStack,
  Link,
  Spacer,
  Stack,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useToast,
} from "@chakra-ui/react";
import { fetchMembers } from "api";
import { CustomTr, MainHeader } from "components";
import { MemberRole, MemberStatus } from "constant";
import { Member, ParamsMembers } from "dto";
import { useEffect } from "react";
import { Link as ReactRouter } from "react-router-dom";
import { useRecoilState, useRecoilValue } from "recoil";
import { membersState, pageAtom, pageSizeAtom } from "state";
import { getErrorMessage, timestampMsToDateStr } from "utils/functions";

export function MembersPage() {
  const toast = useToast();
  const [members, setMembers] = useRecoilState(membersState);

  // 页码
  const page = useRecoilValue(pageAtom);
  const pageSize = useRecoilValue(pageSizeAtom);

  useEffect(() => {
    const asyncFunc = async () => {
      const params: ParamsMembers = {
        page,
        pageSize,
      };

      try {
        const res = await fetchMembers(params);

        setMembers(res.data.data ?? []);
      } catch (error) {
        toast({
          title: "Fetch members failed",
          description: getErrorMessage(error),
          status: "error",
        });
      }
    };

    asyncFunc();
  }, [page, pageSize, toast, setMembers]);

  return (
    <Stack>
      <HStack>
        <MainHeader />
        <Spacer />

        {/* New Member */}
        <Button
          as={ReactRouter}
          to={"create"}>
          New Member
        </Button>
      </HStack>

      <MembersTable data={members} />

      {/* Pagination */}
    </Stack>
  );
}

interface MembersTableProps {
  data: Member[];
}

function MembersTable(props: MembersTableProps) {
  return (
    <Table variant="simple">
      <Thead>
        <Tr>
          <Th>#</Th>
          <Th>Username</Th>
          <Th>Email</Th>
          <Th>Role</Th>
          <Th>Status</Th>
          <Th>Login At</Th>
          <Th>Created At</Th>
          <Th>Updated At</Th>
        </Tr>
      </Thead>

      <Tbody>
        {props.data.map((item, index) => (
          <MembersTableItem
            key={index}
            data={item}
          />
        ))}
      </Tbody>
    </Table>
  );
}

interface MembersTableItemProps {
  data: Member;
}

function MembersTableItem(props: MembersTableItemProps) {
  return (
    <CustomTr>
      <Td>
        <Link
          as={ReactRouter}
          to={props.data.id.toString()}>
          {props.data.id}
        </Link>
      </Td>
      <Td>
        <HStack>
          <Avatar
            size="sm"
            name={props.data.username}
            src={props.data.avatar}
          />
          <Text>{props.data.username}</Text>
        </HStack>
      </Td>
      <Td>{props.data.mail}</Td>

      <Td>{MemberRole[props.data.role]}</Td>
      <Td>{MemberStatus[props.data.status]}</Td>
      <Td>{timestampMsToDateStr(props.data.lastLogin)}</Td>
      <Td>{timestampMsToDateStr(props.data.createdAt)}</Td>
      <Td>{timestampMsToDateStr(props.data.updatedAt)}</Td>
    </CustomTr>
  );
}
