import { Link, LinkProps } from "@chakra-ui/react";
import { Media } from "dto";
import React from "react";
import { useSetRecoilState } from "recoil";
import { modalImageViewState } from "state";

export function ImageLink(props: ImageIdProps & LinkProps) {
  const { image, children, ...linkProps } = props;

  const setImageProps = useSetRecoilState(modalImageViewState);

  const handleClick = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    e.stopPropagation();
    setImageProps(image);
  };

  return (
    <Link
      {...linkProps}
      onClick={handleClick}>
      {children}
    </Link>
  );
}

export function ImageId({ image }: { image: Media }) {
  return <ImageLink image={image}>#{image.id}</ImageLink>;
}

interface ImageIdProps {
  image: Media;
}
