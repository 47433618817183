import { Divider } from "@chakra-ui/react";
import {
  BioStatusTag,
  CustomHighlighter,
  CustomStat,
  CustomStatLabel,
  CustomStatValue,
} from "components";
import { useRecoilState, useRecoilValue } from "recoil";
import { bioListToRejectAtom, userInfoBioQuery } from "state";
import { toggleItemOfArr } from "utils/functions";
import { DescriptionStack } from "./components";

export function BioPanel() {
  const bio = useRecoilValue(userInfoBioQuery);
  const [bioListToReject, setBioListToReject] =
    useRecoilState(bioListToRejectAtom);

  if (!bio) return <></>;

  const handleClick = () => {
    setBioListToReject((curr) => {
      return toggleItemOfArr(curr, bio.uid);
    });
  };

  return (
    <>
      {bio && bio?.bio !== "" && (
        <DescriptionStack
          isSelected={bioListToReject.includes(bio.uid)}
          onClick={handleClick}>
          <CustomStat>
            <CustomStatLabel>Bio</CustomStatLabel>
            <CustomStatValue>
              <CustomHighlighter content={bio.bio} />
              <Divider />
              {bio.observerBio && (
                <CustomHighlighter content={bio.observerBio} />
              )}
            </CustomStatValue>
          </CustomStat>

          <BioStatusTag status={bio.status} />
        </DescriptionStack>
      )}
    </>
  );
}
