import { SearchParam } from "constant";
import { ReviewHistoryTable } from "features";
import { useParams } from "react-router-dom";

export function ReviewHistoryPage() {
  const params = useParams();
  const uid = params[SearchParam.UID];

  if (!uid) return <></>;

  return <ReviewHistoryTable uid={+uid} />;
}
