import { CustomTabProps, CustomTabs } from "components";
import { BoostsTable } from "./components/BoostsTable";
import { SubscriptionsTable } from "./components/SubscriptionsTable";
import { SuperFlipsTable } from "./components/SuperFlipsTable";

const tabs: CustomTabProps[] = [
  { title: "Subscriptions" },
  { title: "Boosts" },
  { title: "Super Flips" },
];

export function PurchaseOverview() {
  return (
    <CustomTabs tabs={tabs}>
      <SubscriptionsTable />
      <BoostsTable />
      <SuperFlipsTable />
    </CustomTabs>
  );
}
