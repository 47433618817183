import {
  Box,
  Center,
  HStack,
  Image,
  Spacer,
  Stack,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import { ZStack } from "components";
import { MessageType } from "constant";
import {
  AudioMessage,
  LocationMessage,
  Message,
  PhotoMessage,
  TextMessage,
  VideoMessage,
} from "dto";
import { BiUserVoice } from "react-icons/bi";
import { BsPauseCircle, BsPlayCircle } from "react-icons/bs";
import { GoLocation } from "react-icons/go";
import { useAudio, useVideo } from "react-use";
import { useRecoilValue } from "recoil";
import { userInfoQuery } from "state";
import { timestampMsToDateStr } from "utils/functions";

export function MessageCell(props: MessageCellProps) {
  const grayBg = useColorModeValue("gray.100", "gray.600");

  const { message } = props;

  const userInfo = useRecoilValue(userInfoQuery);

  if (!userInfo) return <></>;

  // 是否是自己发出的消息
  const outgoing = userInfo.uuid === message.from;

  // 根据消息类型展示UI
  const getMessageBodyView = () => {
    switch (message.type) {
      case MessageType.Text:
        const text = message.body as TextMessage;
        return (
          <Text
            py={2}
            px={4}>
            {text.msg}
          </Text>
        );
      case MessageType.Photo:
        const photo = message.body as PhotoMessage;
        return (
          <Image
            w="315px"
            src={photo.url}
            alt={photo.name}
          />
        );
      case MessageType.Audio:
        const audio = message.body as AudioMessage;
        return <AudioMessagePlayer audio={audio} />;
      case MessageType.Video:
        const video = message.body as VideoMessage;
        return <VideoMessagePlayer video={video} />;
      case MessageType.Location:
        const location = message.body as LocationMessage;
        return (
          <HStack
            px={4}
            py={2}>
            <GoLocation size="20px" />
            <Text
              py={2}
              px={4}>
              {location.title}
            </Text>
          </HStack>
        );
      case MessageType.Notification:
        return (
          <Text
            py={2}
            px={4}>
            "[Notification]"
          </Text>
        );
      case MessageType.File:
        return (
          <Text
            py={2}
            px={4}>
            "[File]"
          </Text>
        );
      case MessageType.Custom:
        return (
          <Text
            py={2}
            px={4}>
            "[Custom]"
          </Text>
        );
      default:
        return (
          <Text
            py={2}
            px={4}>
            "[]"
          </Text>
        );
    }
  };

  return (
    <HStack>
      {outgoing && <Spacer />}
      <Stack
        spacing={0}
        alignItems={outgoing ? "flex-end" : "flex-start"}>
        <Box
          maxW="500px"
          borderRadius={10}
          overflow="hidden"
          bg={outgoing ? "teal" : grayBg}
          color={outgoing ? "white" : ""}>
          {getMessageBodyView()}
        </Box>
        <Text
          fontSize="12px"
          opacity={0.5}
          whiteSpace="nowrap">
          {timestampMsToDateStr(message.sendTime)}
        </Text>
      </Stack>
    </HStack>
  );
}

interface MessageCellProps {
  message: Message;
}

function VideoMessagePlayer(props: VideoMessagePlayerProps) {
  const [video, state, controls] = useVideo(<video src={props.video.url} />);

  return (
    <ZStack
      w={props.video.w}
      h={props.video.h}>
      {/* play & pause */}
      {/* 未播放时，展示播放按钮
             /* 播放时，隐藏暂停按钮
             /* 鼠标指向时，展示暂停按钮
             */}
      <Center
        h="100%"
        onClick={state.playing ? controls.pause : controls.play}
        opacity={state.playing ? 0 : 1}
        _hover={{ opacity: 1, cursor: "pointer" }}>
        {!state.playing && <BsPlayCircle size="44px" />}
        {state.playing && <BsPauseCircle size="44px" />}
      </Center>

      <HStack
        px={4}
        py={2}>
        <Spacer />
        <Text>
          {Math.ceil(
            state.playing ? state.duration - state.time : props.video.dur / 1000
          )}
          s
        </Text>
      </HStack>

      {/* video player */}
      {video}
    </ZStack>
  );
}

interface VideoMessagePlayerProps {
  video: VideoMessage;
}

function AudioMessagePlayer(props: AudioMessagePlayerProps) {
  const [audio, state, controls] = useAudio({
    src: props.audio.url,
    autoPlay: false,
  });

  return (
    <HStack
      px={4}
      py={2}
      onClick={!state.playing ? controls.play : () => {}}
      _hover={{ cursor: "pointer" }}>
      <ZStack>
        <BiUserVoice size="20px" />
        {audio}
      </ZStack>
      <HStack
        w={Math.ceil((props.audio.dur / 100) * 1) + "px"}
        minW="20px"
        maxW="315px">
        <Spacer />
        <Text>
          {Math.ceil(
            state.playing ? state.duration - state.time : props.audio.dur / 1000
          )}
          s
        </Text>
      </HStack>
    </HStack>
  );
}

interface AudioMessagePlayerProps {
  audio: AudioMessage;
}
