import { fetchReports } from "api";
import { genderAtom, reportStatusQuery } from "components";
import { Report, ReportsParams, ReportsResp } from "dto";
import { selector } from "recoil";
import { setRecoil } from "recoil-nexus";
import { getErrorMessage } from "utils/functions";
import { errorState, pageSizeAtom, requestIdState } from "./common";
import { pageAtom } from "./search";

export const reportsRespQuery = selector<ReportsResp | undefined>({
  key: "reports/resp",
  get: async ({ get }) => {
    // request id for refresh
    get(requestIdState);

    const params: ReportsParams = {
      page: get(pageAtom),
      pageSize: get(pageSizeAtom),
      genders: [get(genderAtom)],
      status: get(reportStatusQuery),
    };

    try {
      const resp = await fetchReports(params);
      return resp.data;
    } catch (error) {
      setRecoil(errorState, getErrorMessage(error));
    }
  },
});

export const reportsQuery = selector<Report[]>({
  key: "reports",
  get: ({ get }) => {
    return get(reportsRespQuery)?.data ?? [];
  },
});

export const reportsTotalQuery = selector({
  key: "reports/total",
  get: ({ get }) => {
    const resp = get(reportsRespQuery);
    return resp?.page?.total ?? 0;
  },
});
