import { Spinner, Stack } from "@chakra-ui/react";
import { UserCard } from "components";
import { Suspense, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { RouterURI } from "router/RouterURI";
import { profileRouteURIState, profilesQuery } from "state";

export function ProfilesPage() {
  const setProfileRouteURI = useSetRecoilState(profileRouteURIState);

  const location = useLocation();

  useEffect(() => {
    setProfileRouteURI(location.pathname as RouterURI);

    return () => {
      setProfileRouteURI(undefined);
    };
  }, [setProfileRouteURI, location]);

  return (
    <Suspense fallback={<Spinner />}>
      <ProfilesGrid />
    </Suspense>
  );
}

function ProfilesGrid() {
  const profiles = useRecoilValue(profilesQuery);

  return (
    <Stack>
      {profiles.map((profile, index) => (
        <UserCard
          key={index}
          user={profile}
        />
      ))}
    </Stack>
  );
}
