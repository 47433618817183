import { SearchParam } from "constant";
import { MainLayout, PurchaseLayout, ReviewLayout } from "layouts";
import { Suspense } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import {
  BioPage,
  BlacklistPage,
  CreateMemberPage,
  Dashboard,
  DevicesPage,
  EditMemberPage,
  HighlightWordsPage,
  LocationsPage,
  LoginPage,
  MembersPage,
  PhotosPage,
  ProductTransactions,
  ProfilesPage,
  PurchaseNotifications,
  PurchaseOverview,
  QuestionsPage,
  ReceiptTransactions,
  ReportsPage,
  ReviewHistoryPage,
  SettingsPage,
  SubscriptionTransfer,
  UserPage,
  UsersPage,
  VoicesPage,
} from "routes";
import { RequireAuth } from "utils/hooks";
import { RouterURI } from "./RouterURI";

export function AppRouter() {
  return (
    <BrowserRouter>
      <Routes>
        <Route
          path="/"
          element={
            <RequireAuth>
              <MainLayout />
            </RequireAuth>
          }>
          <Route
            index
            element={<Dashboard />}
          />

          {/* Review Section */}
          <Route element={<ReviewLayout />}>
            <Route
              path={RouterURI.Normal}
              element={<ProfilesPage />}
            />
            <Route
              path={RouterURI.Confirmed}
              element={<ProfilesPage />}
            />
            <Route
              path={RouterURI.Suspended}
              element={<ProfilesPage />}
            />
            <Route
              path={RouterURI.Banned}
              element={<ProfilesPage />}
            />
            <Route
              path={RouterURI.Bio}
              element={<BioPage />}
            />
            <Route
              path={RouterURI.Questions}
              element={<QuestionsPage />}
            />
            <Route
              path={RouterURI.Voices}
              element={<VoicesPage />}
            />
            <Route
              path={RouterURI.Reports}
              element={<ReportsPage />}
            />
            <Route
              path={RouterURI.Photos}
              element={<PhotosPage />}
            />
          </Route>

          <Route path={RouterURI.Users}>
            <Route
              index
              element={<UsersPage />}
            />
            <Route path={`:${SearchParam.UID}`}>
              <Route
                index
                element={<UserPage />}
              />
              <Route
                path={"locations"}
                element={<LocationsPage />}
              />
              <Route
                path={"review_histories"}
                element={<ReviewHistoryPage />}
              />
            </Route>
          </Route>

          <Route
            path={RouterURI.Devices}
            element={<DevicesPage />}
          />

          {/* Purchase */}
          <Route element={<PurchaseLayout />}>
            <Route
              path={RouterURI.PurchaseOverview}
              element={<PurchaseOverview />}
            />
            <Route
              path={RouterURI.ReceiptTransactions}
              element={<ReceiptTransactions />}
            />
            <Route
              path={RouterURI.ProductTransactions}
              element={<ProductTransactions />}
            />
            <Route
              path={RouterURI.SubscriptionTransfer}
              element={<SubscriptionTransfer />}
            />
            <Route
              path={RouterURI.PurchaseNotifications}
              element={<PurchaseNotifications />}
            />
          </Route>

          {/* Manage Section */}
          <Route path={RouterURI.Members}>
            <Route
              index
              element={<MembersPage />}
            />
            <Route
              path="create"
              element={<CreateMemberPage />}
            />
            <Route
              path=":memberId"
              element={
                <Suspense fallback={<p>Loading...</p>}>
                  <EditMemberPage />
                </Suspense>
              }
            />
          </Route>
          <Route path={RouterURI.HighlightWords}>
            <Route
              index
              element={<HighlightWordsPage />}
            />
          </Route>
          <Route path={RouterURI.BlackList}>
            <Route
              index
              element={<BlacklistPage />}
            />
          </Route>

          <Route
            path="/settings"
            element={<SettingsPage />}
          />

          <Route
            path="/*"
            element={<p>404</p>}
          />
        </Route>

        <Route
          path="/login"
          element={<LoginPage />}
        />
        <Route
          path="/*"
          element={<p>404</p>}
        />
      </Routes>
    </BrowserRouter>
  );
}
