import { Wrap, WrapItem } from "@chakra-ui/react";
import { UserPhotoCard } from "components/UserCard";
import { useRecoilValue } from "recoil";
import { photosQuery } from "state";

export function PhotosGrid() {
  const photos = useRecoilValue(photosQuery);

  return (
    <Wrap spacing="1px">
      {photos.map((photo, index) => (
        <WrapItem key={index}>
          <UserPhotoCard photo={photo} />
        </WrapItem>
      ))}
    </Wrap>
  );
}
