import dayjs from "dayjs";
import { atom, selectorFamily } from "recoil";
import { pageAtom, pageSizeAtom } from "state";
import { searchReviewHistories } from "./review-history.api";

export const reviewHistoriesRequestIdAtom = atom<number>({
  key: "reviewHistories/requestId",
  default: dayjs().valueOf(),
});

export const reviewHistoriesRespQuery = selectorFamily({
  key: "reviewHistories/resp",
  get:
    (uid: number) =>
    async ({ get }) => {
      //
      get(reviewHistoriesRequestIdAtom);

      const page = get(pageAtom);
      const pageSize = get(pageSizeAtom);

      try {
        const resp = await searchReviewHistories({
          page,
          pageSize,
          uid,
        });
        return resp.data;
      } catch (error) {
        // handle error
      }
    },
});

export const reviewHistoriesQuery = selectorFamily({
  key: "reviewHistories",
  get:
    (uid: number) =>
    ({ get }) => {
      const resp = get(reviewHistoriesRespQuery(uid));

      if (resp?.error) {
        // handle error
      }

      return resp?.data;
    },
});

export const reviewHistoriesTotalQuery = selectorFamily({
  key: "reviewHistories/total",
  get:
    (uid: number) =>
    ({ get }) => {
      const resp = get(reviewHistoriesRespQuery(uid));
      //
      return resp?.page?.total ?? 0;
    },
});
