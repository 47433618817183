export enum BodyType {
  Thin = 1,
  Overweight,
  "Average build",
  Fit,
  Jacked,
  "A little extra",
  Curvy,
  "Full figure",
  "Prefer not to say",
}

export enum Drinking {
  Yes = 1,
  Sometime,
  No,
  "Prefer not to say",
}

export enum Drugs {
  Yes = 1,
  Sometimes,
  No,
  "Prefer not to say",
}

export enum Ethnicity {
  Asian = 1,
  Black,
  "Hispanic / Latin",
  Indian,
  "Middle Eastern",
  "Native American",
  "Pacific Islander",
  White,
  Other,
  "Prefer not to say",
}

export enum Education {
  "High school" = 1,
  Undergrad,
  Postgraduate,
  "Prefer not to say",
}

export enum RelationshipStatus {
  Single = 1,
  Married,
  "In a relationship",
  "Prefer not to say",
}

export enum Religion {
  Buddhist = 1,
  Catholic,
  Christian,
  Hindu,
  Jewish,
  Muslim,
  Spiritual,
  Agnostic,
  Atheist,
  Other,
  "Prefer not to say",
}

export enum Smoking {
  Yes = 1,
  Sometimes,
  No,
  "Prefer not to say",
}

export enum STDCheck {
  Regularly = 1,
  Sometimes,
  Never,
  "Prefer not to say",
}

export enum STDStatus {
  Positive = 1,
  Negative,
  "Not sure",
  "Prefer not to say",
}

export enum BioStatus {
  Rejected = -1,
  Pending = 0,
  Approved = 1,
  Deleted = 99, // 需同bill确认此状态是否存在
}

export enum QuestionStatus {
  Rejected = -1,
  Pending = 0,
  Approved = 1,
  Deleted = 99,
}

export enum UserStatus {
  Banned = -2, // 手动ban掉
  Rejected = -1, // 照片为空或全被Ban
  Pending = 0,
  Approved = 1,
  Deleted = 99,
  DeletedFromS3 = 125,
}

export enum ApprovedType {
  Pending,
  Manual,
  AI,
}

export enum VerifyStatus {
  Pending,
  Verified,
}

export enum ReportStatus {
  Pending,
  Checked,
}

export enum RecommendedStatus {
  False = 0,
  True,
}

export enum RelationshipType {
  Passed = 0, // "不喜欢"
  Liked = 1, // "喜欢"
  Chatted = 2, // "首次发送消息"
  Blocked = 3, // "拉黑"
  Flipped = 4, // "普通翻"
  "Super Flipped" = 5, // "超级翻"
  Matched = 6, // "matched"
}

export enum RiskStatus {
  Unknown = 0,
  Normal = 1,
  Confirmed = 2,
  Suspended = 3,
  Banned = 4,
  Underage = 5,
}

export enum ReviewStatus {
  Reviewed,
  InReview,
}

export enum Gender {
  Woman = 1,
  Man = 2,
  Couple = 4,
  Couple_2W = 8,
  Couple_2M = 16,
  Trans = 32,
}

export enum BanAction {
  Restore,
  Ban,
}
