import { HStack } from "@chakra-ui/react";
import { UserPhotoCard, UserVerifyInfoCard, VoiceCard } from "components";
import { useRecoilValue } from "recoil";
import { userInfoQuery } from "state";

export function MediaPanel() {
  const userInfo = useRecoilValue(userInfoQuery);

  if (!userInfo) return <></>;

  return (
    <HStack>
      <UserVerifyInfoCard
        verifyMedia={userInfo.verify}
        otherVerifyMedia={userInfo.oldVerifies}
      />

      {userInfo.photos?.map((photo, index) => (
        <UserPhotoCard
          photo={photo}
          key={index}
        />
      ))}

      {userInfo.voice && <VoiceCard voice={userInfo.voice} />}
    </HStack>
  );
}
