import { Box, Spinner, Stack } from "@chakra-ui/react";
import { Suspense, useEffect, useRef } from "react";
import { useRecoilValue } from "recoil";
import { messagesQuery } from "state";
import { MessageCell } from "./MessageCell";
import { MessagesViewHeader } from "./MessagesViewHeader";


export function MessagesView() {
  return (
    <Stack p={2}>
      <Suspense fallback={<Spinner />}>
        <MessagesViewHeader />
        <MessagesViewList />
      </Suspense>
    </Stack>
  );
}


function MessagesViewList() {

  const messages = useRecoilValue(messagesQuery)

  const messageEndRef = useRef<null | HTMLDivElement>(null);

  const scrollToBottom = () => {
    messageEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  useEffect(() => {
    scrollToBottom();
  });

  return (
    <Stack>
      {messages?.msgs.map((message, i) => (
        <MessageCell key={i} message={message} />
      ))}
      {/* dummy */}
      <Box ref={messageEndRef} />
    </Stack>
  )
}