import {
  Avatar,
  Box,
  HStack,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
  Text,
} from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { RouterURI } from "router/RouterURI";
import { signedInUserAtom } from "state";
import { IS_DEBUG } from "utils/functions";
import { auth } from "../../firebase";

export function UserMenu() {
  const navigate = useNavigate();
  const signedInUser = useRecoilValue(signedInUserAtom);

  if (IS_DEBUG) return <p>DEBUG</p>;

  if (!signedInUser) return <></>;

  const getNavigateToMemberDetails = async () => {
    const token = await signedInUser.getIdTokenResult();
    const { cuid } = token.claims;
    navigate(RouterURI.Members + "/" + cuid);
  };

  return (
    <Box>
      <Menu isLazy>
        <MenuButton>
          <HStack>
            <Text>
              {signedInUser.email || signedInUser.displayName || "--"}
            </Text>
            <Avatar
              size="sm"
              name={signedInUser.displayName ?? undefined}
              src={signedInUser.photoURL ?? undefined}
            />
          </HStack>
        </MenuButton>
        <MenuList>
          <MenuItem onClick={getNavigateToMemberDetails}>My Account</MenuItem>
          <MenuDivider />
          <MenuItem>
            <Text
              fontSize="medium"
              onClick={() => auth.signOut()}>
              Sign Out
            </Text>
          </MenuItem>
        </MenuList>
      </Menu>
    </Box>
  );
}
