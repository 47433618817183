import { Highlight } from "@chakra-ui/react";
import { ZStack } from "components";
import { useRecoilValue } from "recoil";
import { offensiveWordsQuery, spamWordsQuery } from "../word.state";

export function CustomHighlighter(props: CustomHighlighterProps) {
  const spamWords = useRecoilValue(spamWordsQuery);
  const offensiveWords = useRecoilValue(offensiveWordsQuery);
  return (
    <ZStack
      fontSize="20px"
      wordBreak="break-all">
      <Highlight
        query={offensiveWords.map((item) => item.word)}
        styles={{ bg: "green.600" }}>
        {props.content}
      </Highlight>
      <Highlight
        query={spamWords.map((item) => item.word)}
        styles={{ bg: "red.100" }}>
        {props.content}
      </Highlight>
    </ZStack>
  );
}
interface CustomHighlighterProps {
  content: string;
}
