import { Suspense } from "react";
import { RecoilValueReadOnly } from "recoil";
import { PaginationContent } from "./PaginationContent";

export function Pagination(props: PaginationProps) {
  return (
    <Suspense fallback={<></>}>
      {props.state && <PaginationContent state={props.state} />}
    </Suspense>
  );
}

interface PaginationProps {
  state?: RecoilValueReadOnly<number>;
}
