import { Breadcrumb, BreadcrumbItem, BreadcrumbLink } from "@chakra-ui/react";
import dayjs from "dayjs";
import { NavLink } from "react-router-dom";
import { useResetRecoilState, useSetRecoilState } from "recoil";
import {
  imeiAtom,
  pageAtom,
  requestIdState,
  shortUUIDAtom,
  uidAtom,
  uuidAtom,
} from "state";
import useBreadcrumbs from "use-react-router-breadcrumbs";

export function Breadcrumbs() {
  const breadcrumbs = useBreadcrumbs();

  const setTimestamp = useSetRecoilState(requestIdState);

  const resetPage = useResetRecoilState(pageAtom);
  const resetUID = useResetRecoilState(uidAtom);
  const resetShortUUID = useResetRecoilState(shortUUIDAtom);
  const resetUUID = useResetRecoilState(uuidAtom);
  const resetIMEI = useResetRecoilState(imeiAtom);

  const handleClick = () => {
    setTimestamp(dayjs().valueOf());
    resetPage();
    resetShortUUID();
    resetUUID();
    resetUID();
    resetIMEI();
  };

  return (
    <Breadcrumb>
      {breadcrumbs.map((breadcrumb, index) => (
        <BreadcrumbItem key={index}>
          <BreadcrumbLink
            as={NavLink}
            to={breadcrumb.match}
            onClick={handleClick}>
            {breadcrumb.breadcrumb}
          </BreadcrumbLink>
        </BreadcrumbItem>
      ))}
    </Breadcrumb>
  );
}
