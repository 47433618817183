import { Spacer, Stack } from "@chakra-ui/react";
import {
  DateFilter,
  HeaderStack,
  MainHeader,
  Pagination,
  PurchaseFromFilter,
  tabAtom,
} from "components";
import { SearchUID } from "components/filters/SearchUID";
import { Outlet, useLocation } from "react-router-dom";
import { RecoilValueReadOnly, useRecoilValue } from "recoil";
import { RouterURI } from "router/RouterURI";
import {
  boostsTotalQuery,
  notificationsTotalQuery,
  productTransactionsTotalQuery,
  subscriptionsTotalQuery,
  superFlipsTotalQuery,
  transactionsTotalQuery,
  transferRecordsTotalQuery,
} from "state";

export function PurchaseLayout() {
  const location = useLocation();

  const option = triningItemOptions.find(
    (opt) => opt.pathname === location.pathname
  );

  const currentTab = useRecoilValue(tabAtom);

  const getState = () => {
    if (option?.totalStates) {
      return option.totalStates[option.totalStates.length > 1 ? currentTab : 0];
    } else {
      return undefined;
    }
  };

  return (
    <Stack>
      <HeaderStack>
        <MainHeader totalState={getState()} />
        <Spacer />

        {option?.components.map((Component, index) => (
          <Component key={index} />
        ))}
      </HeaderStack>

      <Outlet />

      <Pagination state={getState()} />
    </Stack>
  );
}

const triningItemOptions: TrainingItemOption[] = [
  {
    pathname: RouterURI.PurchaseOverview,
    components: [SearchUID, PurchaseFromFilter, DateFilter],
    totalStates: [
      subscriptionsTotalQuery,
      superFlipsTotalQuery,
      boostsTotalQuery,
    ],
  },
  {
    pathname: RouterURI.ReceiptTransactions,
    components: [SearchUID, PurchaseFromFilter, DateFilter],
    totalStates: [transactionsTotalQuery],
  },
  {
    pathname: RouterURI.ProductTransactions,
    components: [SearchUID, DateFilter],
    totalStates: [productTransactionsTotalQuery],
  },
  {
    pathname: RouterURI.SubscriptionTransfer,
    components: [SearchUID, DateFilter],
    totalStates: [transferRecordsTotalQuery],
  },
  {
    pathname: RouterURI.PurchaseNotifications,
    components: [PurchaseFromFilter, DateFilter],
    totalStates: [notificationsTotalQuery],
  },
];

interface TrainingItemOption {
  pathname: RouterURI;
  components: (() => JSX.Element)[];
  totalStates?: RecoilValueReadOnly<number>[];
}
