import {
  Box,
  Button,
  Menu,
  MenuButton,
  MenuItemOption,
  MenuList,
  MenuOptionGroup,
  Tab,
  TabList,
  Tabs,
} from "@chakra-ui/react";
import { number } from "@recoiljs/refine";
import { Gender } from "constant";
import { useState } from "react";
import { IoChevronDown } from "react-icons/io5";
import { atom, useRecoilState, useSetRecoilState } from "recoil";
import { syncEffect } from "recoil-sync";

const genders = [
  Gender.Woman,
  Gender.Man,
  Gender.Couple,
  Gender.Couple_2W,
  Gender.Couple_2M,
  Gender.Trans,
];

export const gendersAtom = atom<string[]>({
  key: "genders",
  default: [],
});

export const genderAtom = atom({
  key: "g",
  default: genders[0],
  effects: [syncEffect({ refine: number() })],
});

export function GenderStatusesFilter() {
  const optionType = "checkbox";

  const setSelectedGenders = useSetRecoilState(gendersAtom);
  const setSelectedGender = useSetRecoilState(genderAtom);

  const [_genders, setGenders] = useState<string | string[]>(
    optionType === "checkbox" ? [] : ""
  );

  const menuItemOptions = genders.map((g) => (
    <MenuItemOption
      value={g.toString()}
      key={g.toString()}>
      {Gender[g]}
    </MenuItemOption>
  ));

  const handleApply = () => {
    console.log(_genders);
    if (optionType === "checkbox") {
      setSelectedGenders(_genders as string[]);
    } else {
      setSelectedGender(+(_genders as string));
    }
  };

  return (
    <Box>
      <Menu
        closeOnSelect={false}
        onClose={handleApply}>
        <MenuButton
          as={Button}
          variant="outline"
          colorScheme="gray"
          rightIcon={<IoChevronDown />}>
          Genders
        </MenuButton>
        <MenuList>
          <MenuOptionGroup
            title="Filtered Genders"
            type={optionType}
            onChange={setGenders}>
            {menuItemOptions}
          </MenuOptionGroup>
        </MenuList>
      </Menu>
    </Box>
  );
}

export function GenderTabs() {
  const [gender, setGender] = useRecoilState(genderAtom);

  return (
    <Tabs
      isLazy
      variant="solid-rounded"
      index={genders.indexOf(gender)}
      onChange={(i) => setGender(genders[i])}>
      <TabList>
        {genders.map((g, index) => (
          <Tab key={index}>{Gender[g]}</Tab>
        ))}
      </TabList>
    </Tabs>
  );
}
