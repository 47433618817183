import {
  AutoStatus,
  BioStatus,
  MediaStatus,
  QuestionStatus,
  ReportStatus,
  RiskStatus,
} from "constant";
import { PropertyToReview, User, UserInfo, UserToReview } from "dto";
import { atom, selector } from "recoil";
import { photosQuery, voicesQuery } from "./media";
import {
  bioListQuery,
  profilesQuery,
  questionsQuery,
  updatedProfilesAtom,
  userInfoQuery,
} from "./user";

export const usersToRejectAtom = atom<number[]>({
  key: "usersToReject",
  default: [],
});

export const photosToRejectAtom = atom<number[]>({
  key: "photosToReject",
  default: [],
});

export const voicesToRejectAtom = atom<number[]>({
  key: "voicesToReject",
  default: [],
});

export const verifyImagesToRejectAtom = atom<number[]>({
  key: "verifyImagesToReject",
  default: [],
});

export const bioListToRejectAtom = atom<number[]>({
  key: "bioListToReject",
  default: [],
});

export const questionsToRejectAtom = atom<number[]>({
  key: "questionsToReject",
  default: [],
});

export const usersToReviewQuery = selector<UserToReview>({
  key: "usersToReview",
  get: ({ get }) => {
    const profiles = get(profilesQuery);
    const updatedProfiles = get(updatedProfilesAtom);

    const pendingRejectedUserIds = get(usersToRejectAtom);
    const pendingRejectedPhotoIds = get(photosToRejectAtom);
    const pendingRejectedVerifyPhotoIds = get(verifyImagesToRejectAtom);

    return generateFinalSubmissionProfilesData(
      profiles,
      updatedProfiles,
      pendingRejectedUserIds,
      pendingRejectedPhotoIds,
      pendingRejectedVerifyPhotoIds
    );
  },
});

export const photosToReviewQuery = selector<UserToReview>({
  key: "photosToReview",
  get: ({ get }) => {
    const userToReview: UserToReview = {};

    const photos = get(photosQuery);

    const photosToReject = get(photosToRejectAtom);

    if (photos.length > 0) {
      const photosProperty: PropertyToReview = {
        approvedIds: [],
        rejectedIds: [],
      };

      photos.forEach((photo) => {
        if (photosToReject.includes(photo.id)) {
          photosProperty.rejectedIds.push(photo.id);
        } else if (photo.status === MediaStatus.Pending) {
          photosProperty.approvedIds.push(photo.id);
        } else if (photo.autoStatus === AutoStatus.Pending) {
          if (
            [MediaStatus.Rejected, MediaStatus.Deleted].includes(photo.status)
          ) {
            photosProperty.rejectedIds.push(photo.id);
          } else {
            photosProperty.approvedIds.push(photo.id);
          }
        }
      });

      userToReview.photos = photosProperty;
    }

    return userToReview;
  },
});

export const bioListToReviewQuery = selector<UserToReview>({
  key: "bioListToReview",
  get: ({ get }) => {
    const userToReview: UserToReview = {};

    const bioList = get(bioListQuery);
    const bioListToReject = get(bioListToRejectAtom);

    if (bioList.length > 0) {
      const bioProperty: PropertyToReview = {
        approvedIds: [],
        rejectedIds: [],
      };

      bioList.forEach((bio) => {
        if (bioListToReject.includes(bio.uid)) {
          bioProperty.rejectedIds.push(bio.uid);
        } else if (bio.status === BioStatus.Pending) {
          bioProperty.approvedIds.push(bio.uid);
        }
      });

      userToReview.bio = bioProperty;
    }
    return userToReview;
  },
});

export const questionsToReviewQuery = selector<UserToReview>({
  key: "questionsToReview",
  get: ({ get }) => {
    const userToReview: UserToReview = {};

    const questions = get(questionsQuery);
    const questionsToReject = get(questionsToRejectAtom);

    if (questions.length > 0) {
      const questionsProperty: PropertyToReview = {
        approvedIds: [],
        rejectedIds: [],
      };

      questions.forEach((question) => {
        if (questionsToReject.includes(question.id)) {
          questionsProperty.rejectedIds.push(question.id);
        } else if (question.status === QuestionStatus.Pending) {
          questionsProperty.approvedIds.push(question.id);
        }
      });

      userToReview.question = questionsProperty;
    }

    return userToReview;
  },
});

export const voicesToReviewQuery = selector<UserToReview>({
  key: "voicesToReview",
  get: ({ get }) => {
    const userToReview: UserToReview = {};

    const voices = get(voicesQuery);

    const voicesToReject = get(voicesToRejectAtom);

    if (voices.length > 0) {
      const voicesProperty: PropertyToReview = {
        approvedIds: [],
        rejectedIds: [],
      };

      voices.forEach((voice) => {
        if (voicesToReject.includes(voice.id)) {
          voicesProperty.rejectedIds.push(voice.id);
        } else if (voice.status === MediaStatus.Pending) {
          voicesProperty.approvedIds.push(voice.id);
        }
      });

      userToReview.voice = voicesProperty;
    }

    return userToReview;
  },
});

export const reportsToReviewQuery = selector<UserToReview>({
  key: "reportsToReview",
  get: ({ get }) => {
    const userToReview: UserToReview = {};

    return userToReview;
  },
});

export const userToReviewQuery = selector<UserToReview>({
  key: "userToReviewQuery",
  get: ({ get }) => {
    let userToReview: UserToReview = {};

    const userInfo = get(userInfoQuery);

    const updatedProfiles = get(updatedProfilesAtom);

    const pendingRejectedUserIds = get(usersToRejectAtom);
    const pendingRejectedPhotoIds = get(photosToRejectAtom);
    const pendingRejectedVerifyPhotoIds = get(verifyImagesToRejectAtom);

    const pendingRejectedBioIds = get(bioListToRejectAtom);
    const pendingRejectedQuestionIds = get(questionsToRejectAtom);
    const pendingRejectedVoiceIds = get(voicesToRejectAtom);

    if (userInfo) {
      userToReview = generateFinalSubmissionProfileData(
        userInfo,
        updatedProfiles,
        pendingRejectedUserIds,
        pendingRejectedPhotoIds,
        pendingRejectedVerifyPhotoIds,
        pendingRejectedBioIds,
        pendingRejectedQuestionIds,
        pendingRejectedVoiceIds
      );
    }

    return userToReview;
  },
});

// 用户列表中的基础资料
function generateFinalSubmissionProfilesData(
  profiles: User[],
  updatedProfiles: User[],
  pendingRejectedUserIds: number[],
  pendingRejectedPhotoIds: number[],
  pendingRejectedVerifyPhotoIds: number[]
) {
  const userToReview: UserToReview = {};

  const verifyProperty: PropertyToReview = {
    approvedIds: [],
    rejectedIds: [],
  };

  const photosProperty: PropertyToReview = {
    approvedIds: [],
    rejectedIds: [],
  };

  const userProperty: PropertyToReview = {
    approvedIds: [],
    rejectedIds: [],
  };

  const deviceProperty: PropertyToReview = {
    approvedIds: [],
    rejectedIds: [],
  };

  // 转换数据
  profiles.forEach((profile) => {
    // verify photo
    if (profile.verify) {
      if (pendingRejectedVerifyPhotoIds.includes(profile.verify.id)) {
        // 已标记为为reject
        verifyProperty.rejectedIds.push(profile.verify.id);
      } else if (profile.verify.status === MediaStatus.Pending) {
        // 未标记为reject，默认从pending变approved
        verifyProperty.approvedIds.push(profile.verify.id);
      }

      userToReview.verify = verifyProperty;
    }

    // profile photos
    profile.photos?.forEach((photo) => {
      if (pendingRejectedPhotoIds.includes(photo.id)) {
        photosProperty.rejectedIds.push(photo.id);
      } else if (photo.status === MediaStatus.Pending) {
        photosProperty.approvedIds.push(photo.id);
      } else if (photo.autoStatus === AutoStatus.Pending) {
        if (
          [MediaStatus.Rejected, MediaStatus.Deleted].includes(photo.status)
        ) {
          // rejected或deleted状态下未人工确认的照片
          // 主要用户修正数据，理论上不应该出现这类数据
          photosProperty.rejectedIds.push(photo.id);
        } else {
          photosProperty.approvedIds.push(photo.id);
        }
      }

      userToReview.photos = photosProperty;
    });

    // risk status
    const targetProfile =
      updatedProfiles.find((p) => p.uid === profile.uid) ?? profile;
    if (targetProfile.riskStatus !== RiskStatus.Underage) {
      if (pendingRejectedUserIds.includes(targetProfile.uid)) {
        userProperty.rejectedIds.push(targetProfile.uid);
        deviceProperty.rejectedIds.push(targetProfile.uid);
      } else if (
        [RiskStatus.Normal, RiskStatus.Suspended].includes(
          targetProfile.riskStatus
        )
      ) {
        // 若在normal或suspended列表时，未明确为ban，则点击submit会将用户标记为confirmed
        userProperty.approvedIds.push(targetProfile.uid);
      }

      userToReview.user = userProperty;
      userToReview.device = deviceProperty;
    }
  });

  return userToReview;
}

// 用户资料详情
function generateFinalSubmissionProfileData(
  profile: UserInfo,
  updatedProfiles: User[],
  pendingRejectedUserIds: number[],
  pendingRejectedPhotoIds: number[],
  pendingRejectedVerifyPhotoIds: number[],
  pendingRejectedBioIds: number[],
  pendingRejectedQuestionIds: number[],
  pendingRejectedVoiceIds: number[]
) {
  const userToReview = generateFinalSubmissionProfilesData(
    [profile],
    updatedProfiles,
    pendingRejectedUserIds,
    pendingRejectedPhotoIds,
    pendingRejectedVerifyPhotoIds
  );

  // voice
  if (profile.voice) {
    const voiceProperty: PropertyToReview = {
      approvedIds: [],
      rejectedIds: [],
    };

    if (pendingRejectedVoiceIds.includes(profile.voice.id)) {
      voiceProperty.rejectedIds.push(profile.voice.id);
    } else if (profile.voice.status === MediaStatus.Pending) {
      voiceProperty.approvedIds.push(profile.voice.id);
    }

    userToReview.voice = voiceProperty;
  }

  // bio
  if (profile.bioContent !== "") {
    const bioProperty: PropertyToReview = {
      approvedIds: [],
      rejectedIds: [],
    };

    if (pendingRejectedBioIds.includes(profile.uid)) {
      bioProperty.rejectedIds.push(profile.uid);
    } else if (profile.bioStatus === BioStatus.Pending) {
      bioProperty.approvedIds.push(profile.uid);
    }

    userToReview.bio = bioProperty;
  }

  // questions
  if (profile.questions.length > 0) {
    const questionProperty: PropertyToReview = {
      approvedIds: [],
      rejectedIds: [],
    };

    profile.questions.forEach((question) => {
      if (pendingRejectedQuestionIds.includes(question.id)) {
        questionProperty.rejectedIds.push(question.id);
      } else if (question.status === QuestionStatus.Pending) {
        questionProperty.approvedIds.push(question.id);
      }
    });

    userToReview.question = questionProperty;
  }

  // reports
  if (profile.reports) {
    const index = profile.reports.findIndex(
      (r) => r.status === ReportStatus.Pending
    );

    // 存在任意一条未check的report，则需将uid加入review内容中
    if (index > -1) {
      userToReview.report = [profile.uid];
    }
  }

  return userToReview;
}
