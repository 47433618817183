import { Center, Icon, Link, LinkProps, Text } from "@chakra-ui/react";
import { RiUser3Line } from "react-icons/ri";
import { Link as ReactRouterLink } from "react-router-dom";
import { RouterURI } from "router/RouterURI";

interface UserIdProps {
  uid?: number;
  uuid?: string;
}

export function UserIdWrapper(props: UserIdProps & LinkProps) {
  const { uid, uuid, children, ...restProps } = props;

  if (!uid && !uuid) {
    return <></>;
  }

  return (
    <Link
      as={ReactRouterLink}
      to={RouterURI.Users + "/" + (uid || uuid)}
      onClick={(e) => e.stopPropagation()}
      {...restProps}>
      {children}
    </Link>
  );
}

export function UserId(props: UserIdProps & LinkProps) {
  // 仅给Link传递LinkProps
  const { uid, ...restProps } = props;

  return (
    <UserIdWrapper
      uid={uid}
      {...restProps}>
      <Text fontSize={props.size}>:{uid}</Text>
    </UserIdWrapper>
  );
}

export function UserIdIcon(props: UserIdProps & LinkProps) {
  const { uid, ...restProps } = props;

  return (
    <UserIdWrapper
      uid={uid}
      {...restProps}
      _hover={{ color: "purple.400" }}>
      <Center>
        <Icon
          as={RiUser3Line}
          w="24px"
          h="24px"
        />
      </Center>
    </UserIdWrapper>
  );
}
