export enum MessageType {
  Text = 0, // 文本消息类型
  Photo = 1, // 图片类型消息
  Audio = 2, // 语音类型消息
  Video = 3, // 视频类型消息
  Location = 4, // 地理位置类型消息
  Notification = 5, // 群notification通知
  File = 6, // 文件消息
  Custom = 100, // 第三方自定义消息
}
