import {
  Box,
  Button,
  Menu,
  MenuButton,
  MenuDivider,
  MenuList,
} from "@chakra-ui/react";
import { BsChevronDown } from "react-icons/bs";
import { DeleteUserMenuItem } from "./DeleteUserMenuItem";
import { LocationEditor } from "./LocationEditor";

export function UserInfoMoreMenu() {
  return (
    // 用Box包裹起来，控制台就不报css警告了。
    <Box>
      <Menu>
        <MenuButton
          as={Button}
          rightIcon={<BsChevronDown />}
          variant="outline">
          More
        </MenuButton>
        <MenuList>
          <LocationEditor />

          <MenuDivider />
          <DeleteUserMenuItem />
        </MenuList>
      </Menu>
    </Box>
  );
}
