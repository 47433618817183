import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
  MenuItem,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { deleteUser } from "api";
import dayjs from "dayjs";
import { useRef, useState } from "react";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { requestIdState, userInfoQuery } from "state";

import { getErrorMessage } from "utils/functions";

export function DeleteUserMenuItem() {
  const toast = useToast();

  const userInfo = useRecoilValue(userInfoQuery);

  const { isOpen, onOpen, onClose } = useDisclosure();
  const cancelRef = useRef(null);

  const [isSubmitting, setIsSubmitting] = useState(false);
  const refreshRequestId = useSetRecoilState(requestIdState);

  if (!userInfo) return <></>;

  const handleClick = async () => {
    setIsSubmitting(true);

    try {
      await deleteUser(userInfo.uid);

      setIsSubmitting(false);

      onClose();
      refreshRequestId(dayjs().valueOf());
    } catch (error) {
      setIsSubmitting(false);

      toast({
        title: getErrorMessage(error),
        status: "error",
      });
    }
  };

  return (
    <>
      <MenuItem
        onClick={onOpen}
        color="red">
        Delete User
      </MenuItem>
      <AlertDialog
        motionPreset="slideInBottom"
        leastDestructiveRef={cancelRef}
        onClose={onClose}
        isOpen={isOpen}
        isCentered>
        <AlertDialogOverlay />

        <AlertDialogContent>
          <AlertDialogHeader>Delete User?</AlertDialogHeader>

          <AlertDialogBody>
            Are you sure? You can't undo this action afterwards.
          </AlertDialogBody>
          <AlertDialogFooter>
            <Button
              ref={cancelRef}
              onClick={onClose}
              variant="ghost">
              Cancel
            </Button>
            <Button
              colorScheme="red"
              ml={3}
              onClick={handleClick}
              isLoading={isSubmitting}>
              Delete
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </>
  );
}
