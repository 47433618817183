import { axiosInstance } from "api/common/axios";
import { ApiURI } from "api/common/url";
import { CIDRActiveStatus } from "constant";
import {
  CIDRToBlock,
  ParamBlockedCIDRs,
  RespBlockedCIDR,
  RespBlockedCIDRs,
  RespCIDRDetectResults,
} from "dto";

export const fetchBlockedCIDRs = (params: ParamBlockedCIDRs) => {
  return axiosInstance.post<RespBlockedCIDRs>(ApiURI.BlockedIPsSearch, params);
};

export const blockCIDR = (cidr: string, status?: CIDRActiveStatus) => {
  const array = cidr.split("/");

  let cidrToBlock: CIDRToBlock = {
    ip: array[0],
    mask: array[1] ? parseInt(array[1]) : 0, // 默认mask为0
    status: status ?? CIDRActiveStatus.Enabled, // 默认启用
  };

  return axiosInstance.post(ApiURI.BlockedIPsCreate, cidrToBlock);
};

export const fetchBlockedCIDR = (cidrId: number) => {
  return axiosInstance.get<RespBlockedCIDR>(
    ApiURI.BlockedIPsDetails + "/" + cidrId
  );
};

export const editBlockedCIDR = (params: CIDRToBlock) => {
  return axiosInstance.post(ApiURI.BlockedIPsEdit, params);
};

export const detectIPs = (ips: string[]) => {
  if (ips.length === 0) return;

  return axiosInstance.post<RespCIDRDetectResults>(ApiURI.BlockedIPsDetect, {
    ips,
  });
};
