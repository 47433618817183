import {
  Button,
  FormControl,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import dayjs from "dayjs";
import { useRef, useState } from "react";
import { useSetRecoilState } from "recoil";
import { getErrorMessage } from "utils/functions";
import { blockIsp } from "./isp.api";
import { ispsRequestIdAtom } from "./isp.state";

export function AddISPToBlacklistButton() {
  const toast = useToast();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const initialRef = useRef(null);

  const [isLoading, toggleLoading] = useState(false);
  const [isp, setIsp] = useState("");

  const setRequestId = useSetRecoilState(ispsRequestIdAtom);

  const handleBlock = async () => {
    toggleLoading(true);

    try {
      await blockIsp(isp);
      toggleLoading(false);
      setRequestId(dayjs().valueOf());
      setIsp("");
      onClose();
    } catch (error) {
      // handle error
      toggleLoading(false);
      toast({
        title: getErrorMessage(error),
        status: "error",
      });
    }
  };
  return (
    <>
      <Button onClick={onOpen}>Add ISP</Button>

      <Modal
        isOpen={isOpen}
        onClose={onClose}
        initialFocusRef={initialRef}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Block ISP</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <FormControl>
              <FormLabel>ISP</FormLabel>
              <Input
                placeholder="ISP"
                value={isp}
                onChange={(e) => setIsp(e.target.value)}
                ref={initialRef}
              />
            </FormControl>
          </ModalBody>

          <ModalFooter>
            <Button
              mr={3}
              variant="ghost"
              onClick={onClose}>
              Cancel
            </Button>
            <Button
              isLoading={isLoading}
              onClick={handleBlock}>
              Block
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}
