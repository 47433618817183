import {
  HStack,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  Spacer,
  Stack,
  Text,
} from "@chakra-ui/react";
import { ImageView } from "components";
import { BlockImageButtonWrapper } from "features";
import { useRecoilState } from "recoil";
import { modalImageViewState } from "state";

export function ModalImageView() {
  const [image, setImage] = useRecoilState(modalImageViewState);

  if (!image) return <></>;

  // 检测是否已在黑名单
  // 添加金黑名单
  // 从黑名单解禁

  return (
    <Modal
      isCentered
      isOpen={image ? true : false}
      onClose={() => {
        setImage(null);
      }}>
      <ModalOverlay />
      <ModalContent>
        <ModalBody p={0}>
          <Stack spacing={0}>
            <ImageView src={image?.url} />
            <HStack p={2}>
              <Text>#{image?.id}</Text>
              <Spacer />
              <BlockImageButtonWrapper image={image} />
            </HStack>
          </Stack>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}
