import { cidrStatusQuery } from "components";
import { CIDRDetectStatus, IPVersion } from "constant";
import dayjs from "dayjs";
import { BlockedCIDR, CIDRDetectResults, ParamBlockedCIDRs } from "dto";
import { atom, selector, selectorFamily } from "recoil";
import { pageSizeAtom } from "state/common";
import { pageAtom } from "state/search";
import { fetchBlockedCIDRs } from "./ip.api";

export const cidrsRequestIdAtom = atom<number>({
  key: "cidr/requestId",
  default: dayjs().valueOf(),
});

export const cidrsRespQuery = selector({
  key: "cidrs/resp",
  get: async ({ get }) => {
    get(cidrsRequestIdAtom);

    const page = get(pageAtom);
    const pageSize = get(pageSizeAtom);
    const status = get(cidrStatusQuery);

    const params: ParamBlockedCIDRs = {
      page,
      pageSize,
      status,
    };

    try {
      const resp = await fetchBlockedCIDRs(params);
      return resp.data;
    } catch (error) {
      // TODO: handle error
    }
    return;
  },
});

export const cidrsQuery = selector({
  key: "cidrs",
  get: ({ get }) => {
    const resp = get(cidrsRespQuery);

    if (resp?.error) {
      // handle error
    }

    return resp?.data ?? [];
  },
});

export const cidrsTotalQuery = selectorFamily({
  key: "cidrs/total",
  get:
    (version: IPVersion) =>
    ({ get }) => {
      const resp = get(cidrsRespQuery);

      return resp?.page?.total ?? 0;
    },
});

export const cidrQuery = selectorFamily({
  key: "cidrQuery",
  get:
    (id: number) =>
    async ({ get }) => {
      let cidr: BlockedCIDR | undefined;

      const cidrs = get(cidrsQuery);

      cidr = cidrs.find((item) => item.id === id);

      return cidr;
    },
});

export const detectCIDRResultsState = atom<CIDRDetectResults[]>({
  key: "detectCIDRResultsState",
  default: [],
});

export const numRejectedCIDRsState = selector({
  key: "numRejectedCIDRsState",
  get: ({ get }) => {
    const rejectedCIDRs = get(detectCIDRResultsState).filter((result) => {
      return result.checkStatus === CIDRDetectStatus.Rejected;
    });

    return rejectedCIDRs.length;
  },
});

export const numInvalidCIDRsState = selector({
  key: "numInvalidCIDRsState",
  get: ({ get }) => {
    const cidrs = get(detectCIDRResultsState).filter((result) => {
      return result.checkStatus === CIDRDetectStatus.Invalided;
    });

    return cidrs.length;
  },
});

export const numValidCIDRsState = selector({
  key: "numValidCIDRsState",
  get: ({ get }) => {
    const cidrs = get(detectCIDRResultsState).filter((result) => {
      return result.checkStatus === CIDRDetectStatus.Valid;
    });

    return cidrs.length;
  },
});
