import {
  Button,
  FormControl,
  FormHelperText,
  FormLabel,
  HStack,
  Input,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Select,
  Stack,
  useDisclosure,
} from "@chakra-ui/react";
import { editUser } from "api";
import { DayjsKey, DeviceStatus, RiskStatus } from "constant";
import dayjs from "dayjs";
import { UserInfo, UserToUpdate } from "dto";
import { updatedDevicesAtom } from "features/device";
import { useForm } from "react-hook-form";
import { useRecoilState, useRecoilValue } from "recoil";
import { setRecoil } from "recoil-nexus";
import { errorState, updatedProfilesAtom, userInfoQuery } from "state";
import { getErrorMessage } from "utils/functions";

export function BirthdayEditor() {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const user = useRecoilValue(userInfoQuery);

  if (!user) return <></>;

  return (
    <>
      <Button
        onClick={onOpen}
        size="xs"
        variant="outline"
        pointerEvents="auto">
        Change
      </Button>

      <Modal
        size="lg"
        isOpen={isOpen}
        onClose={onClose}>
        <ModalOverlay />
        <BirthdayEditorForm
          user={user}
          onCancel={onClose}
        />
      </Modal>
    </>
  );
}

type BirthdayEditorFormProps = {
  user: UserInfo;
  onCancel: () => void;
  // onSubmit: (userToUpdate: UserToUpdate) => void;
};

type BirthdayEditorFormScheme = {
  birthday: string;
  riskStatus: RiskStatus;
  deviceStatus: DeviceStatus;
};

function BirthdayEditorForm(props: BirthdayEditorFormProps) {
  const [updatedProfiles, setUpdatedProfiles] =
    useRecoilState(updatedProfilesAtom);
  const [updatedDevices, setUpdatedDevices] =
    useRecoilState(updatedDevicesAtom);

  const targetProfile =
    updatedProfiles.find((p) => p.uid === props.user.uid) ?? props.user;
  const targetDevice =
    updatedDevices.find((item) => item.id === props.user.device?.id) ??
    props.user.device;

  const {
    register,
    handleSubmit,
    formState: { isSubmitting },
  } = useForm<BirthdayEditorFormScheme>({
    defaultValues: {
      birthday: dayjs(targetProfile.birthday).format(
        DayjsKey.BirthdayValueFormat
      ),
      riskStatus: targetProfile.riskStatus,
      deviceStatus: targetDevice.status,
    },
  });

  const onSubmit = async (values: BirthdayEditorFormScheme) => {
    console.log(values);

    // 转换日期为时间戳
    // 转换risk status
    // 转换device status
    const userToUpdate: UserToUpdate = {
      birthday: dayjs(values.birthday, DayjsKey.BirthdayValueFormat).valueOf(),
      riskStatus: +values.riskStatus,
      deviceStatus: +values.deviceStatus,
    };

    try {
      await editUser(props.user.uid, userToUpdate);
      // 同步本地数据
      syncLocalProfile(userToUpdate);
      syncLocalDevice(userToUpdate);
    } catch (error) {
      setRecoil(errorState, getErrorMessage(error));
    } finally {
      props.onCancel();
    }
  };

  const syncLocalProfile = (userToUpdate: UserToUpdate) => {
    // setUpdatedProfiles
    setUpdatedProfiles((prev) => {
      const updatedProfilesMap = new Map(prev.map((item) => [item.uid, item]));

      updatedProfilesMap.set(targetProfile.uid, {
        ...targetProfile,
        birthday: userToUpdate.birthday ?? targetProfile.birthday,
        riskStatus: userToUpdate.riskStatus ?? targetProfile.riskStatus,
      });

      return Array.from(updatedProfilesMap.values());
    });
  };

  const syncLocalDevice = (userToUpdate: UserToUpdate) => {
    // setUpdatedDevices

    setUpdatedDevices((prev) => {
      const updatedDevicesMap = new Map(prev.map((item) => [item.id, item]));

      updatedDevicesMap.set(targetDevice.id, {
        ...targetDevice,
        status: userToUpdate.deviceStatus ?? targetDevice.status,
      });

      return Array.from(updatedDevicesMap.values());
    });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <ModalContent>
        <ModalHeader>Change Gender</ModalHeader>

        <ModalBody>
          <Stack spacing={4}>
            <FormControl>
              <Input
                placeholder="Birthday"
                {...register("birthday")}
              />
              <FormHelperText>
                Format: {DayjsKey.BirthdayValueFormat}
              </FormHelperText>
            </FormControl>

            <FormControl>
              <FormLabel>Risk Status</FormLabel>
              <Select {...register("riskStatus")}>
                <option value="2">Confirmed</option>
                <option value="4">Banned</option>
                <option value="5">Underage</option>
              </Select>
            </FormControl>

            <FormControl>
              <FormLabel>Device Status</FormLabel>
              <Select {...register("deviceStatus")}>
                <option value="0">Banned</option>
                <option value="1">Normal</option>
                <option value="2">Underage</option>
              </Select>
            </FormControl>
          </Stack>
        </ModalBody>

        <ModalFooter>
          <HStack>
            <Button
              onClick={props.onCancel}
              variant="ghost">
              Cancel
            </Button>

            <Button
              type="submit"
              isLoading={isSubmitting}>
              Save
            </Button>
          </HStack>
        </ModalFooter>
      </ModalContent>
    </form>
  );
}
