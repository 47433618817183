import {
  Heading,
  HStack,
  SimpleGrid,
  Spacer,
  Spinner,
  Stack,
} from "@chakra-ui/react";
import { HeaderStack, RiskStatusBadge, UserStatusTag } from "components";
import { SearchParam, UserStatus } from "constant";
import {
  BasicPanel,
  BioPanel,
  DevicePanel,
  ExtraPanel,
  MediaPanel,
  PurchasePanel,
  QuestionsPanel,
  RecommendedBadge,
  ReportsPanel,
  ReviewHistoryPanel,
  UserInfoMoreMenu,
  ViewChatsButton,
} from "features";
import {
  BanOrRestoreProfileButton,
  SubmitProfileButton,
} from "features/user/actions";
import { Suspense, useEffect } from "react";
import { useLocation, useParams } from "react-router-dom";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { currentUserIdAtom, userInfoQuery } from "state";
import { getTitleFromPathname } from "utils/functions";

export function UserPage() {
  const params = useParams();
  const uid = params[SearchParam.UID];

  const setCurrentUserId = useSetRecoilState(currentUserIdAtom);

  useEffect(() => {
    setCurrentUserId(uid ? +uid : 0);
  }, [setCurrentUserId, uid]);

  return (
    <Suspense fallback={<Spinner />}>
      <UserInfoView />
    </Suspense>
  );
}

function UserInfoView() {
  const location = useLocation();
  const userInfo = useRecoilValue(userInfoQuery);

  if (!userInfo) return <></>;

  return (
    <Stack>
      <HeaderStack>
        <Heading>{getTitleFromPathname(location.pathname)}</Heading>
        <HStack>
          <UserStatusTag status={userInfo.status} />
        </HStack>

        <RiskStatusBadge
          user={userInfo}
          suspicious={userInfo.suspicious}
        />

        <RecommendedBadge
          user={userInfo}
          key={userInfo.uid}
        />
        <Spacer />

        {userInfo.status !== UserStatus.Deleted && (
          <>
            <SubmitProfileButton />
            <BanOrRestoreProfileButton />
          </>
        )}

        <ViewChatsButton />

        {userInfo.status !== UserStatus.Deleted && <UserInfoMoreMenu />}
      </HeaderStack>

      <UserInfoPanel />
    </Stack>
  );
}

function UserInfoPanel() {
  return (
    <Stack spacing={8}>
      <MediaPanel />
      <BasicPanel />
      <SimpleGrid
        spacing={4}
        columns={4}>
        <BioPanel />
        <QuestionsPanel />
      </SimpleGrid>
      <ExtraPanel />
      <DevicePanel />
      <PurchasePanel />
      <ReviewHistoryPanel />
      <ReportsPanel />
    </Stack>
  );
}
