import { UserToReview } from "dto";
import { Suspense } from "react";
import { RecoilValueReadOnly, useRecoilValue } from "recoil";
import { ReviewButtonWrapper } from "./ReviewButtonWrapper";

function SubmitProfilesButtonWrapper({
  state,
}: {
  state: RecoilValueReadOnly<UserToReview>;
}) {
  const userToReview = useRecoilValue(state);

  return (
    <ReviewButtonWrapper
      userToReview={userToReview}
      colorScheme="green">
      Submit
    </ReviewButtonWrapper>
  );
}

export function SubmitProfilesButton({
  state,
}: {
  state: RecoilValueReadOnly<UserToReview>;
}) {
  return (
    <Suspense fallback={<></>}>
      <SubmitProfilesButtonWrapper state={state} />
    </Suspense>
  );
}
