import { Button } from "@chakra-ui/react";
import { reviewUser } from "api";
import { Suspense } from "react";
import { useRecoilValue } from "recoil";
import { RouterURI } from "router/RouterURI";
import { reportsQuery } from "state";
import { openInNewTab } from "utils/functions";

function OpenReportedUsersInNewTab() {
  const reports = useRecoilValue(reportsQuery);

  const openAllInNewTab = () => {
    if (!reports) {
      return;
    }

    reports.forEach((report) => {
      openInNewTab(RouterURI.Users + "/" + report.uid);
    });

    reviewUser({
      report: reports.map((r) => r.uid),
    });
  };

  return <Button onClick={openAllInNewTab}>Open In New Tab</Button>;
}

export function OpenAllReports() {
  return (
    <Suspense>
      <OpenReportedUsersInNewTab />
    </Suspense>
  );
}
