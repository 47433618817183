import { cloneDeep } from "lodash";

// 从指定数组中替换指定index的元素为指定的值
export function replaceItemAtIndex<T>(arr: T[], index: number, newValue: T) {
  return [...arr.slice(0, index), newValue, ...arr.slice(index + 1)];
}

export const toggleItemOfArr = (arr: number[], item: number) => {
  const index = arr.indexOf(item);

  const tmpArr = cloneDeep(arr);

  if (index === -1) {
    tmpArr.push(item);
  } else {
    tmpArr.splice(index, 1);
  }

  return tmpArr;
};
