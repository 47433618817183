import { axiosInstance } from "api/common/axios";
import {
  VersionWhitelistSetting,
  VersionWhitelistSettingResp,
} from "./version-whitelist.interface";

const SEARCH_VERSION_WHITELISTS = "/config/ignore/version";
const EDIT_VERSION_WHITELIST = "/config/edit/ignore/version";

export const fetchVersionWhitelists = () => {
  return axiosInstance.get<VersionWhitelistSettingResp>(
    SEARCH_VERSION_WHITELISTS
  );
};

export const editVersionWhitelist = (setting: VersionWhitelistSetting) => {
  return axiosInstance.post(EDIT_VERSION_WHITELIST, setting);
};
