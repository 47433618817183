export enum MemberStatus {
  Disabled = -1,
  Pending,
  Enabled,
  Deleted = 99,
}

export enum MemberRole {
  Unknown = 0,
  Viewer = 16,
  Editor = 32,
  Admin = 64,
  Owner = 128,
}
