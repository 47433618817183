import dayjs from "dayjs";
import { atom, selectorFamily } from "recoil";
import { pageAtom, pageSizeAtom } from "state";
import { searchIsp } from "./isp.api";
import { SearchIspParams } from "./isp.interface";

export const ispsRequestIdAtom = atom<number>({
  key: "isps/requestId",
  default: dayjs().valueOf(),
});

export const ispsRespQuery = selectorFamily({
  key: "isps/search",
  get:
    (isp: string) =>
    async ({ get }) => {
      get(ispsRequestIdAtom);
      const page = get(pageAtom);
      const pageSize = get(pageSizeAtom);

      const params: SearchIspParams = {
        page,
        pageSize,
      };

      if (isp !== "") {
        params.isp = isp;
      }

      try {
        const resp = await searchIsp(params);
        return resp.data;
      } catch (error) {
        // TODO: handle error
      }
    },
});

export const ispsQuery = selectorFamily({
  key: "isps",
  get:
    (isp: string) =>
    ({ get }) => {
      const resp = get(ispsRespQuery(isp));

      if (resp?.error) {
        // handle error
      }

      return resp?.data ?? [];
    },
});

export const ispsTotalQuery = selectorFamily({
  key: "isps/total",
  get:
    (isp: string) =>
    ({ get }) => {
      const resp = get(ispsRespQuery(isp));

      if (resp?.error) {
        // handle error
      }

      return resp?.page?.total ?? 0;
    },
});
