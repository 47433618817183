import { Divider, Heading, Stack } from "@chakra-ui/react";

export function Caption(props: CaptionProps) {
  return (
    <Stack>
      <Heading size="md">{props.title}</Heading>
      <Divider />
    </Stack>
  );
}

interface CaptionProps {
  title: string;
}