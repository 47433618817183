import { axiosInstance } from "api/common/axios";
import {
  ReviewHistoriesParams,
  ReviewHistoriesResp,
} from "./review-history.interface";

const SEARCH_REVIEW_HISTORIES = "/user/review/record";

export const searchReviewHistories = (params: ReviewHistoriesParams) => {
  return axiosInstance.post<ReviewHistoriesResp>(
    SEARCH_REVIEW_HISTORIES,
    params
  );
};
