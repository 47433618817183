import {
  AspectRatio,
  Box,
  HStack,
  Icon,
  Spacer,
  Stack,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import { MediaStatusTag, SelectedView, UserIdIcon } from "components";
import { MediaStatus } from "constant";
import { Voice } from "dto";
import { IoIosPause, IoIosPlay } from "react-icons/io";
import { useAudio } from "react-use";
import { useRecoilState } from "recoil";
import { voicesToRejectAtom } from "state";
import { toggleItemOfArr } from "utils/functions";

interface VoiceCardProps {
  voice: Voice;
}
export function VoiceCard(props: VoiceCardProps) {
  const { voice } = props;

  const borderColor = useColorModeValue("gray.100", "gray.700");
  const playerBgColor = useColorModeValue("purple.100", "purple.700");

  const [audio, state, controls] = useAudio({
    src: voice.url,
    // autoPlay: true,
  });

  const [voicesToReject, setVoicesToReject] =
    useRecoilState(voicesToRejectAtom);

  const isSelected = voicesToReject.includes(voice.id);

  const handleSelect = () => {
    controls.pause();
    console.log("selected voice id:", voice.id);

    if ([MediaStatus.Pending, MediaStatus.Approved].includes(voice.status)) {
      setVoicesToReject((curr) => toggleItemOfArr(curr, voice.id));
    }
  };

  const handlePlay = (e: React.MouseEvent<SVGElement, MouseEvent>) => {
    e.stopPropagation();
    controls.play();
  };

  const handlePause = (e: React.MouseEvent<SVGElement, MouseEvent>) => {
    e.stopPropagation();
    controls.pause();
  };

  return (
    <Stack
      w="188px"
      spacing={0}
      _hover={{ bg: borderColor }}
      cursor="pointer"
      border="1px"
      borderColor={borderColor}>
      <AspectRatio
        ratio={10 / 16}
        onClick={handleSelect}>
        <Stack bg={isSelected ? "red.100" : playerBgColor}>
          <Box
            w="100%"
            h="100%"
          />
          <HStack
            w="100%"
            h="100%">
            <Box
              w="70%"
              h="100%"
            />

            <AspectRatio
              ratio={1 / 1}
              w="100%">
              <Box>
                {audio}
                {isSelected ? (
                  <SelectedView />
                ) : (
                  <>
                    {state.playing ? (
                      <Icon
                        as={IoIosPause}
                        w="100%"
                        h="100%"
                        onClick={handlePause}
                      />
                    ) : (
                      <Icon
                        as={IoIosPlay}
                        w="100%"
                        h="100%"
                        onClick={handlePlay}
                      />
                    )}
                  </>
                )}
              </Box>
            </AspectRatio>

            <Box
              w="70%"
              h="100%"
            />
          </HStack>

          {!isSelected && (
            <Text
              fontSize="20"
              fontWeight="bold">
              {" "}
              {state.time.toFixed(1)} / {state.duration.toFixed(1)} (
              {voice.length}) s{" "}
            </Text>
          )}

          <Box
            w="100%"
            h="100%"
          />
        </Stack>
      </AspectRatio>

      <HStack p={2}>
        <Text>#{voice.id}</Text>
        <Spacer />
        <MediaStatusTag status={voice.status} />
        <UserIdIcon
          uid={voice.uid}
          isExternal
        />
      </HStack>
    </Stack>
  );
}
