import {
  Heading,
  HStack,
  Spacer,
  Stack,
  Switch,
  Text,
  useToast,
} from "@chakra-ui/react";
import { fetchAutoReviewStatus, toggleAutoReviewStatus } from "api";
import { HeaderStack, MainHeader } from "components";
import { VersionWhitelistSettingsWrapper } from "features";
import { useEffect, useState } from "react";
import { getErrorMessage } from "utils/functions";

export function SettingsPage() {
  const toast = useToast();
  const [autoReviewStatus, setAutoReviewStatus] = useState(false);

  useEffect(() => {
    // fetch auto review status
    (async () => {
      try {
        const { data } = await fetchAutoReviewStatus();
        setAutoReviewStatus(data.data!);
      } catch (error) {
        toast({
          title: "Fetch auto review status failed",
          description: getErrorMessage(error),
          status: "error",
        });
      }
    })();

    return () => {
      // cleanup
    };
  }, [toast, setAutoReviewStatus]);

  const handleToggleAutoReview = async () => {
    setAutoReviewStatus(!autoReviewStatus);
    const { data } = await toggleAutoReviewStatus();
    setAutoReviewStatus(data.data!);
  };

  return (
    <Stack spacing={8}>
      {/* Header */}
      <HeaderStack>
        <MainHeader />
        <Spacer />
      </HeaderStack>

      <HStack
        alignItems="flex-start"
        spacing={4}>
        <Stack spacing={0}>
          <Heading size="md">Auto Review Image</Heading>
          <Text opacity={0.6}>Approve Image with NSFW AI</Text>
        </Stack>
        <Switch
          id="auto-review"
          isChecked={autoReviewStatus}
          onChange={handleToggleAutoReview}
        />
      </HStack>

      <VersionWhitelistSettingsWrapper />
    </Stack>
  );
}
