import { Button } from "@chakra-ui/react";
import _ from "lodash";
import { useSetRecoilState } from "recoil";
import { pageAtom } from "state";





export function PaginationPrevButton() {
  const setPage = useSetRecoilState(pageAtom);

  const handleClickPre = () => {
    setPage(curr => {
      const _page = _.cloneDeep(curr);
      return Math.max(_page - 1, 1);
    });
  };

  return <Button onClick={handleClickPre}>Pre</Button>;
}
