import { Stack } from "@chakra-ui/react";
import { CustomTabProps, CustomTabs } from "components";
import { ProductRecordsTable } from "./components/ProductRecordsTable";

const tabs: CustomTabProps[] = [{ title: "Boosts" }, { title: "Super Flips" }];

export function ProductTransactions() {
  return (
    <Stack>
      <CustomTabs tabs={tabs} />
      <ProductRecordsTable />
    </Stack>
  );
}
