import { Spinner } from "@chakra-ui/react";
import { PhotosGrid } from "components";
import { Suspense } from "react";

export function PhotosPage() {
  return (
    <Suspense fallback={<Spinner />}>
      <PhotosGrid />
    </Suspense>
  );
}
