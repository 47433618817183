const ipv6Pattern = /^([0-9a-fA-F]{1,4}:){7}[0-9a-fA-F]{1,4}$/;

export function isIPv6(address: string) {
  return ipv6Pattern.test(address);
}

export function truncateIPv6(address: string, length: number) {
  // 如果长度小于等于 0 或大于等于 IPv6 地址长度，直接返回原始地址
  if (length <= 0 || length >= address.length) {
    return address;
  }

  // 切割地址的部分
  const parts = address.split(":");

  // 如果有双冒号 (::)
  if (parts.includes("")) {
    const emptyIndex = parts.indexOf("");
    const emptyCount = parts.filter((part) => part === "").length;
    const truncatedParts = parts
      .slice(0, emptyIndex)
      .concat(
        Array(length - emptyCount).fill(""),
        parts.slice(-length + emptyCount)
      );
    return truncatedParts.join(":");
  } else {
    // 没有双冒号，直接截取指定长度
    return parts.slice(-length).join(":");
  }
}
