import { number } from "@recoiljs/refine";
import { ReportStatus } from "constant";
import { atom, selector } from "recoil";
import { syncEffect } from "recoil-sync";
import { FilterSelector, FilterSelectorOption } from "./FilterSelector";

const statuses = [
  ReportStatus.Pending,
  ReportStatus.Checked,
]

const options = statuses.map((status, index) => {
  const option: FilterSelectorOption = {
    label: ReportStatus[status],
    value: index + 1
  }
  return option
})

const reportStatusAtom = atom<number>({
  key: "report_s",
  default: 1, // 从1开始
  effects: [syncEffect({ refine: number() })]
})

export const reportStatusQuery = selector<ReportStatus[] | undefined>({
  key: "reportStatus",
  get: ({ get }) => {
    const value = get(reportStatusAtom)
    return value ? [statuses[value - 1]] : undefined
  }
})

export function ReportStatusFilter() {
  return <FilterSelector
    placeholder="Select Status"
    state={reportStatusAtom}
    options={options}
  />
}