import { axiosInstance } from "api/common/axios";
import { ApiURI } from "api/common/url";
import {
  BioListParams,
  BioListResp,
  QuestionsParams,
  QuestionsResp,
  ReportsParams,
  ReportsResp,
  Resp,
  UserInfo,
  UsersParams,
  UsersResp,
  UserToReview,
  UserToUpdate,
} from "dto";

export function fetchUsers(params: UsersParams) {
  return axiosInstance.post<UsersResp>(ApiURI.UserSearch, params);
}

// 获取待审核的bio内容
export const fetchBio = (params: BioListParams) => {
  return axiosInstance.post<BioListResp>(ApiURI.BioSearch, params);
};

// 获取待审核的questions
export const fetchQuestions = (params: QuestionsParams) => {
  return axiosInstance.post<QuestionsResp>(ApiURI.QuestionSearch, params);
};

// 获取被举报的用户
export const fetchReports = (params: ReportsParams) => {
  return axiosInstance.post<ReportsResp>(ApiURI.ReportSearch, params);
};

// 根据id获取用户详情
export const fetchUser = (uid: number) => {
  return axiosInstance.get<Resp<UserInfo>>(ApiURI.UserDetails + "/" + uid);
};

// 审核用户
export const reviewUser = (userToReview: UserToReview) => {
  return axiosInstance.put(ApiURI.UserReview, userToReview);
};

// 编辑用户
export const editUser = (uid: number, data: UserToUpdate) => {
  return axiosInstance.put(ApiURI.UserEdit + "/" + uid, data);
};

export const deleteUser = (uid: number) => {
  return axiosInstance.delete(ApiURI.UserDelete + "/" + uid);
};
