import {
  Center,
  Spinner,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import {
  CustomHighlighter,
  CustomTr,
  QuestionStatusTag,
  SelectedView,
  UserId,
  ZStack,
} from "components";
import { QuestionStatus } from "constant";
import { Question } from "dto";
import { Suspense } from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import { questionsQuery, questionsToRejectAtom } from "state";
import { timestampMsToDateStr, toggleItemOfArr } from "utils/functions";

export function QuestionsPage() {
  return (
    <Suspense fallback={<Spinner />}>
      <QuestionsTable />
    </Suspense>
  );
}

function QuestionsTable() {
  const questions = useRecoilValue(questionsQuery);
  return (
    <Table variant="simple">
      <Thead>
        <Tr>
          <Th>#</Th>
          <Th>UID</Th>
          <Th>Answer</Th>
          <Th>Status</Th>
          <Th>Created At</Th>
          <Th>Updated At</Th>
        </Tr>
      </Thead>

      <Tbody>
        {questions.map((question, i) => (
          <QuestionsTableItem
            key={i}
            question={question}
          />
        ))}
      </Tbody>
    </Table>
  );
}

function QuestionsTableItem({ question }: { question: Question }) {
  const [questionsToReject, setQuestionsToReject] = useRecoilState(
    questionsToRejectAtom
  );

  const isSelected = questionsToReject.includes(question.id);

  const handleSelect = () => {
    console.log("selected question id: ", question.id);

    if (
      [QuestionStatus.Pending, QuestionStatus.Approved].includes(
        question.status
      )
    ) {
      setQuestionsToReject((curr) => toggleItemOfArr(curr, question.id));
    }
  };

  return (
    <CustomTr
      onClick={handleSelect}
      isSelected={isSelected}>
      <Td w={1}>{question.id}</Td>
      <Td w={1}>
        <UserId
          uid={question.uid}
          isExternal
        />
      </Td>

      <Td bg={isSelected ? "red.100" : undefined}>
        <ZStack>
          <CustomHighlighter content={question.answer} />
          {isSelected && (
            <Center
              w="100%"
              h="100%">
              <SelectedView />
            </Center>
          )}
        </ZStack>
      </Td>

      <Td w={1}>{<QuestionStatusTag status={question.status} />}</Td>
      <Td
        w={1}
        whiteSpace="nowrap">
        {timestampMsToDateStr(question.createdAt)}
      </Td>
      <Td
        w={1}
        whiteSpace="nowrap">
        {timestampMsToDateStr(question.updatedAt)}
      </Td>
    </CustomTr>
  );
}
