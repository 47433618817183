import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
  ButtonProps,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";

import { CIDRActiveStatus } from "constant";
import { blockCIDR } from "features/blacklist/ip/ip.api";

import { useRef, useState } from "react";
import { getErrorMessage } from "utils/functions";

export function BanIPButton(props: BanIPButtonProps & ButtonProps) {
  const { ip, ...restProps } = props;

  const { isOpen, onOpen, onClose } = useDisclosure();
  const cancelRef = useRef(null);
  const toast = useToast();

  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleSubmit = async () => {
    setIsSubmitting(true);

    try {
      await blockCIDR(props.ip, CIDRActiveStatus.Enabled);

      setIsSubmitting(false);
      onClose();
      toast({
        title: "Ban Ip success",
        status: "success",
      });
    } catch (error) {
      setIsSubmitting(false);

      toast({
        title: "Ban ip failed",
        description: getErrorMessage(error),
        status: "error",
      });
    }
  };

  return (
    <>
      <Button
        onClick={onOpen}
        {...restProps}>
        Ban IP
      </Button>

      <AlertDialog
        motionPreset="slideInBottom"
        leastDestructiveRef={cancelRef}
        onClose={onClose}
        isOpen={isOpen}
        isCentered>
        <AlertDialogOverlay />

        <AlertDialogContent>
          <AlertDialogHeader>Ban IP: {props.ip}?</AlertDialogHeader>

          <AlertDialogBody>
            You can undo this in "MANAGE / Blocked IPs".
          </AlertDialogBody>
          <AlertDialogFooter>
            <Button
              ref={cancelRef}
              onClick={onClose}>
              Cancel
            </Button>
            <Button
              colorScheme="red"
              ml={3}
              onClick={handleSubmit}
              isLoading={isSubmitting}>
              Ban
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </>
  );
}

interface BanIPButtonProps {
  ip: string;
}
