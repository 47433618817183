import {
  Box,
  Spinner,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import { CustomTr, UserId } from "components";
import { IAPBoost } from "dto";
import { Suspense } from "react";
import { useRecoilValue } from "recoil";
import { boostsQuery } from "state";
import { timestampMsToDateStr } from "utils/functions";

export function BoostsTable() {
  return (
    <Suspense fallback={<Spinner />}>
      <Box overflowX="scroll">
        <Table variant="simple">
          <BoostsTableHead />
          <BoostsTableBody />
        </Table>
      </Box>
    </Suspense>
  );
}

function BoostsTableHead() {
  return (
    <Thead>
      <Tr>
        <Th>#</Th>
        <Th>User</Th>
        <Th>Boosts</Th>
        <Th>Orders</Th>
        <Th>Created At</Th>
        <Th>Updated At</Th>
      </Tr>
    </Thead>
  );
}

function BoostsTableBody() {
  const boosts = useRecoilValue(boostsQuery);

  return (
    <Tbody>
      {boosts.map((boost, index) => (
        <BoostsTableCell
          key={index}
          boost={boost}
        />
      ))}
    </Tbody>
  );
}

interface BoostsTableCellProps {
  boost: IAPBoost;
}

function BoostsTableCell(props: BoostsTableCellProps) {
  const { boost } = props;
  return (
    <CustomTr>
      <Td>{boost.id}</Td>
      <Td>
        <UserId uid={boost.uid} />
      </Td>
      <Td>{boost.quantity}</Td>
      <Td>{boost.orderQuantity}</Td>
      <Td>{timestampMsToDateStr(boost.createdAt)}</Td>
      <Td>{timestampMsToDateStr(boost.updatedAt)}</Td>
    </CustomTr>
  );
}
