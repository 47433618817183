import { Center, Icon, IconProps } from "@chakra-ui/react";
import { RiCloseLine } from "react-icons/ri";

export function SelectedView(props: IconProps) {
  return (
    <Center
      w="100%"
      h="100%">
      <Icon
        as={RiCloseLine}
        color="red"
        border="1px"
        borderColor="red.200"
        borderWidth="2px"
        {...props}
      />
    </Center>
  );
}

export function SelectedCardView(props: IconProps) {
  return (
    <Icon
      as={RiCloseLine}
      w="100%"
      h="100%"
      color="red"
      border="1px"
      borderColor="red.200"
      borderWidth="2px"
      {...props}
    />
  );
}
