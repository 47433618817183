export enum ApiURI {
  /* REVIEW */
  MediaSearch = "/media/search",
  BioSearch = "/bio/search",
  QuestionSearch = "/question/search",
  ReportSearch = "/report/search",

  UserSearch = "/user/search",
  UserReview = "/user/review",

  UserLocationSearch = "/position/info/list",

  UserDetails = "/user/details",
  UserEdit = "/user/edit/partial",
  UserDelete = "/user/del",

  /* DEVICE */
  DevicesSearch = "/devices/search",
  DeviceEdit = "/devices/edit",

  /* Purchase */
  IAPSubscriptionsSearch = "/user/subscription/search",
  IAPSuperFlipsSearch = "/super/flip/search",
  IAPBoostsSearch = "/boost/search",

  IAPTransactionsSearch = "/iap/order/search",
  IAPProductRecordsSearch = "/user/flow/search",
  IAPTransferRecordsSearch = "/user/subscription/transfer/record/search",
  IAPNotificationsSearch = "/user/subscription/callback/record/search",
  IAPNotificationsDetails = "/user/subscription/callback/record/details/",

  /* USER */
  Login = "/login",

  /* ADMIN USER */
  AdminUsersSearch = "/admin/users/search",
  AdminUserCreate = "/admin/users/create",
  AdminUserDetails = "/admin/users/details",
  AdminUserDelete = "/admin/users/del",
  AdminUserDisable = "/admin/users/disable",
  AdminUserEdit = "/admin/users",

  /* blocked IPs */
  BlockedIPsSearch = "/ip/block/search",
  BlockedIPsDetails = "/ip/block/details",
  BlockedIPsEdit = "/ip/block/edit",
  BlockedIPsCreate = "/ip/block/add",
  BlockedIPsDetect = "/ip/block/check",

  // settings
  FetchAutoReviewStatus = "/config/auto/audit/img",
  ToggleAutoReviewStatus = "/config/edit/auto/audit/img",

  // im
  FetchSessions = "/im/session/search",
  FetchMessages = "/im/session/msg/search",
}
