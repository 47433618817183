import { Center, Spinner } from "@chakra-ui/react";
import { LocationState } from "dto";
import { Navigate, useLocation } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { RouterURI } from "router/RouterURI";
import { signedInUserAtom } from "state";
import { IS_DEBUG } from "utils/functions";

export const RequireAuth = ({ children }: { children: React.ReactElement }) => {
  const location = useLocation();
  const signedInUser = useRecoilValue(signedInUserAtom);

  // 未完成firebase auth初始化
  if (signedInUser === undefined) {
    return (
      <Center h="100vh">
        <Spinner />
      </Center>
    );
  }

  // 用户未登录
  if (signedInUser === null) {
    // DEBUG 模式，跳过auth要求
    if (IS_DEBUG) return children;

    // 重定向至Login Page, 设置from state
    const state: LocationState = { from: location };
    return (
      <Navigate
        to={RouterURI.Login}
        state={{ from: state }}
      />
    );
  }

  return children;
};
