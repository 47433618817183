import {
  Link,
  SimpleGrid,
  Spinner,
  Stack,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import {
  CustomStat,
  CustomStatLabel,
  CustomStatValue,
  CustomTr,
  HeaderStack,
  MainHeader,
  Pagination,
} from "components";
import { RiskStatus, SearchParam, UserStatus } from "constant";
import { Suspense } from "react";
import { Link as ReactRouterLink, useParams } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { RouterURI } from "router/RouterURI";
import { timestampMsToDateStr } from "utils/functions";
import { Caption } from "../components";
import {
  reviewHistoriesQuery,
  reviewHistoriesTotalQuery,
} from "./review-history.state";

export function ReviewHistoryPanel() {
  const params = useParams();
  const uid = params[SearchParam.UID];

  if (!uid) return <></>;

  return (
    <Suspense fallback={<></>}>
      <ReviewHistoryView uid={+uid} />
    </Suspense>
  );
}

export function ReviewHistoryView({ uid }: { uid: number }) {
  const reviewHistories = useRecoilValue(reviewHistoriesQuery(uid));
  const reviewHistoriesTotal = useRecoilValue(reviewHistoriesTotalQuery(uid));

  const lastReviewer = reviewHistories ? reviewHistories[0] : undefined;

  return (
    <Stack>
      <Caption title="Review History" />
      <SimpleGrid
        spacing={4}
        columns={4}>
        <CustomStat>
          <CustomStatLabel>Review Histories</CustomStatLabel>
          <CustomStatValue>
            <Link
              as={ReactRouterLink}
              to={RouterURI.Users + "/" + uid + "/review_histories"}>
              {reviewHistoriesTotal}
            </Link>
          </CustomStatValue>
        </CustomStat>

        <CustomStat>
          <CustomStatLabel>Last Reviewer</CustomStatLabel>
          <CustomStatValue>
            {lastReviewer?.reviewerName ?? "--"}
          </CustomStatValue>
        </CustomStat>

        <CustomStat>
          <CustomStatLabel>Reviewed At</CustomStatLabel>
          <CustomStatValue>
            {timestampMsToDateStr(lastReviewer?.createdAt)}
          </CustomStatValue>
        </CustomStat>
      </SimpleGrid>
    </Stack>
  );
}

export function ReviewHistoryTable({ uid }: { uid: number }) {
  return (
    <Stack>
      <HeaderStack>
        <MainHeader totalState={reviewHistoriesTotalQuery(uid)} />
      </HeaderStack>
      <Suspense fallback={<Spinner />}>
        <ReviewHistoryTableContent uid={uid} />
      </Suspense>

      <Pagination state={reviewHistoriesTotalQuery(+uid)} />
    </Stack>
  );
}

function ReviewHistoryTableContent({ uid }: { uid: number }) {
  const reviewHistories = useRecoilValue(reviewHistoriesQuery(uid));

  return (
    <Table variant="simple">
      <Thead>
        <Tr>
          <Th>#</Th>
          <Th>Reviewer</Th>
          <Th>User Status</Th>
          <Th>Verification Status</Th>
          <Th>Reviewed At</Th>
        </Tr>
      </Thead>

      <Tbody>
        {reviewHistories &&
          reviewHistories.map((history, index) => (
            <CustomTr key={index}>
              <Td>{history.id}</Td>
              <Td>{history.reviewerName}</Td>
              <Td>{UserStatus[history.userStatus]}</Td>
              <Td>{RiskStatus[history.userRiskStatus]}</Td>
              <Td>{timestampMsToDateStr(history.createdAt)}</Td>
            </CustomTr>
          ))}
      </Tbody>
    </Table>
  );
}
