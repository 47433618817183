import {
  Box,
  Spinner,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import { CustomTr, UserId } from "components";
import { IAPSuperFlip } from "dto";
import { Suspense } from "react";
import { useRecoilValue } from "recoil";
import { superFlipsQuery } from "state";
import { timestampMsToDateStr } from "utils/functions";

export function SuperFlipsTable() {
  return (
    <Suspense fallback={<Spinner />}>
      <Box overflowX="scroll">
        <Table variant="simple">
          <SuperFlipsTableHead />
          <SuperFlipsTableBody />
        </Table>
      </Box>
    </Suspense>
  );
}

function SuperFlipsTableHead() {
  return (
    <Thead>
      <Tr>
        <Th>#</Th>
        <Th>User</Th>
        <Th>Super Flips</Th>
        <Th>Orders</Th>
        <Th>Created At</Th>
        <Th>Updated At</Th>
      </Tr>
    </Thead>
  );
}

function SuperFlipsTableBody() {
  const superFlips = useRecoilValue(superFlipsQuery);
  return (
    <Tbody>
      {superFlips.map((superFlip, index) => (
        <SuperFlipsTableCell
          key={index}
          superFlip={superFlip}
        />
      ))}
    </Tbody>
  );
}

interface SuperFlipsTableCellProps {
  superFlip: IAPSuperFlip;
}

function SuperFlipsTableCell(props: SuperFlipsTableCellProps) {
  const { superFlip } = props;
  return (
    <CustomTr>
      <Td>{superFlip.id}</Td>
      <Td>
        <UserId uid={superFlip.uid} />
      </Td>
      <Td>{superFlip.quantity}</Td>
      <Td>{superFlip.orderQuantity}</Td>
      <Td>{timestampMsToDateStr(superFlip.createdAt)}</Td>
      <Td>{timestampMsToDateStr(superFlip.updatedAt)}</Td>
    </CustomTr>
  );
}
