import { Badge, Box, HStack, Spinner, useToast } from "@chakra-ui/react";
import { editUser } from "api";
import { RecommendedStatus } from "constant";
import { User } from "dto";
import { useState } from "react";
import { useRecoilState } from "recoil";
import { updatedProfilesAtom } from "state";
import { getErrorMessage } from "utils/functions";

export type RecommendedBadgeProps = {
  user: User;
};

export function RecommendedBadge({ user }: RecommendedBadgeProps) {
  const toast = useToast();

  const [isSubmitting, setIsSubmitting] = useState(false);

  const [updatedProfiles, updateProfiles] = useRecoilState(updatedProfilesAtom);

  const updatedProfile = updatedProfiles.find((item) => item.uid === user.uid);
  const recommend = updatedProfile?.recommend ?? user.recommend;

  const isRecommended = recommend === RecommendedStatus.True;

  const recommended = isRecommended
    ? RecommendedStatus.False
    : RecommendedStatus.True;

  const handleSubmit = async () => {
    setIsSubmitting(true);

    // updateRecommendedStatus(recommended);

    updateProfiles((curr) => {
      const updatedProfiles = new Map(curr.map((item) => [item.uid, item]));

      const profile = updatedProfiles.get(user.uid) ?? user;

      updatedProfiles.set(user.uid, {
        ...profile,
        recommend: recommended,
      });

      return Array.from(updatedProfiles.values());
    });

    try {
      await editUser(user.uid, { markAsRecommend: recommended });

      setIsSubmitting(false);

      toast({
        title: `${isRecommended ? "Un-" : ""}Recommend user success`,
        status: "success",
      });
    } catch (error) {
      setIsSubmitting(false);

      toast({
        title: getErrorMessage(error),
        status: "error",
      });
    }
  };

  return (
    <Box>
      <HStack>
        <Badge
          pointerEvents="auto"
          colorScheme={recommend ? "purple" : "gray"}
          variant={recommend ? "subtle" : "outline"}
          onClick={handleSubmit}>
          {recommend ? "Recommended" : "Un-Recommended"}
        </Badge>
        {isSubmitting && <Spinner size={"sm"} />}
      </HStack>
    </Box>
  );
}
