import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  FormLabel,
  Heading,
  HStack,
  Input,
  Radio,
  RadioGroup,
  Stack,
  Text,
} from "@chakra-ui/react";
import { Platform } from "constant";
import { Suspense, useState } from "react";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { errorState } from "state";
import { getErrorMessage } from "utils/functions";
import { editVersionWhitelist } from "./version-whitelist.api";
import {
  ReleaseStatus,
  VersionWhitelistSetting,
} from "./version-whitelist.interface";
import { versionWhitelistSettingsQuery } from "./version-whitelist.state";

const releaseOptions: ReleaseStatus[] = [
  ReleaseStatus["In Review"],
  ReleaseStatus.Published,
];

export function VersionWhitelistSettingsWrapper() {
  return (
    <Suspense fallback={<></>}>
      <VersionWhitelistSettings />
    </Suspense>
  );
}

export function VersionWhitelistSettings() {
  const settings = useRecoilValue(versionWhitelistSettingsQuery);

  return (
    <Stack spacing={4}>
      <Box>
        <Heading size="md">Whitelist Version</Heading>
        <Text opacity={0.6}>
          Client above the version bellow will not been suspended during App
          Store review.
        </Text>
      </Box>

      <HStack spacing={4}>
        {settings.map((setting, index) => (
          <VersionWhiteListSettingOption
            key={index}
            setting={setting}
          />
        ))}
      </HStack>
    </Stack>
  );
}

function VersionWhiteListSettingOption({
  setting,
}: {
  setting: VersionWhitelistSetting;
}) {
  const [loading, setLoading] = useState(false);

  const [version, updateVersion] = useState(setting.version);
  const [isOpen, updateIsOpen] = useState(setting.isOpen);

  const setError = useSetRecoilState(errorState);

  const handleSave = async () => {
    setLoading(true);

    try {
      await editVersionWhitelist({ ...setting, version, isOpen });
      // setLoading(false);
    } catch (error) {
      // handle error
      setError(getErrorMessage(error));
    }

    setLoading(false);
  };

  return (
    <Stack
      p={4}
      border="1px"
      borderRadius={15}
      borderColor="gray.400">
      <Heading
        size="sm"
        pb={4}
        opacity="0.6">
        {Platform[setting.channel]}
      </Heading>

      <FormControl>
        <FormLabel>Version</FormLabel>
        <FormHelperText></FormHelperText>
        <Input
          maxW="156px"
          type="text"
          placeholder="Version"
          value={version}
          onChange={(e) => updateVersion(e.target.value)}
        />
      </FormControl>

      <FormControl>
        <FormLabel>Review Status</FormLabel>
        <RadioGroup
          onChange={(e) => updateIsOpen(parseInt(e))}
          value={isOpen.toString()}>
          <Stack direction="row">
            {releaseOptions.map((item, index) => (
              <Radio
                key={index}
                value={item.toString()}>
                {ReleaseStatus[item]}
              </Radio>
            ))}
          </Stack>
        </RadioGroup>
      </FormControl>
      <Button
        isLoading={loading}
        onClick={handleSave}>
        Save
      </Button>
    </Stack>
  );
}
