import {
  AspectRatio,
  Divider,
  HStack,
  Image,
  SimpleGrid,
  Spinner,
  Stack,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import { reviewUser } from "api";
import { ReportsList, UserId, UserIdWrapper } from "components";
import { Report } from "dto";
import { Suspense } from "react";
import { useRecoilValue } from "recoil";
import { reportsQuery } from "state";

export function ReportsPage() {
  return (
    <Suspense fallback={<Spinner />}>
      <ReportsGrid />
    </Suspense>
  );
}

function ReportsGrid() {
  const reports = useRecoilValue(reportsQuery);

  return (
    <SimpleGrid
      minChildWidth="600px"
      spacing={2}>
      {reports &&
        reports.map((report, index) => (
          <PendingReportCard
            key={index}
            report={report}
          />
        ))}
    </SimpleGrid>
  );
}

function PendingReportCard({ report }: { report: Report }) {
  const borderColor = useColorModeValue("gray.100", "gray.700");

  const handleClick = () => {
    reviewUser({
      report: [report.uid],
    });
  };

  return (
    <HStack
      alignItems="flex-start"
      border="1px"
      borderColor={borderColor}
      spacing={0}
      cursor="pointer">
      <Stack
        spacing={0}
        _hover={{ bg: borderColor }}>
        <UserIdWrapper
          uid={report.uid}
          isExternal
          onClick={handleClick}>
          <AspectRatio
            ratio={10 / 16}
            w="156px">
            <>
              {report.avatar ? (
                <Image
                  src={report.avatar.url}
                  alt={report.uid.toString()}
                />
              ) : (
                <Text>-_-!</Text>
              )}
            </>
          </AspectRatio>
        </UserIdWrapper>
        <UserId
          uid={report.uid}
          isExternal
          p={2}
          onClick={handleClick}
        />
      </Stack>

      <Divider
        orientation="vertical"
        color={borderColor}
      />

      <ReportsList reports={report.reports} />
    </HStack>
  );
}
