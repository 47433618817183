import { DeviceStatus, RiskStatus } from "constant";
import { PropertyToReview } from "dto";
import { Suspense } from "react";
import { useRecoilValue } from "recoil";
import {
  effectiveDeviceQuery,
  effectiveProfileQuery,
  userToReviewQuery,
} from "state";
import { ReviewButtonWrapper } from "./ReviewButtonWrapper";

function BanOrRestoreProfileButtonWrapper() {
  const effectiveProfile = useRecoilValue(effectiveProfileQuery);
  const effectiveDevice = useRecoilValue(effectiveDeviceQuery);

  const userToReview = useRecoilValue(userToReviewQuery);

  if (!effectiveProfile) return <></>;
  if (effectiveProfile.riskStatus === RiskStatus.Underage) return <></>;

  const isRestore = effectiveProfile.riskStatus === RiskStatus.Banned;

  const userProperty: PropertyToReview = {
    approvedIds: [],
    rejectedIds: [],
  };

  const deviceProperty: PropertyToReview = {
    approvedIds: [],
    rejectedIds: [],
  };

  if (isRestore) {
    userProperty.approvedIds.push(effectiveProfile.uid);

    // 用户设备状态已为underage时，不更改设备状态
    effectiveDevice?.status !== DeviceStatus.Underage &&
      deviceProperty.approvedIds.push(effectiveProfile.uid);
  } else {
    userProperty.rejectedIds.push(effectiveProfile.uid);

    // 用户设备状态已为underage时，不更改设备状态
    effectiveDevice?.status !== DeviceStatus.Underage &&
      deviceProperty.rejectedIds.push(effectiveProfile.uid);
  }

  return (
    <ReviewButtonWrapper
      userToReview={{
        ...userToReview,
        user: userProperty,
        device: deviceProperty,
      }}
      colorScheme="red">
      {isRestore ? "Restore" : "Ban"}
    </ReviewButtonWrapper>
  );
}

function SubmitProfileButtonWrapper() {
  const effectiveProfile = useRecoilValue(effectiveProfileQuery);
  const userToReview = useRecoilValue(userToReviewQuery);

  if (!effectiveProfile) return <></>;
  if (effectiveProfile.riskStatus === RiskStatus.Underage) return <></>;

  return (
    <ReviewButtonWrapper
      userToReview={userToReview}
      colorScheme="green">
      Submit
    </ReviewButtonWrapper>
  );
}

export function BanOrRestoreProfileButton() {
  return (
    <Suspense fallback={<></>}>
      <BanOrRestoreProfileButtonWrapper />
    </Suspense>
  );
}

export function SubmitProfileButton() {
  return (
    <Suspense fallback={<></>}>
      <SubmitProfileButtonWrapper />
    </Suspense>
  );
}
