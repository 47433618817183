import { number } from "@recoiljs/refine";
import { MediaStatus } from "constant";
import { atom, selector } from "recoil";
import { syncEffect } from "recoil-sync";
import { FilterSelector, FilterSelectorOption } from "./FilterSelector";

const statuses = [
  MediaStatus.Pending,
  MediaStatus.Approved,
  MediaStatus.Rejected,
  MediaStatus.Deleted,
]

const options = statuses.map((status, index) => {
  const option: FilterSelectorOption = {
    label: MediaStatus[status],
    value: index + 1
  }
  return option
})

const mediaStatusAtom = atom<number>({
  key: "media_s",
  default: 1, // 从1开始
  effects: [syncEffect({ refine: number() })]
})

export const mediaStatusQuery = selector<MediaStatus[] | undefined>({
  key: "mediaStatus",
  get: ({ get }) => {
    const value = get(mediaStatusAtom)
    return value ? [statuses[value - 1]] : undefined
  }
})

export function MediaStatusFilter() {
  return <FilterSelector
    placeholder="Select Status"
    state={mediaStatusAtom}
    options={options}
  />
}