import { SimpleGrid, Stack } from "@chakra-ui/react";
import { CustomStat, CustomStatLabel, CustomStatValue } from "components";
import { MemberLevel } from "constant";

import { useRecoilValue } from "recoil";
import { RouterURI } from "router/RouterURI";
import { userInfoPurchaseQuery } from "state";
import { timestampMsToDateStr } from "utils/functions";
import { Caption, UserPurchasesLink } from "./components";

export function PurchasePanel() {
  const purchase = useRecoilValue(userInfoPurchaseQuery);

  return (
    <>
      {purchase && (
        <Stack>
          <Caption title="Purchases" />
          <SimpleGrid
            spacing={4}
            columns={4}>
            {/* Super Flips */}
            <CustomStat>
              <CustomStatLabel>Reward Super Flips</CustomStatLabel>
              <CustomStatValue>
                <UserPurchasesLink
                  title={purchase?.superFlips?.remaining.rewarded + ""}
                  uid={purchase.uid}
                  routeURI={RouterURI.PurchaseOverview}
                  tab={2}
                />
              </CustomStatValue>
            </CustomStat>

            <CustomStat>
              <CustomStatLabel>Purchased Super Flips</CustomStatLabel>
              <CustomStatValue>
                <UserPurchasesLink
                  title={purchase?.superFlips?.remaining.purchased + ""}
                  uid={purchase.uid}
                  routeURI={RouterURI.PurchaseOverview}
                  tab={2}
                />
              </CustomStatValue>
            </CustomStat>

            <CustomStat>
              <CustomStatLabel>Super Flip Orders</CustomStatLabel>
              <CustomStatValue>
                <UserPurchasesLink
                  title={purchase?.superFlips?.orders + ""}
                  uid={purchase.uid}
                  routeURI={RouterURI.ReceiptTransactions}
                  tab={3}
                />
              </CustomStatValue>
            </CustomStat>

            <CustomStat>
              <CustomStatLabel>Super Flip Records</CustomStatLabel>
              <CustomStatValue>
                <UserPurchasesLink
                  title={purchase?.superFlips?.records + ""}
                  uid={purchase.uid}
                  routeURI={RouterURI.ProductTransactions}
                  tab={1}
                />
              </CustomStatValue>
            </CustomStat>

            {/* Boosts */}
            <CustomStat>
              <CustomStatLabel>Reward Boosts</CustomStatLabel>
              <CustomStatValue>
                <UserPurchasesLink
                  title={purchase?.boosts?.remaining.rewarded + ""}
                  uid={purchase.uid}
                  routeURI={RouterURI.PurchaseOverview}
                  tab={1}
                />
              </CustomStatValue>
            </CustomStat>

            <CustomStat>
              <CustomStatLabel>Purchased Boosts</CustomStatLabel>
              <CustomStatValue>
                <UserPurchasesLink
                  title={purchase?.boosts?.remaining.purchased + ""}
                  uid={purchase.uid}
                  routeURI={RouterURI.PurchaseOverview}
                  tab={1}
                />
              </CustomStatValue>
            </CustomStat>

            <CustomStat>
              <CustomStatLabel>Boost Orders</CustomStatLabel>
              <CustomStatValue>
                <UserPurchasesLink
                  title={purchase?.boosts?.orders + ""}
                  uid={purchase.uid}
                  routeURI={RouterURI.ReceiptTransactions}
                  tab={2}
                />
              </CustomStatValue>
            </CustomStat>
            <CustomStat>
              <CustomStatLabel>Boost Records</CustomStatLabel>
              <CustomStatValue>
                <UserPurchasesLink
                  title={purchase?.boosts?.records + ""}
                  uid={purchase.uid}
                  routeURI={RouterURI.ProductTransactions}
                  tab={0}
                />
              </CustomStatValue>
            </CustomStat>

            {/* Subscription */}
            {purchase.subscription && (
              <>
                <CustomStat>
                  <CustomStatLabel>Membership Type</CustomStatLabel>
                  <CustomStatValue>
                    <UserPurchasesLink
                      title={
                        MemberLevel[purchase.subscription.membershipType] + ""
                      }
                      uid={purchase.uid}
                      routeURI={RouterURI.PurchaseOverview}
                      tab={0}
                    />
                  </CustomStatValue>
                </CustomStat>

                <CustomStat>
                  <CustomStatLabel>Product ID</CustomStatLabel>
                  <CustomStatValue>
                    {purchase?.subscription?.productId || "--"}
                  </CustomStatValue>
                </CustomStat>

                <CustomStat>
                  <CustomStatLabel>Membership Purchase Date</CustomStatLabel>
                  <CustomStatValue>
                    {purchase?.subscription?.purchaseDate
                      ? timestampMsToDateStr(
                          purchase?.subscription?.purchaseDate
                        )
                      : "--"}
                  </CustomStatValue>
                </CustomStat>

                <CustomStat>
                  <CustomStatLabel>Membership Expires Dat</CustomStatLabel>
                  <CustomStatValue>
                    {purchase?.subscription?.expiresDate
                      ? timestampMsToDateStr(
                          purchase?.subscription?.expiresDate
                        )
                      : "--"}
                  </CustomStatValue>
                </CustomStat>
              </>
            )}
          </SimpleGrid>
        </Stack>
      )}
    </>
  );
}
