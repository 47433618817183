import { Button } from "@chakra-ui/react";
import _ from "lodash";
import { RecoilValueReadOnly, useRecoilValue, useSetRecoilState } from "recoil";
import { pageAtom, totalPagesQuery } from "state";







export function PaginationNextButton(props: PaginationNextButtonProps) {

  const setPage = useSetRecoilState(pageAtom);
  const totalPages = useRecoilValue(totalPagesQuery(props.state));

  const handleClickNext = () => {

    setPage(curr => {
      const _page = _.cloneDeep(curr);
      return Math.min(_page + 1, totalPages);
    });
  };

  return <Button onClick={handleClickNext}>Next</Button>;
}
interface PaginationNextButtonProps {
  state: RecoilValueReadOnly<number>;
}
