import {
  Box,
  Button,
  Center,
  Heading,
  Image,
  Spacer,
  Stack,
  useToast,
} from "@chakra-ui/react";
import { logo } from "assets";
import { LocationState } from "dto";
import { GoogleAuthProvider, signInWithPopup } from "firebase/auth";
import { useEffect, useState } from "react";
import { FcGoogle } from "react-icons/fc";
import { useLocation, useNavigate } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { RouterURI } from "router/RouterURI";
import { signedInUserAtom } from "state";
import { getErrorMessage } from "utils/functions";
import { auth } from "../firebase";

export function LoginPage() {
  const signedInUser = useRecoilValue(signedInUserAtom);

  const location = useLocation();
  const navigate = useNavigate();
  const toast = useToast();

  const [loading, setLoading] = useState(false);

  let from = RouterURI.Home.toString();

  if (location.state != null) {
    from = (location.state as LocationState).from.pathname;
  }

  useEffect(() => {
    // 已登陆，跳转from或首页
    if (signedInUser) {
      navigate(from, { replace: true });
    }
  });

  const handleSignInWithGoogle = async () => {
    setLoading(true);

    try {
      let provider = new GoogleAuthProvider();
      await signInWithPopup(auth, provider);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      toast({
        title: getErrorMessage(error),
        status: "error",
      });
    }
  };

  return (
    <Center h="80vh">
      <Stack alignItems="center">
        <Image
          w="128px"
          src={logo}
          borderRadius={25}
        />
        <Heading>KASUAL</Heading>
        <Spacer />
        <Box>
          <Button
            leftIcon={<FcGoogle />}
            onClick={handleSignInWithGoogle}
            isLoading={loading}>
            Continue with Google
          </Button>
        </Box>
      </Stack>
    </Center>
  );
}
