import dayjs from "dayjs";
import { Media } from "dto";
import { atom, RecoilState, RecoilValueReadOnly, selectorFamily } from "recoil";

export const requestIdState = atom<number>({
  key: "requestId",
  default: dayjs().valueOf(),
});

export const errorState = atom<string | undefined>({
  key: "error",
  default: undefined,
});

export const modalImageViewState = atom<Media | null>({
  key: "modalImageState",
  default: null,
});

export const totalAtom = atom({
  key: "total",
  default: -1, // -1: loading
});

export const totalStateAtom = atom<
  RecoilState<number> | RecoilValueReadOnly<number> | undefined
>({
  key: "totalState",
  default: undefined,
});

export const pageSizeAtom = atom<number>({
  key: "pageSize",
  default: parseInt(process.env.REACT_APP_PAGE_SIZE ?? "30"),
});

export const totalPagesQuery = selectorFamily({
  key: "totalPageState",
  get:
    (state: RecoilValueReadOnly<number>) =>
    ({ get }) => {
      return Math.ceil(get(state) / get(pageSizeAtom));
    },
});
