import dayjs from "dayjs";
import { atom, selectorFamily } from "recoil";
import { pageAtom, pageSizeAtom } from "state";
import { searchBlockedImages } from "./image.api";
import { SearchBlockedImageParams } from "./image.interface";

export const blockedImagesRequestIdAtom = atom<number>({
  key: "blockedImages/requestId",
  default: dayjs().valueOf(),
});

export const blockedImagesRespQuery = selectorFamily({
  key: "blockedImages/search",
  get:
    (mediaId: number) =>
    async ({ get }) => {
      get(blockedImagesRequestIdAtom);

      const page = get(pageAtom);
      const pageSize = get(pageSizeAtom);

      let params: SearchBlockedImageParams = { page, pageSize };

      if (mediaId !== -1) {
        params.mediaId = mediaId;
        params.page = 1;
      }

      try {
        const resp = await searchBlockedImages(params);
        return resp.data;
      } catch (error) {
        // TODO: handle error
      }
    },
});

export const blockedImagesQuery = selectorFamily({
  key: "blockedImages",
  get:
    (mediaId: number) =>
    ({ get }) => {
      const resp = get(blockedImagesRespQuery(mediaId));

      if (resp?.error) {
        // handle error
      }

      return resp?.data ?? [];
    },
});

export const blockedImagesTotalQuery = selectorFamily({
  key: "blockedImages/total",
  get:
    (mediaId: number) =>
    ({ get }) => {
      const resp = get(blockedImagesRespQuery(mediaId));

      if (resp?.error) {
        // handle error
      }

      return resp?.page?.total ?? 0;
    },
});
