import { Suspense } from "react"
import { RecoilValueReadOnly, useRecoilValue } from "recoil"

interface TotalViewProps {
  state?: RecoilValueReadOnly<number>
}

export function TotalView(props: TotalViewProps) {
  return (
    <Suspense fallback={<></>}>
      {props.state &&
        <TotalViewContent state={props.state} />
      }
    </Suspense>
  )
}

interface TotalViewContentProps {
  state: RecoilValueReadOnly<number>
}

function TotalViewContent(props: TotalViewContentProps) {
  const total = useRecoilValue(props.state)

  return (
    // example title (123)
    <> ({total >= 0 ? total : "..."})</>
  )
}