import {
  Spacer,
  Spinner,
  Stack,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import {
  CopyButton,
  CustomTr,
  DateFilter,
  DeviceStatusFilter,
  HeaderStack,
  IMEILink,
  MainHeader,
  Pagination,
} from "components";
import { DeviceIMIERouteURIType } from "constant";
import { Device } from "dto";
import {
  devicesQuery,
  DeviceStatusBadge,
  devicesTotalQuery,
} from "features/device";
import { Suspense } from "react";
import { useRecoilValue } from "recoil";
import { totalAtom } from "state";
import { timestampMsToDateStr } from "utils/functions";

export function DevicesPage() {
  return (
    <Stack>
      <HeaderStack>
        <MainHeader totalState={devicesTotalQuery} />

        <Spacer />
        <DeviceStatusFilter />
        <DateFilter />
      </HeaderStack>

      <Suspense fallback={<Spinner />}>
        <DevicesTable />
      </Suspense>
      <Pagination state={totalAtom} />
    </Stack>
  );
}

function DevicesTable() {
  const devices = useRecoilValue(devicesQuery);

  return (
    <Table variant="simple">
      <Thead>
        <Tr>
          <Th>#</Th>
          <Th>UUID / ASSID</Th>
          <Th>Hardware</Th>
          <Th>App</Th>
          <Th>FCM</Th>
          <Th>Status</Th>
          <Th>Created At</Th>
          <Th>Updated At</Th>
        </Tr>
      </Thead>

      <Tbody>
        {devices.map((item, index) => (
          <DevicesTableItem
            key={index}
            data={item}
          />
        ))}
      </Tbody>
    </Table>
  );
}

interface DevicesTableItemProps {
  data: Device;
}

function DevicesTableItem(props: DevicesTableItemProps) {
  return (
    <CustomTr>
      <Td>{props.data.id}</Td>
      <Td>
        <IMEILink
          imei={props.data.imei}
          action={DeviceIMIERouteURIType.Users}
        />
      </Td>
      <Td>
        <Stack>
          <Text>{props.data.hardwareModel || "--"}</Text>
          <Text>{props.data.osVersion}</Text>
        </Stack>
      </Td>

      <Td>
        <Stack>
          <Text>{props.data.bundleId || "--"}</Text>
          <Text>
            {props.data.appVersion} ({props.data.appBuild})
          </Text>
        </Stack>
      </Td>
      <Td>
        {props.data.fcmToken ? (
          <CopyButton value={props.data.fcmToken} />
        ) : (
          <Text>--</Text>
        )}
      </Td>
      <Td>
        <DeviceStatusBadge device={props.data} />
      </Td>
      <Td>{timestampMsToDateStr(props.data.createdAt)}</Td>
      <Td>{timestampMsToDateStr(props.data.updatedAt)}</Td>
    </CustomTr>
  );
}
