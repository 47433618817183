import {
  Button,
  HStack,
  ListItem,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  OrderedList,
  Spacer,
  Stack,
  Text,
  Textarea,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { yupResolver } from "@hookform/resolvers/yup";

import { CIDRDetectStatus } from "constant";
import { Controller, useForm } from "react-hook-form";
import { BsCheck, BsExclamationTriangle, BsX } from "react-icons/bs";
import { useRecoilState, useRecoilValue } from "recoil";

import { getErrorMessage } from "utils/functions";
import * as yup from "yup";
import { detectIPs } from "./ip.api";
import {
  detectCIDRResultsState,
  numInvalidCIDRsState,
  numRejectedCIDRsState,
  numValidCIDRsState,
} from "./ip.state";

const schema = yup
  .object({
    ips: yup.string().required(),
  })
  .required();

export function DetectCIDRRuleButton() {
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <>
      {/* Button */}
      <Button
        onClick={onOpen}
        colorScheme="red">
        Detect
      </Button>

      {/* Modal */}
      <Modal
        isCentered
        // initialFocusRef={initialRef}
        onClose={onClose}
        isOpen={isOpen}
        motionPreset="slideInBottom"
        scrollBehavior="inside">
        <ModalOverlay />
        <ModalContent>
          <DetectForm onClose={onClose} />
        </ModalContent>
      </Modal>
    </>
  );
}

interface StatusProps {
  status: CIDRDetectStatus;
}

function Status(props: StatusProps) {
  return (
    <>
      {props.status === CIDRDetectStatus.Invalided && (
        <BsExclamationTriangle color="yellow" />
      )}
      {props.status === CIDRDetectStatus.Valid && <BsCheck color="teal" />}
      {props.status === CIDRDetectStatus.Rejected && <BsX color="red" />}
    </>
  );
}

interface IPTextAreaForm {
  ips: string;
}

interface DetectFormProps {
  onClose: () => void;
}

function DetectForm(props: DetectFormProps) {
  const toast = useToast();

  const { control, formState, handleSubmit } = useForm<IPTextAreaForm>({
    resolver: yupResolver(schema),
  });
  const [detectCIDRResults, setDetectCIDRResults] = useRecoilState(
    detectCIDRResultsState
  );
  const numRejectedCIDRs = useRecoilValue(numRejectedCIDRsState);
  const numInvalidCIDRs = useRecoilValue(numInvalidCIDRsState);
  const numValidCIDRs = useRecoilValue(numValidCIDRsState);

  const handleDetect = handleSubmit(async (data) => {
    // 清空历史
    setDetectCIDRResults([]);

    // 切分输入的ips
    const array = data.ips.split("\n");

    const ipv4Array: string[] = [];

    array.forEach((ip) => {
      ipv4Array.push(ip);
    });

    // 接口请求
    try {
      const resp = await detectIPs(ipv4Array);

      const result = resp?.data.data;
      setDetectCIDRResults(result ?? []);
    } catch (error) {
      toast({
        title: "detect ip failed",
        description: getErrorMessage(error),
        status: "error",
      });
    }
  });

  return (
    <>
      <form onSubmit={handleDetect}>
        <ModalHeader>Detect CIDR Rule</ModalHeader>
        <ModalBody>
          <Stack>
            <Controller
              name="ips"
              control={control}
              render={({ field }) => (
                <Textarea
                  placeholder="Type ip to detect, 1 ip each line."
                  {...field}
                />
              )}
            />

            {detectCIDRResults.length > 0 && (
              <>
                <HStack>
                  <Text>Hits: {numRejectedCIDRs}, </Text>
                  <Text>Invalid: {numInvalidCIDRs}, </Text>
                  <Text>Valid: {numValidCIDRs}</Text>
                </HStack>
                <Stack>
                  <OrderedList>
                    {detectCIDRResults.map((item, index) => (
                      <ListItem key={index}>
                        <HStack>
                          <Status status={item.checkStatus} />
                          <Text>{item.originalIp}</Text>
                          <Spacer />
                          {item.checkStatus === CIDRDetectStatus.Rejected && (
                            <Text>#{item.id}</Text>
                          )}
                        </HStack>
                      </ListItem>
                    ))}
                  </OrderedList>
                </Stack>
              </>
            )}
          </Stack>
        </ModalBody>
        <ModalFooter>
          <HStack>
            <Spacer />
            <Button
              variant="ghost"
              onClick={props.onClose}>
              Cancel
            </Button>
            <Button
              isLoading={formState.isSubmitting}
              type="submit">
              Detect
            </Button>
          </HStack>
        </ModalFooter>
      </form>
    </>
  );
}
