import { ColorModeScript } from "@chakra-ui/react";
import React from "react";
import { createRoot } from 'react-dom/client';
import { RecoilRoot } from "recoil";
import RecoilNexus from "recoil-nexus";
import { RecoilURLSyncJSON } from "recoil-sync";
import { App } from "./App";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import * as serviceWorker from "./serviceWorker";

const root = createRoot(document.getElementById("root") as HTMLElement)

root.render(
  <React.StrictMode>
    <ColorModeScript />
    <RecoilRoot>
      <RecoilNexus />
      <RecoilURLSyncJSON location={{ part: "queryParams" }}>
        <App />
      </RecoilURLSyncJSON>
    </RecoilRoot>

  </React.StrictMode>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorker.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
