import { axiosInstance } from "api/common/axios";
import { IPScore, Resp } from "dto";
import { IPDetail } from "./types";

const API_URI_IP_SCORE_DETAILS = "/ip/score/details";
const API_URI_IP_DETAILS = "/ip/details";

type IPScoreResp = Resp<IPScore>;

// getIPScore 获取ip质量得分
export const getIPScore = (ip: string) => {
  return axiosInstance.post<IPScoreResp>(API_URI_IP_SCORE_DETAILS, {
    ip,
  });
};

type IPDetailResp = Resp<IPDetail>;

export const getIPDetails = (ip: string) => {
  return axiosInstance.post<IPDetailResp>(API_URI_IP_DETAILS, { ip });
};
