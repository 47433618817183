import {
  Heading,
  HStack,
  IconButton,
  Spinner,
  Stack,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import { axiosInstance } from "api/common/axios";
import { ApiURI } from "api/common/url";
import { CustomTr, Pagination } from "components";
import { SearchParam } from "constant";
import { PageParams, Resp } from "dto";
import { Suspense } from "react";
import { BsArrowLeft } from "react-icons/bs";
import { useNavigate, useParams } from "react-router-dom";
import { selectorFamily, useRecoilValue } from "recoil";
import { pageAtom, pageSizeAtom } from "state";

interface UserLocationsParams extends PageParams {
  uid: number;
}

interface UserLocation {
  id: number;
  lat: string;
  lng: string;
  city: string;
  address: string;
  country: string;
  countryCode: string;
  type: LocationType;
  selectStatus: boolean;
}

enum LocationType {
  Travel,
  Local,
}

type UserLocationResp = Resp<UserLocation[]>;

export function LocationsPage() {
  const navigation = useNavigate();
  const params = useParams();
  const uid = params[SearchParam.UID];

  if (!uid) return <></>;

  return (
    <Stack>
      <HStack spacing={4}>
        <IconButton
          aria-label="back"
          icon={<BsArrowLeft />}
          onClick={() => navigation(-1)}
        />
        <Heading>
          Locations{" "}
          <Suspense fallback={<></>}>
            <TotalView uid={+uid} />
          </Suspense>
        </Heading>
      </HStack>

      <Suspense fallback={<Spinner />}>
        <LocationsTable uid={+uid} />
      </Suspense>
      <Pagination state={locationsTotalQuery(+uid)} />
    </Stack>
  );
}

function TotalView({ uid }: { uid: number }) {
  const total = useRecoilValue(locationsTotalQuery(uid));
  return <>({total})</>;
}

function LocationsTable({ uid }: { uid: number }) {
  const locations = useRecoilValue(locationsQuery(uid));
  return (
    <Table variant="simple">
      <Thead>
        <Tr>
          <Th>#</Th>
          <Th>In Use</Th>
          <Th>Type</Th>
          <Th>Lat & Lng</Th>
          <Th>City</Th>
          <Th>Country / Country Code</Th>
          <Th>Address</Th>
        </Tr>
      </Thead>

      <Tbody>
        {locations.map((location, index) => (
          <CustomTr key={index}>
            <Td>{location.id}</Td>
            <Td>{location.selectStatus ? "Yes" : "No"}</Td>
            <Td>{LocationType[location.type]}</Td>
            <Td>{[location.lat || "--", location.lng || "--"].join(", ")}</Td>
            <Td>{location.city}</Td>
            <Td>
              {[location.country || "--", location.countryCode || "--"].join(
                " / "
              )}
            </Td>
            <Td>{location.address}</Td>
          </CustomTr>
        ))}
      </Tbody>
    </Table>
  );
}

export function fetchUsers(params: UserLocationsParams) {
  return axiosInstance.post<UserLocationResp>(
    ApiURI.UserLocationSearch,
    params
  );
}

export const locationsRespQuery = selectorFamily({
  key: "locations/resp",
  get:
    (uid: number) =>
    async ({ get }) => {
      const page = get(pageAtom);
      const pageSize = get(pageSizeAtom);

      try {
        const resp = await fetchUsers({
          uid,
          page,
          pageSize,
        });
        return resp.data;
      } catch (error) {
        // TODO: handle error
      }
    },
});

export const locationsQuery = selectorFamily({
  key: "locations",
  get:
    (uid: number) =>
    ({ get }) => {
      const resp = get(locationsRespQuery(uid));

      if (resp?.error) {
        // TODO: handle error
      }

      return resp?.data ?? [];
    },
});

export const locationsTotalQuery = selectorFamily({
  key: "locations/total",
  get:
    (uid: number) =>
    ({ get }) => {
      const resp = get(locationsRespQuery(uid));
      return resp?.page?.total ?? 0;
    },
});
