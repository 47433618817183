import { number } from "@recoiljs/refine";
import { UserStatus } from "constant";
import { atom, selector } from "recoil";
import { syncEffect } from "recoil-sync";
import { FilterSelector, FilterSelectorOption } from "./FilterSelector";

const statuses = [
  UserStatus.Pending,
  UserStatus.Approved,
  UserStatus.Rejected,
  UserStatus.Deleted,
];

const options = statuses.map((status, index) => {
  const option: FilterSelectorOption = {
    label: UserStatus[status],
    value: index + 1,
  };
  return option;
});

const userStatusAtom = atom<number>({
  key: "user_status",
  default: 0, // 从1开始
  effects: [syncEffect({ refine: number() })],
});

export const userStatusQuery = selector<UserStatus[] | undefined>({
  key: "userStatuses",
  get: ({ get }) => {
    const value = get(userStatusAtom);
    return value ? [statuses[value - 1]] : undefined;
  },
});

export function UserStatusFilter() {
  return (
    <FilterSelector
      placeholder="User Status"
      state={userStatusAtom}
      options={options}
    />
  );
}
