import { Text, useToast } from "@chakra-ui/react";
import { CIDRActiveStatus } from "constant";
import dayjs from "dayjs";
import { BlockedCIDR, CIDRToBlock } from "dto";
import { useSetRecoilState } from "recoil";
import { getErrorMessage } from "utils/functions";
import { editBlockedCIDR } from "./ip.api";
import { cidrsRequestIdAtom } from "./ip.state";

export function CIDRStatusView({ data }: { data: BlockedCIDR }) {
  const toast = useToast();

  let color = data.status === CIDRActiveStatus.Enabled ? "teal" : "pink";

  const setRequestId = useSetRecoilState(cidrsRequestIdAtom);

  const handleToggleStatus = async () => {
    const cidrToBlock: CIDRToBlock = {
      id: data.id,
      ip: data.ip,
      status:
        data.status === CIDRActiveStatus.Enabled
          ? CIDRActiveStatus.Disabled
          : CIDRActiveStatus.Enabled,
    };

    try {
      await editBlockedCIDR(cidrToBlock);
      setRequestId(dayjs().valueOf());
    } catch (error) {
      toast({
        title: getErrorMessage(error),
        status: "error",
      });
    }
  };

  return (
    <Text
      color={color}
      onClick={handleToggleStatus}>
      {CIDRActiveStatus[data.status]}
    </Text>
  );
}
