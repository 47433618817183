import { Stack } from "@chakra-ui/react";
import { ReportsList } from "components";
import { useRecoilValue } from "recoil";
import { userInfoReportsQuery } from "state";
import { Caption } from "./components";

export function ReportsPanel() {
  const reports = useRecoilValue(userInfoReportsQuery);

  return (
    <>
      {reports && reports.length > 0 && (
        <Stack>
          <Caption title="Reports" />
          <ReportsList reports={reports} />
        </Stack>
      )}
    </>
  );
}
