import { fetchBio, fetchQuestions, fetchUser, fetchUsers } from "api";
import { Gender, ReviewStatus, RiskStatus, UserStatus } from "constant";
import {
  Bio,
  BioListParams,
  BioListResp,
  GeoInfoToUpdate,
  Question,
  QuestionsParams,
  QuestionsResp,
  ReportItem,
  Resp,
  User,
  UserInfo,
  UserInfoBio,
  UserInfoExtra,
  UserInfoMedia,
  UserInfoProduct,
  UserInfoPurchase,
  UserInfoSubscription,
  UsersParams,
  UsersResp,
} from "dto";
import { atom, selector } from "recoil";
import { setRecoil } from "recoil-nexus";

import {
  bioStatusQuery,
  genderAtom,
  gendersAtom,
  questionStatusQuery,
  reviewStatusQuery,
  riskStatusQuery,
  timestampsQuery,
  userStatusQuery,
} from "components";
import { updatedDevicesAtom } from "features/device";
import { RouterURI } from "router/RouterURI";
import { getErrorMessage } from "utils/functions";
import { errorState, pageSizeAtom, requestIdState } from "./common";
import {
  imeiAtom,
  pageAtom,
  profileRouteURIState,
  shortUUIDAtom,
  uidAtom,
  uuidAtom,
} from "./search";

export const updatedProfilesAtom = atom<User[]>({
  key: "updatedProfiles",
  default: [],
});

const profilesParamsQuery = selector({
  key: "profiles/params",
  get: ({ get }) => {
    const routeURI = get(profileRouteURIState);

    let riskStatus = RiskStatus.Normal;
    let reviewStatuses = [ReviewStatus.InReview];

    if (routeURI === RouterURI.Normal) {
      riskStatus = RiskStatus.Normal;
      reviewStatuses = [ReviewStatus.InReview];
    } else if (routeURI === RouterURI.Confirmed) {
      riskStatus = RiskStatus.Confirmed;
      reviewStatuses = [ReviewStatus.InReview];
    } else if (routeURI === RouterURI.Suspended) {
      riskStatus = RiskStatus.Suspended;
      reviewStatuses = [ReviewStatus.InReview, ReviewStatus.Reviewed];
    } else {
      riskStatus = RiskStatus.Banned;
      reviewStatuses = [ReviewStatus.InReview];
    }

    const params: UsersParams = {
      page: get(pageAtom),
      reviewStatus: reviewStatuses,
      genders: [get(genderAtom)],
      pageSize: get(pageSizeAtom),
      status: [UserStatus.Pending, UserStatus.Approved, UserStatus.Rejected],
      riskStatus: [riskStatus],
    };

    return params;
  },
});

export const profilesRespQuery = selector({
  key: "profiles/resp",
  get: async ({ get }) => {
    get(requestIdState);

    try {
      const resp = await fetchUsers(get(profilesParamsQuery));
      return resp.data;
    } catch (error) {
      setRecoil(errorState, getErrorMessage(error));
    }
  },
});

export const profilesQuery = selector({
  key: "profiles",
  get: async ({ get }) => {
    const resp = get(profilesRespQuery);
    return resp?.data ?? [];
  },
});

export const profilesTotalQuery = selector({
  key: "profiles/total",
  get: ({ get }) => {
    return get(profilesRespQuery)?.page?.total ?? 0;
  },
});

export const questionsRespQuery = selector<QuestionsResp | undefined>({
  key: "questions/resp",
  get: async ({ get }) => {
    get(requestIdState);

    const statuses = get(questionStatusQuery);

    const params: QuestionsParams = {
      page: get(pageAtom),
      pageSize: get(pageSizeAtom),
      genders: [get(genderAtom)],
      status: statuses,
    };

    try {
      const resp = await fetchQuestions(params);
      return resp.data;
    } catch (error) {
      setRecoil(errorState, getErrorMessage(error));
    }
  },
});

export const questionsQuery = selector<Question[]>({
  key: "questions",
  get: ({ get }) => {
    return get(questionsRespQuery)?.data ?? [];
  },
});

export const questionsTotalQuery = selector<number>({
  key: "questions/total",
  get: ({ get }) => {
    return get(questionsRespQuery)?.page?.total ?? 0;
  },
});

export const bioListRespQuery = selector<BioListResp | undefined>({
  key: "bioList/resp",
  get: async ({ get }) => {
    get(requestIdState);

    const params: BioListParams = {
      page: get(pageAtom),
      pageSize: get(pageSizeAtom),
      genders: [get(genderAtom)],
      status: get(bioStatusQuery),
    };

    try {
      const resp = await fetchBio(params);
      return resp.data;
    } catch (error) {
      setRecoil(errorState, getErrorMessage(error));
    }
  },
});

export const bioListQuery = selector<Bio[]>({
  key: "bioList",
  get: ({ get }) => {
    return get(bioListRespQuery)?.data ?? [];
  },
});

export const bioListTotalQuery = selector<number>({
  key: "bioList/total",
  get: ({ get }) => {
    return get(bioListRespQuery)?.page?.total ?? 0;
  },
});

export const usersRespQuery = selector<UsersResp | undefined>({
  key: "users/resp",
  get: async ({ get }) => {
    get(requestIdState);

    const imei = get(imeiAtom);
    const uid = get(uidAtom);
    const uuid = get(uuidAtom);
    const shortUUID = get(shortUUIDAtom);

    const params: UsersParams = {
      page: get(pageAtom),
      pageSize: get(pageSizeAtom),
      genders: get(gendersAtom).map((g) => +g as Gender),
    };

    if (imei) {
      params.imei = imei;
    }

    if (uid) {
      params.uid = uid;
    }

    if (uuid) {
      params.uuid = uuid;
    }

    if (shortUUID) {
      params.shortUUID = shortUUID;
    }

    if (!imei && !uid && !uuid && !shortUUID) {
      const timestamps = get(timestampsQuery);

      params.createdStart = timestamps.createdStart;
      params.createdEnd = timestamps.createdEnd;
      params.reviewStatus = get(reviewStatusQuery);
      params.riskStatus = get(riskStatusQuery);
      params.status = get(userStatusQuery);
    }

    try {
      const resp = await fetchUsers(params);
      return resp.data;
    } catch (error) {
      setRecoil(errorState, getErrorMessage(error));
    }
  },
});

export const usersQuery = selector<User[]>({
  key: "users",
  get: async ({ get }) => {
    return get(usersRespQuery)?.data ?? [];
  },
});

export const usersTotalQuery = selector<number>({
  key: "users/total",
  get: ({ get }) => {
    return get(usersRespQuery)?.page?.total ?? 0;
  },
});

export const currentUserIdAtom = atom<number>({
  key: "currentUserId",
  default: 0,
});

export const userInfoRespQuery = selector<Resp<UserInfo> | undefined>({
  key: "userInfo/resp",
  get: async ({ get }) => {
    get(requestIdState);

    const uid = get(currentUserIdAtom);

    if (!uid) return;

    try {
      const resp = await fetchUser(uid);
      return resp.data;
    } catch (error) {
      setRecoil(errorState, getErrorMessage(error));
    }
  },
});

export const userInfoQuery = atom<UserInfo | undefined>({
  key: "userInfo",
  default: selector({
    key: "userInfo/default",
    get: async ({ get }) => {
      const userInfo = get(userInfoRespQuery);

      return userInfo?.data;
    },
  }),
});

const getUserInfoMediaFrom = (user: UserInfo): UserInfoMedia => {
  const userInfoMedia: UserInfoMedia = {
    uid: user.uid,
    verifyImage: user.verify,
    otherVerifyImages: user.oldVerifies,
    photos: user.photos,
    voice: user.voice,
  };

  return userInfoMedia;
};

export const userInfoMediaQuery = selector<UserInfoMedia | undefined>({
  key: "userInfoMedia",
  get: ({ get }) => {
    const userInfo = get(userInfoQuery);
    if (userInfo) {
      return getUserInfoMediaFrom(userInfo);
    }
  },
});

const getUserInfoBioFrom = (user: UserInfo): UserInfoBio => {
  return {
    uid: user.uid,
    bio: user.bioContent,
    observerBio: user.bioObserverContent,
    status: user.bioStatus,
  };
};

export const userInfoBioQuery = selector<UserInfoBio | undefined>({
  key: "userInfoBio",
  get: ({ get }) => {
    const userInfo = get(userInfoQuery);
    if (userInfo) {
      return getUserInfoBioFrom(userInfo);
    }
  },
});

export const userInfoQuestionsQuery = selector<Question[] | undefined>({
  key: "userInfoQuestions",
  get: ({ get }) => {
    const userInfo = get(userInfoQuery);
    if (userInfo) {
      return userInfo.questions;
    }
  },
});

const getUserInfoExtraFrom = (user: UserInfo): UserInfoExtra => {
  const userInfoExtra: UserInfoExtra = {
    height: user.userExpandInfo.height,
    bodyType: user.userExpandInfo.bodyType,
    drinking: user.userExpandInfo.drinking,
    drugs: user.userExpandInfo.drugs,
    ethnicity: user.userExpandInfo.ethnicity,
    relationshipStatus: user.userExpandInfo.relationshipStatus,
    religion: user.userExpandInfo.religion,
    smoking: user.userExpandInfo.smoking,
    stdCheck: user.userExpandInfo.stdCheck,
    stdStatus: user.userExpandInfo.stdStatus,
    education: user.userExpandInfo.education,
    jobTitle: user.userExpandInfo.jobTitle,
  };
  return userInfoExtra;
};

export const userInfoExtraQuery = selector<UserInfoExtra | undefined>({
  key: "userInfoExtra",
  get: ({ get }) => {
    const resp = get(userInfoRespQuery);

    if (resp?.data) {
      return getUserInfoExtraFrom(resp?.data);
    } else {
      return undefined;
    }
  },
});

const getUserInfoPurchaseFrom = (user: UserInfo): UserInfoPurchase => {
  const subscription: UserInfoSubscription = {
    subscriptionId: user.iapOrder.id,
    membershipType: user.level,
    productId: user.iapOrder.productId,
    purchaseDate: user.iapOrder.purchaseDate,
    expiresDate: user.iapOrder.expiresDate,
  };

  const superFlips: UserInfoProduct = {
    remaining: {
      purchased: user.iapOrder.buySuperFlips,
      rewarded: user.iapOrder.giveSuperFlips,
    },
    orders: user.iapOrder.superFlipsOrders,
    records: user.iapOrder.superFlipFlowCount,
  };

  const boosts: UserInfoProduct = {
    remaining: {
      purchased: user.iapOrder.buyBoost,
      rewarded: user.iapOrder.giveBoost,
    },
    orders: user.iapOrder.boostOrders,
    records: user.iapOrder.boostFlowCount,
  };

  return { uid: user.uid, subscription, superFlips, boosts };
};

export const userInfoPurchaseQuery = selector<UserInfoPurchase | undefined>({
  key: "userInfoPurchase",
  get: ({ get }) => {
    const resp = get(userInfoRespQuery);
    return resp?.data && getUserInfoPurchaseFrom(resp?.data);
  },
});

export const userInfoReportsQuery = selector<ReportItem[] | undefined>({
  key: "userInfoReports",
  get: ({ get }) => {
    const resp = get(userInfoRespQuery);
    return resp?.data?.reports;
  },
});

// edit location
export const userInfoGeoInfoAtom = atom<GeoInfoToUpdate | undefined>({
  key: "userInfoGeoInfo",
  default: selector({
    key: "userInfoGeoInfo/default",
    get: ({ get }) => {
      const userInfo = get(userInfoQuery);
      if (!userInfo) return;

      return {
        uid: userInfo.uid,
        geoInfo: userInfo.currGeoInfo,
      };
    },
  }),
});

export const effectiveProfileQuery = selector({
  key: "effectiveProfile",
  get: ({ get }) => {
    const userInfo = get(userInfoQuery);
    const localProfiles = get(updatedProfilesAtom);
    if (!userInfo) return;

    return localProfiles.find((p) => p.uid === userInfo.uid) ?? userInfo;
  },
});

export const effectiveDeviceQuery = selector({
  key: "effectiveDevice",
  get: ({ get }) => {
    const userInfo = get(userInfoQuery);
    const localDevices = get(updatedDevicesAtom);
    if (!userInfo) return;

    return (
      localDevices.find((d) => d.id === userInfo.device.id) ?? userInfo.device
    );
  },
});
