import { HStack, SimpleGrid, Stack, Text } from "@chakra-ui/react";
import {
  CustomStat,
  CustomStatLabel,
  CustomStatValue,
  IpView,
} from "components";
import { Gender } from "constant";
import { useRecoilValue } from "recoil";
import { updatedProfilesAtom, userInfoQuery } from "state";
import {
  formatBirthday,
  getSeekingIn,
  timestampMsToDateStr,
} from "utils/functions";
import { UnderageButton } from "./actions";
import { GenderEditor, LocationsView } from "./components";
import { Caption } from "./components/Caption";
import { BirthdayEditor } from "./menu/BirthdayEditor";

export function BasicPanel() {
  const userInfo = useRecoilValue(userInfoQuery);
  const updatedProfiles = useRecoilValue(updatedProfilesAtom);

  const updatedProfile = updatedProfiles.find(
    (item) => item.uid === userInfo?.uid
  );
  const gender = updatedProfile?.gender ?? userInfo?.gender;
  const likeGenders = updatedProfile?.likeGenders ?? userInfo?.likeGenders;
  const birthday = updatedProfile?.birthday ?? userInfo?.birthday;

  return (
    <>
      {userInfo && (
        <Stack>
          <Caption title="Basic" />
          <SimpleGrid
            spacing={4}
            columns={4}>
            <CustomStat>
              <CustomStatLabel>Login with</CustomStatLabel>
              <CustomStatValue>{userInfo?.loginMode}</CustomStatValue>
            </CustomStat>

            <CustomStat>
              <CustomStatLabel>Birthday</CustomStatLabel>
              <CustomStatValue>
                {birthday ? (
                  <HStack>
                    <Text>{formatBirthday(birthday)}</Text>
                    <BirthdayEditor />
                    <UnderageButton user={userInfo} />
                  </HStack>
                ) : (
                  "--"
                )}
              </CustomStatValue>
            </CustomStat>

            <CustomStat>
              <CustomStatLabel>Gender</CustomStatLabel>
              <CustomStatValue>
                {gender ? (
                  <HStack>
                    <Text>{Gender[gender]}</Text>
                    <GenderEditor user={userInfo} />
                  </HStack>
                ) : (
                  "--"
                )}
              </CustomStatValue>
            </CustomStat>
            <CustomStat>
              <CustomStatLabel>Seeking In</CustomStatLabel>
              <CustomStatValue>
                {likeGenders ? getSeekingIn(likeGenders) : "--"}
              </CustomStatValue>
            </CustomStat>

            <CustomStat>
              <CustomStatLabel>Created At</CustomStatLabel>
              <CustomStatValue>
                {userInfo?.updatedAt &&
                  timestampMsToDateStr(userInfo?.createdAt)}
              </CustomStatValue>
            </CustomStat>

            <CustomStat>
              <CustomStatLabel>Register Ip</CustomStatLabel>
              <CustomStatValue>
                <IpView ip={userInfo.regIp} />
              </CustomStatValue>
            </CustomStat>
            <CustomStat>
              <CustomStatLabel>Register Lat & Long</CustomStatLabel>
              <CustomStatValue>
                {[
                  userInfo?.regGeoInfo.lat || "--",
                  userInfo?.regGeoInfo.lng || "--",
                ].join(", ")}
              </CustomStatValue>
            </CustomStat>
            <CustomStat>
              <CustomStatLabel>Register City & Country</CustomStatLabel>
              <CustomStatValue>
                {[
                  userInfo?.regGeoInfo.city || "--",
                  userInfo?.regGeoInfo.countryCode || "--",
                ].join(", ")}
              </CustomStatValue>
            </CustomStat>

            <CustomStat>
              <CustomStatLabel>Updated At</CustomStatLabel>
              <CustomStatValue>
                {userInfo?.updatedAt &&
                  timestampMsToDateStr(userInfo?.updatedAt)}
              </CustomStatValue>
            </CustomStat>

            <CustomStat>
              <CustomStatLabel>Current Ip</CustomStatLabel>
              <CustomStatValue>
                <IpView ip={userInfo.currIp} />
              </CustomStatValue>
            </CustomStat>

            <CustomStat>
              <CustomStatLabel>Current Lat & Long</CustomStatLabel>
              <CustomStatValue>
                {[
                  userInfo?.currGeoInfo?.lat || "--",
                  userInfo?.currGeoInfo?.lng || "--",
                ].join(", ")}
              </CustomStatValue>
            </CustomStat>
            <CustomStat>
              <CustomStatLabel>Current City & Country</CustomStatLabel>
              <CustomStatValue>
                {[
                  userInfo?.currGeoInfo?.city || "--",
                  userInfo?.currGeoInfo?.countryCode || "--",
                ].join(", ")}
              </CustomStatValue>
            </CustomStat>

            <CustomStat>
              <CustomStatLabel>Last Seen</CustomStatLabel>
              <CustomStatValue>
                {userInfo?.lastLoginTime &&
                  timestampMsToDateStr(userInfo?.lastLoginTime)}
              </CustomStatValue>
            </CustomStat>
            <CustomStat>
              <CustomStatLabel>Boost Expiry</CustomStatLabel>
              <CustomStatValue>
                {userInfo?.boostEndTime &&
                  timestampMsToDateStr(userInfo?.boostEndTime)}
              </CustomStatValue>
            </CustomStat>

            <CustomStat>
              <CustomStatLabel>Locations</CustomStatLabel>
              <CustomStatValue>
                <LocationsView count={userInfo.geoInfoCount || 0} />
              </CustomStatValue>
            </CustomStat>
            <CustomStat>
              <CustomStatLabel>Language</CustomStatLabel>
              <CustomStatValue>{userInfo.language}</CustomStatValue>
            </CustomStat>
            <CustomStat>
              <CustomStatLabel>Next Super Flip</CustomStatLabel>
              <CustomStatValue>
                {userInfo?.nextSuperFlipTime
                  ? timestampMsToDateStr(userInfo?.nextSuperFlipTime)
                  : "--"}
              </CustomStatValue>
            </CustomStat>
          </SimpleGrid>
        </Stack>
      )}
    </>
  );
}
