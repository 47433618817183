import { SimpleGrid, Stack, Text } from "@chakra-ui/react";
import {
  CopyButton,
  CustomStat,
  CustomStatLabel,
  CustomStatValue,
  IMEILink,
  IpView,
} from "components";
import { DeviceIMIERouteURIType, JailbreakStatus } from "constant";
import { deviceQuery, DeviceStatusBadge } from "features/device";
import { useRecoilValue } from "recoil";
import { timestampMsToDateStr } from "utils/functions";
import { Caption } from "./components/Caption";

export function DevicePanel() {
  const device = useRecoilValue(deviceQuery);

  return (
    <>
      {device && (
        <Stack>
          <Caption title="Device" />
          <SimpleGrid
            spacing={4}
            columns={4}>
            <CustomStat>
              <CustomStatLabel>ID</CustomStatLabel>
              <CustomStatValue>{device.id}</CustomStatValue>
            </CustomStat>
            <CustomStat>
              <CustomStatLabel>Device id</CustomStatLabel>
              <CustomStatValue>
                <IMEILink
                  imei={device.imei}
                  action={DeviceIMIERouteURIType.Users}
                />
              </CustomStatValue>
            </CustomStat>
            <CustomStat>
              <CustomStatLabel>Hardware Model</CustomStatLabel>
              <CustomStatValue>{device.hardwareModel}</CustomStatValue>
            </CustomStat>
            <CustomStat>
              <CustomStatLabel>OS Version</CustomStatLabel>
              <CustomStatValue>
                {device.osVersion} / {JailbreakStatus[device.jailbreak]}
              </CustomStatValue>
            </CustomStat>
            <CustomStat>
              <CustomStatLabel>IP</CustomStatLabel>
              <CustomStatValue>
                <IpView ip={device.ip} />
              </CustomStatValue>
            </CustomStat>
            <CustomStat>
              <CustomStatLabel>Bundle ID / Package Name</CustomStatLabel>
              <CustomStatValue>{device.bundleId} </CustomStatValue>
            </CustomStat>
            <CustomStat>
              <CustomStatLabel>App ID</CustomStatLabel>
              <CustomStatValue>{device.appId} </CustomStatValue>
            </CustomStat>
            <CustomStat>
              <CustomStatLabel>App Version</CustomStatLabel>
              <CustomStatValue>
                {`${device.appVersion} (${device.appBuild})`}{" "}
              </CustomStatValue>
            </CustomStat>
            <CustomStat>
              <CustomStatLabel>FCM Token</CustomStatLabel>
              <CustomStatValue>
                {device.fcmToken ? (
                  <CopyButton value={device.fcmToken} />
                ) : (
                  <Text>--</Text>
                )}
              </CustomStatValue>
            </CustomStat>
            <CustomStat>
              <CustomStatLabel>Status</CustomStatLabel>
              <CustomStatValue>
                <DeviceStatusBadge device={device} />
              </CustomStatValue>
            </CustomStat>
            <CustomStat>
              <CustomStatLabel>Created At</CustomStatLabel>
              <CustomStatValue>
                {timestampMsToDateStr(device.createdAt)}{" "}
              </CustomStatValue>
            </CustomStat>
            <CustomStat>
              <CustomStatLabel>Updated At</CustomStatLabel>
              <CustomStatValue>
                {timestampMsToDateStr(device.updatedAt)}{" "}
              </CustomStatValue>
            </CustomStat>
          </SimpleGrid>
        </Stack>
      )}
    </>
  );
}
