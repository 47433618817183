import {
  ChakraProvider,
  extendTheme,
  withDefaultColorScheme,
} from "@chakra-ui/react";
import { ErrorToast, ModalImageView } from "components";
import { useTitle } from "react-use";
import { AppRouter } from "router/AppRouter";

const theme = extendTheme(
  {
    fonts: {
      body: "Gilroy, sans-serif",
      heading: "Gilroy, serif",
      mono: "Gilroy, monospace",
    },
  },
  withDefaultColorScheme({ colorScheme: "purple" })
);

export const App = () => {
  useTitle(process.env.REACT_APP_NAME ?? "App");

  return (
    <ChakraProvider theme={theme}>
      <AppRouter />

      <ModalImageView />
      <ErrorToast />
    </ChakraProvider>
  );
};
