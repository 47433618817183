import { number } from "@recoiljs/refine";
import { BioStatus } from "constant";
import { atom, selector } from "recoil";
import { syncEffect } from "recoil-sync";
import { FilterSelector, FilterSelectorOption } from "./FilterSelector";

const statuses = [
  BioStatus.Pending,
  BioStatus.Approved,
  BioStatus.Rejected,
  BioStatus.Deleted,
]

const options = statuses.map((status, index) => {
  const option: FilterSelectorOption = {
    label: BioStatus[status],
    value: index + 1
  }
  return option
})

const bioStatusAtom = atom<number>({
  key: "bio_s",
  default: 1, // 从1开始
  effects: [syncEffect({ refine: number() })]
})

export const bioStatusQuery = selector<BioStatus[] | undefined>({
  key: "bioStatus",
  get: ({ get }) => {
    const value = get(bioStatusAtom)
    return value ? [statuses[value - 1]] : undefined
  }
})

export function BioStatusFilter() {
  return <FilterSelector
    placeholder="Select Status"
    state={bioStatusAtom}
    options={options}
  />
}
