import { Spinner, Table, Tbody, Td, Th, Thead, Tr } from "@chakra-ui/react";
import { CustomTr } from "components";
import { BlockedCIDR } from "dto";
import { Suspense } from "react";
import { useRecoilValue } from "recoil";

import { timestampMsToDateStr } from "utils/functions";
import { EditCIDRButton } from "./ip.edit-button";
import { cidrsQuery } from "./ip.state";
import { CIDRStatusView } from "./ip.status";

export function CIDRsTabPanel() {
  return (
    <Suspense fallback={<Spinner />}>
      <CIDRsTable />
    </Suspense>
  );
}

function CIDRsTable() {
  const cidrs = useRecoilValue(cidrsQuery);

  return (
    <Table variant="simple">
      <Thead>
        <Tr>
          <Th>#</Th>
          <Th>CIDR</Th>
          <Th>Start / End</Th>
          <Th>Count</Th>
          <Th>Status</Th>
          <Th>Created At</Th>
          <Th>Updated At</Th>
          <Th></Th>
        </Tr>
      </Thead>

      <Tbody>
        {cidrs.map((item, index) => (
          <CIDRsTableItem
            key={index}
            data={item}
          />
        ))}
      </Tbody>
    </Table>
  );
}

function CIDRsTableItem({ data }: { data: BlockedCIDR }) {
  return (
    <CustomTr>
      <Td>{data.id}</Td>
      <Td>
        {data.ip}/{data.mask}
      </Td>
      <Td>
        {data.beginIp} - {data.endIp}
      </Td>
      <Td>{data.quantity}</Td>
      <Td>
        <CIDRStatusView data={data} />
      </Td>
      <Td>{timestampMsToDateStr(data.createdAt)}</Td>
      <Td>{timestampMsToDateStr(data.updatedAt)}</Td>
      <Td w={1}>
        <EditCIDRButton id={data.id} />
      </Td>
    </CustomTr>
  );
}
