import { axiosInstance } from "api/common/axios";
import { PageParams } from "dto";
import { WordBase, WordsResp } from "./word.interface";

const ADD_WORD = "/sensitive/word/add";
const DEL_WORD = "/sensitive/word/del";
const LIST_WORD = "/sensitive/word/list";
const SEARCH_WORD = "/sensitive/word/search";

export const addWord = (words: WordBase[]) => {
  return axiosInstance.post(ADD_WORD, words);
};

export const delWord = (id: number) => {
  return axiosInstance.post(DEL_WORD, { id });
};

export const searchWord = (params: PageParams) => {
  return axiosInstance.post<WordsResp>(SEARCH_WORD, params);
};

export const listWord = () => {
  return axiosInstance.post<WordsResp>(LIST_WORD);
};
