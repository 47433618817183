import {
  AutoStatus,
  Gender,
  MediaStatus,
  MediaType,
  RiskStatus,
  UserStatus,
} from "constant";
import { ListParams, Resp, TimestampsResp } from "./common";

// ========== Media ==========
export interface Media extends TimestampsResp {
  id: number;
  uid: number;
  type: MediaType;
  url: string;
  length: number; // voice length in seconds
  position: number; // gesture or order
  status: MediaStatus;
  riskScore: number;
  autoStatus?: AutoStatus;
  info?: MediaInfo;
}

export enum MediaHasFace {
  Unknown = -1,
  No,
  Yes,
}

export enum MediaGender {
  Unknown = -1,
  Male,
  Female,
}

export enum Vitality {
  OutRange = -2,
  Unknown,
  No,
  Yes,
}

interface MediaFaceInfo {
  age: number;
  faceId: number;
  gender: MediaGender;
  vitality: Vitality;
  irVitality: Vitality;
  maxSimilarScore: number;
  minSimilarScore: number;
}

interface MediaInfo {
  clientHasFace: MediaHasFace;
  serviceFaceCount: MediaHasFace;
  faceInfos: MediaFaceInfo[];
}

// ========== Media Interfaces ==========
export interface Photo extends Media {}
export interface VerifyImage extends Media {}
export interface Voice extends Media {}

// 媒体列表请求参数
export interface MediaListParams extends ListParams {
  types?: MediaType[];
  genders?: Gender[];
  status?: MediaStatus[];
  userStatus?: UserStatus[];
  userRiskStatus?: RiskStatus[];
}

export type MediaBaseListResp = Resp<Media[]>;
