import { Divider } from "@chakra-ui/react";
import {
  CustomHighlighter,
  CustomStat,
  CustomStatLabel,
  CustomStatValue,
  QuestionStatusTag,
} from "components";
import { Question } from "dto";
import { useRecoilState, useRecoilValue } from "recoil";
import { questionsToRejectAtom, userInfoQuestionsQuery } from "state";
import { toggleItemOfArr } from "utils/functions";
import { DescriptionStack } from "./components/DescriptionStack";

export function QuestionsPanel() {
  const questions = useRecoilValue(userInfoQuestionsQuery);

  return (
    <>
      {questions?.map((question, index) => (
        <QuestionView
          key={index}
          question={question}
        />
      ))}
    </>
  );
}

interface QuestionViewProps {
  question: Question;
}

function QuestionView(props: QuestionViewProps) {
  const { question } = props;
  const [questionsToReject, setQuestionsToReject] = useRecoilState(
    questionsToRejectAtom
  );

  const handleSelect = () => {
    setQuestionsToReject((curr) => {
      return toggleItemOfArr(curr, question.id);
    });
  };

  return (
    <DescriptionStack
      isSelected={questionsToReject.includes(question.id)}
      onClick={handleSelect}>
      <CustomStat>
        <CustomStatLabel>{question.question}</CustomStatLabel>
        <CustomStatValue>
          <CustomHighlighter content={question.answer} />
          <Divider />
          <CustomHighlighter content={question.observerAnswer} />
        </CustomStatValue>
      </CustomStat>

      <QuestionStatusTag status={question.status} />
    </DescriptionStack>
  );
}
