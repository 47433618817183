import {
  Button,
  FormControl,
  FormLabel,
  HStack,
  Input,
  MenuItem,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { editUser } from "api";
import { useState } from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import { userInfoGeoInfoAtom } from "state";
import { getErrorMessage } from "utils/functions";

export function LocationEditor() {
  const toast = useToast();

  // hooks
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const geoInfoToUpdate = useRecoilValue(userInfoGeoInfoAtom);

  const handleSubmit = async () => {
    if (!geoInfoToUpdate) {
      return;
    }

    setIsSubmitting(true);

    try {
      // api
      await editUser(geoInfoToUpdate.uid, {
        geoInfo: geoInfoToUpdate.geoInfo,
      });

      setIsSubmitting(false);

      // 弹窗提示
      toast({
        title: "Edit Location Success",
        status: "success",
      });

      // 关闭modal
      onClose();
    } catch (error) {
      setIsSubmitting(false);

      toast({
        title: getErrorMessage(error),
        status: "error",
      });
    }
  };

  return (
    <>
      <MenuItem onClick={onOpen}>Change Location</MenuItem>

      <Modal
        size="lg"
        isOpen={isOpen}
        onClose={onClose}>
        <ModalOverlay />

        <ModalContent>
          <ModalHeader>Change Location</ModalHeader>

          <ModalBody>
            <LocationForm />
          </ModalBody>

          <ModalFooter>
            <HStack>
              <Button onClick={onClose}>Cancel</Button>

              <Button
                onClick={handleSubmit}
                isLoading={isSubmitting}>
                Save
              </Button>
            </HStack>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}

function LocationForm() {
  const [defaultGeoInfo, setGeoInfo] = useRecoilState(userInfoGeoInfoAtom);

  const handleChangeLat = (e: React.ChangeEvent<HTMLInputElement>) => {
    setGeoInfo((curr) => {
      if (curr?.geoInfo) {
        const geoInfo = { ...curr.geoInfo, lat: parseFloat(e.target.value) };
        return { ...curr, geoInfo };
      }
    });
  };

  const handleChangeLng = (e: React.ChangeEvent<HTMLInputElement>) => {
    setGeoInfo((curr) => {
      if (curr?.geoInfo) {
        const geoInfo = { ...curr.geoInfo, lng: parseFloat(e.target.value) };
        return { ...curr, geoInfo };
      }
    });
  };

  const handleChangeCity = (e: React.ChangeEvent<HTMLInputElement>) => {
    setGeoInfo((curr) => {
      if (curr?.geoInfo) {
        const geoInfo = { ...curr.geoInfo, city: e.target.value };
        return { ...curr, geoInfo };
      }
    });
  };

  const handleChangeAddress = (e: React.ChangeEvent<HTMLInputElement>) => {
    setGeoInfo((curr) => {
      if (curr?.geoInfo) {
        const geoInfo = { ...curr.geoInfo, address: e.target.value };
        return { ...curr, geoInfo };
      }
    });
  };

  return (
    <Stack>
      <FormControl isRequired>
        <FormLabel>Latitude</FormLabel>
        <Input
          name="latitude"
          placeholder="Latitude"
          defaultValue={defaultGeoInfo?.geoInfo?.lat}
          onChange={handleChangeLat}
        />
      </FormControl>
      <FormControl isRequired>
        <FormLabel>Longitude</FormLabel>
        <Input
          name="longitude"
          placeholder="Longitude"
          defaultValue={defaultGeoInfo?.geoInfo?.lng}
          onChange={handleChangeLng}
        />
      </FormControl>
      <FormControl isRequired>
        <FormLabel>City</FormLabel>
        <Input
          name="city"
          placeholder="City"
          defaultValue={defaultGeoInfo?.geoInfo?.city}
          onChange={handleChangeCity}
        />
      </FormControl>
      <FormControl isRequired>
        <FormLabel>Address</FormLabel>
        <Input
          name="address"
          placeholder="Address"
          defaultValue={defaultGeoInfo?.geoInfo?.address}
          onChange={handleChangeAddress}
        />
      </FormControl>
    </Stack>
  );
}
