import { axiosInstance } from "api/common/axios";
import { ApiURI } from "api/common/url";
import { Resp } from "dto";

export const fetchAutoReviewStatus = () => {
  return axiosInstance.get<Resp<boolean>>(ApiURI.FetchAutoReviewStatus);
};

export const toggleAutoReviewStatus = () => {
  return axiosInstance.post<Resp<boolean>>(ApiURI.ToggleAutoReviewStatus);
};
