import {
  Box,
  IconButton,
  Spinner,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import { CustomTr } from "components";
import { PurchaseFrom } from "constant";
import { IAPNotification } from "dto";
import { Suspense } from "react";
import { BsArrowRight } from "react-icons/bs";
import { useRecoilValue } from "recoil";
import { notificationsQuery } from "state";
import { timestampMsToDateStr } from "utils/functions";

export function PurchaseNotifications() {
  return (
    <Suspense fallback={<Spinner />}>
      <Box overflowX="scroll">
        <Table variant="simple">
          <NotificationsTableHead />
          <NotificationsTableBody />
        </Table>
      </Box>
    </Suspense>
  );
}

function NotificationsTableHead() {
  return (
    <Thead>
      <Tr>
        <Th>#</Th>
        <Th>Purchase From</Th>
        <Th>Original Transaction ID</Th>
        <Th>Type</Th>
        <Th>Message Date</Th>
        <Th>Created At</Th>
        <Th>Updated At</Th>
        <Th></Th>
      </Tr>
    </Thead>
  );
}

function NotificationsTableBody() {
  const notifications = useRecoilValue(notificationsQuery);

  return (
    <Tbody>
      {notifications.map((notification, index) => (
        <NotificationsTableCell
          key={index}
          notification={notification}
        />
      ))}
    </Tbody>
  );
}

interface NotificationsTableCellProps {
  notification: IAPNotification;
}

function NotificationsTableCell(props: NotificationsTableCellProps) {
  const { notification } = props;
  return (
    <CustomTr>
      <Td>{notification.id}</Td>
      <Td>{PurchaseFrom[notification.purchaseFrom]}</Td>
      <Td>{notification.originalTransactionId}</Td>
      <Td>{notification.type}</Td>
      <Td>{timestampMsToDateStr(notification.eventTime)}</Td>
      <Td>{timestampMsToDateStr(notification.createdAt)}</Td>
      <Td>{timestampMsToDateStr(notification.updatedAt)}</Td>
      <Td>
        <ViewMoreButton notificationId={notification.id} />
      </Td>
    </CustomTr>
  );
}

function ViewMoreButton(props: ViewMoreButtonProps) {
  // handlers
  const handleClick = () => {
    console.log("view more: ", props.notificationId);
  };

  return (
    <IconButton
      aria-label="view more"
      icon={<BsArrowRight />}
      onClick={handleClick}
    />
  );
}

interface ViewMoreButtonProps {
  notificationId: number;
}
