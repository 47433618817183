import { Button } from "@chakra-ui/react";
import { useRecoilValue } from "recoil";
import { RouterURI } from "router/RouterURI";
import { usersQuery } from "state";
import { openInNewTab } from "utils/functions";

export function OpenAllUsers() {
  const users = useRecoilValue(usersQuery);

  const handleClick = () => {
    users.forEach((u) => {
      openInNewTab(RouterURI.Users + "/" + u.uid);
    });
  };

  return <Button onClick={handleClick}>Open All</Button>;
}
