import { Button, HStack, Spacer, Text } from "@chakra-ui/react";
import { Suspense } from "react";
import { BlockISPButton } from "./isp.block-button";

export function ISPView({ isp }: { isp: string }) {
  return (
    <HStack>
      <Text whiteSpace={"normal"}>{isp}</Text>
      <Spacer />
      <Suspense
        fallback={
          <Button
            size="sm"
            opacity={0}
          />
        }>
        <BlockISPButton isp={isp} />
      </Suspense>
    </HStack>
  );
}
