import { Text } from "@chakra-ui/react";
import { RecoilValueReadOnly, useRecoilValue } from "recoil";
import { pageAtom, pageSizeAtom } from "state";

export function PaginationTotalView(props: PaginationTotalViewProps) {
  const total = useRecoilValue(props.state);
  const page = useRecoilValue(pageAtom);
  const pageSize = useRecoilValue(pageSizeAtom);

  return (
    <Text>{(page - 1) * pageSize + 1} - {Math.min(page * pageSize, total)} of {total}</Text>
  );
}
interface PaginationTotalViewProps {
  state: RecoilValueReadOnly<number>;
}
