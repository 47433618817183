import {
  AspectRatio,
  Box,
  CircularProgress,
  CircularProgressLabel,
  HStack,
  Icon,
  Image,
  Spacer,
  Stack,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import { Gender, MediaStatus, UserStatus, getGestureLabel } from "constant";
import { MediaGender, Photo, User } from "dto";
import { RecommendedBadge } from "features";
import { UnderageButton } from "features/user/actions";
import { GenderEditor } from "features/user/components/GenderEditor";
import _ from "lodash";
import { FaRegFrownOpen } from "react-icons/fa";
import { useRecoilState, useRecoilValue } from "recoil";
import {
  photosToRejectAtom,
  updatedProfilesAtom,
  usersToRejectAtom,
  verifyImagesToRejectAtom,
} from "state";
import { timestampMsToAge, toggleItemOfArr } from "utils/functions";
import { RiskStatusBadge } from "./RiskStatusBadge";
import { SelectedCardView } from "./SelectedView";
import { ZStack } from "./common";
import { ImageId, ImageLink, UserId, UserIdIcon } from "./link";
import { IpView } from "./modal";
import { MediaStatusTag, UserStatusTag } from "./tag";

type UserCardProps = {
  user: User;
};

interface UserPhotoCardProps {
  photo: Photo;
}

interface UserVerifyCardProps {
  verifyMedia?: Photo;
  otherVerifyMedia?: Photo[];
}

export function UserCard(props: UserCardProps) {
  const { user } = props;

  return (
    <HStack>
      {/* 基础信息 */}
      <UserBasicInfoCard user={user} />

      {/* 认证照片 */}
      <UserVerifyInfoCard
        verifyMedia={user.verify}
        otherVerifyMedia={user.oldVerifies}
      />

      {/* 其他照片 */}
      {user.photos?.map((photo, index) => (
        <UserPhotoCard
          photo={photo}
          key={index}
        />
      ))}
    </HStack>
  );
}

export function UserBasicInfoCard({ user }: UserCardProps) {
  const borderColor = useColorModeValue("gray.100", "gray.700");

  const [usersToReject, setUsersToReject] = useRecoilState(usersToRejectAtom);
  const isSelected = usersToReject.includes(user.uid);

  const updatedProfiles = useRecoilValue(updatedProfilesAtom);
  const updatedProfile = updatedProfiles.find((item) => item.uid === user?.uid);

  const currentUserGender = updatedProfile?.gender ?? user?.gender;
  const currentUserBirthday = updatedProfile?.birthday ?? user.birthday;

  const handleSelect = () => {
    if ([UserStatus.Pending, UserStatus.Approved].includes(user.status)) {
      setUsersToReject((curr) => toggleItemOfArr(curr, user.uid));
    }
  };

  return (
    <Stack
      border="1px"
      borderColor={borderColor}
      spacing={0}
      _hover={{ bg: borderColor }}
      bg={isSelected ? borderColor : undefined}>
      <AspectRatio
        w="188px"
        ratio={10 / 16}
        borderBottom="1px"
        borderColor={borderColor}>
        <Box>
          <ZStack
            w="100%"
            h="100%"
            cursor="pointer"
            pointerEvents="none">
            {/* handle click event */}
            <Box
              w="100%"
              h="100%"
              onClick={handleSelect}
              pointerEvents="auto"
            />

            <Stack
              h="100%"
              p="2"
              spacing={2}>
              <RecommendedBadge
                user={user}
                key={user.uid}
              />
              <HStack w="100%">
                <Text
                  fontSize={18}
                  fontWeight={"semibold"}>
                  {timestampMsToAge(currentUserBirthday)}
                </Text>
                <Spacer />
                <UnderageButton user={user} />
              </HStack>

              <HStack w="100%">
                <Text>{currentUserGender && Gender[currentUserGender]}</Text>
                <Spacer />
                <GenderEditor
                  user={user}
                  key={user.uid}
                />
              </HStack>
              <Text>
                {[
                  user.currGeoInfo?.city || "--",
                  user.currGeoInfo?.countryCode || "--",
                ].join(", ")}
              </Text>

              <Spacer />
              <IpView
                ip={user.currIp}
                short={true}
              />

              <RiskStatusBadge
                user={user}
                suspicious={user.suspicious}
              />
            </Stack>

            {isSelected && <SelectedCardView />}
          </ZStack>
        </Box>
      </AspectRatio>

      <HStack p={2}>
        <UserId
          uid={user.uid}
          isExternal
        />
        <Spacer />
        <UserStatusTag status={user.status} />
      </HStack>
    </Stack>
  );
}

export function UserVerifyInfoCard(props: UserVerifyCardProps) {
  const { verifyMedia, otherVerifyMedia } = props;

  const borderColor = useColorModeValue("gray.100", "gray.700");

  const [verifyImageToReject, setVerifyImageToReject] = useRecoilState(
    verifyImagesToRejectAtom
  );

  const isSelected =
    verifyMedia && verifyImageToReject.includes(verifyMedia.id);

  const approvedColor = useColorModeValue("green.100", "green.700");
  const rejectedColor = useColorModeValue("red.100", "red.700");

  const getStatusColor = () => {
    if (verifyMedia?.status === MediaStatus.Rejected) {
      return rejectedColor;
    } else if (verifyMedia?.status === MediaStatus.Approved) {
      return approvedColor;
    } else {
      return undefined;
    }
  };

  const handleSelect = () => {
    console.log("select verify image id: ", verifyMedia?.id);

    if (!verifyMedia) {
      return;
    }

    if (
      [MediaStatus.Pending, MediaStatus.Approved].includes(verifyMedia.status)
    ) {
      setVerifyImageToReject((curr) => toggleItemOfArr(curr, verifyMedia.id));
    }
  };

  return (
    <HStack
      spacing={0}
      alignItems="flex-start"
      pr={8}>
      <Stack
        border="1px"
        borderColor={borderColor}
        spacing={0}
        w="188px"
        _hover={{ bg: borderColor }}
        cursor="pointer"
        bg={isSelected ? borderColor : undefined}>
        <AspectRatio
          ratio={10 / 16}
          onClick={handleSelect}>
          <Stack>
            <ZStack
              w="100%"
              h="100%">
              <AspectRatio ratio={10 / 16}>
                <>
                  {verifyMedia ? (
                    <Image src={verifyMedia.url} />
                  ) : (
                    <Box>
                      <Icon
                        as={FaRegFrownOpen}
                        w="24px"
                        h="24px"
                      />
                    </Box>
                  )}
                </>
              </AspectRatio>

              {verifyMedia && <MediaInfo media={verifyMedia} />}

              {isSelected && <SelectedCardView />}
            </ZStack>
          </Stack>
        </AspectRatio>

        <HStack
          p={2}
          bg={getStatusColor()}>
          {verifyMedia ? (
            <>
              <ImageId image={verifyMedia} />
              <Spacer />

              <Text>{getGestureLabel(verifyMedia.position)}</Text>
              <MediaStatusTag status={verifyMedia.status} />
            </>
          ) : (
            <Text opacity={0.6}>Missing Verify Image</Text>
          )}
        </HStack>
      </Stack>

      {/* otherVerifyImage  */}
      <Stack
        w="47.8px"
        spacing={0}>
        {otherVerifyMedia?.map((image, index) => (
          <ImageLink
            key={index}
            image={image}
            _hover={{ opacity: 0.9 }}
            border="1px"
            borderColor={borderColor}>
            <AspectRatio ratio={10 / 16}>
              <Image src={image.url} />
            </AspectRatio>
          </ImageLink>
        ))}
      </Stack>
    </HStack>
  );
}

export function UserPhotoCard(props: UserPhotoCardProps) {
  const { photo } = props;

  const borderColor = useColorModeValue("gray.100", "gray.700");

  const [photosToReject, setPhotosToReject] =
    useRecoilState(photosToRejectAtom);

  const isSelected = photosToReject.includes(photo.id);

  const approvedColor = useColorModeValue("green.100", "green.700");
  const rejectedColor = useColorModeValue("red.100", "red.700");

  const getStatusColor = () => {
    if (photo.status === MediaStatus.Rejected) {
      return rejectedColor;
    } else if (photo.status === MediaStatus.Approved) {
      return approvedColor;
    } else {
      return undefined;
    }
  };

  const handleSelect = () => {
    console.log("select photo id: ", photo.id);

    if ([MediaStatus.Pending, MediaStatus.Approved].includes(photo.status)) {
      setPhotosToReject((curr) => toggleItemOfArr(curr, photo.id));
    }
  };

  return (
    <Stack
      border="1px"
      borderColor={borderColor}
      spacing={0}
      _hover={{ bg: borderColor }}
      cursor="pointer"
      bg={isSelected ? borderColor : undefined}>
      <AspectRatio
        w="188px"
        ratio={10 / 16}
        onClick={handleSelect}>
        <Stack>
          <ZStack
            w="100%"
            h="100%">
            <AspectRatio ratio={10 / 16}>
              <Image src={photo.url} />
            </AspectRatio>

            <CircularProgress
              value={photo.riskScore / 100}
              size="44px"
              thickness="6px"
              p={2}>
              <CircularProgressLabel>
                {photo.riskScore / 100}
              </CircularProgressLabel>
            </CircularProgress>

            <MediaInfo media={photo} />

            {isSelected && <SelectedCardView />}
          </ZStack>
        </Stack>
      </AspectRatio>

      <HStack
        p={2}
        bg={getStatusColor()}>
        <ImageId image={photo} />
        <Spacer />
        <MediaStatusTag status={photo.status} />
        <UserIdIcon
          uid={photo.uid}
          isExternal
        />
      </HStack>
    </Stack>
  );
}

export function MediaInfo({ media }: { media: Photo }) {
  let faces = media.info?.faceInfos || [];

  const mostSimilarFace = _.sortBy(faces, (i) => -i.maxSimilarScore)[0];

  return (
    <>
      {media?.info && (
        <Stack
          w="100%"
          h="100%"
          alignItems="flex-end"
          p={2}
          fontSize="12"
          textColor="green.400">
          <Text>
            Face: {media.info.clientHasFace} | {media.info.serviceFaceCount}
          </Text>

          {mostSimilarFace && (
            <Stack
              alignItems="flex-end"
              textColor="green.400">
              <Text>
                Vitality: {mostSimilarFace.vitality} |{" "}
                {mostSimilarFace.irVitality}
              </Text>
              <Text>Gender: {MediaGender[mostSimilarFace.gender]}</Text>
              <Text>Age: {mostSimilarFace.age}</Text>
              {mostSimilarFace.maxSimilarScore !== 0 && (
                <Text>
                  Similar: {Math.floor(mostSimilarFace.maxSimilarScore / 10)}%
                </Text>
              )}
            </Stack>
          )}
        </Stack>
      )}
    </>
  );
}
