import {
  Button,
  Link,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spinner,
  Table,
  Tbody,
  Td,
  Tr,
  useDisclosure,
} from "@chakra-ui/react";
import { CustomStatValue } from "components";
import { ISPView } from "features";

import { Suspense } from "react";
import { useRecoilValue } from "recoil";
import { isIPv6, truncateIPv6 } from "utils/functions";
import { BanIPButton } from "./BanIPButton";
import { ipDetailQuery, ipScoreQuery } from "./state";

export function IpView({ ip, short }: { ip: string; short?: boolean }) {
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <>
      {ip === "" ? (
        <CustomStatValue>--</CustomStatValue>
      ) : (
        <Link
          as={CustomStatValue}
          pointerEvents={"auto"}
          onClick={onOpen}>
          {short && isIPv6(ip) ? truncateIPv6(ip, 3) : ip}
        </Link>
      )}

      {ip && (
        <Modal
          isOpen={isOpen}
          onClose={onClose}
          autoFocus={false}
          scrollBehavior="inside">
          <ModalOverlay />
          <ModalContent>
            <ModalCloseButton />
            <ModalHeader>IP Info</ModalHeader>

            <ModalBody>
              <Suspense fallback={<Spinner />}>
                <IPInfoTable ip={ip} />
              </Suspense>
            </ModalBody>

            <ModalFooter>
              <BanIPButton
                colorScheme="red"
                variant="ghost"
                mr={3}
                ip={ip}
              />
              <Button onClick={onClose}>Close</Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      )}
    </>
  );
}

function IPInfoTable({ ip }: { ip: string }) {
  const ipQualityScore = useRecoilValue(ipScoreQuery(ip));
  const ipDetail = useRecoilValue(ipDetailQuery(ip));

  if (!ipQualityScore) return <></>;
  if (!ipDetail) return <></>;

  return (
    <Table variant="simple">
      <Tbody>
        <Tr>
          <Td>ISP</Td>
          <Td w="100%">
            <ISPView isp={ipDetail.isp} />
          </Td>
        </Tr>
        <Tr>
          <Td>Connection Type</Td>
          <Td>{ipQualityScore.connectionType}</Td>
        </Tr>
        <Tr>
          <Td>Organization</Td>
          <Td>{ipDetail.organization}</Td>
        </Tr>
        <Tr>
          <Td>Proxy</Td>
          <Td color={ipQualityScore.proxy ? "red" : "green"}>
            {String(ipQualityScore.proxy)}
          </Td>
        </Tr>
        <Tr>
          <Td>VPN</Td>
          <Td color={ipQualityScore.vpn ? "red" : "green"}>
            {String(ipQualityScore.vpn)}
          </Td>
        </Tr>
        <Tr>
          <Td>TOR</Td>
          <Td color={ipQualityScore.tor ? "red" : "green"}>
            {String(ipQualityScore.tor)}
          </Td>
        </Tr>
        <Tr>
          <Td>Fraud Score</Td>
          <Td color={ipQualityScore.fraudScore > 75 ? "red" : "green"}>
            {ipQualityScore.fraudScore}
          </Td>
        </Tr>
        <Tr>
          <Td>Country</Td>
          <Td>{ipDetail.countryCode}</Td>
        </Tr>
        <Tr>
          <Td>City</Td>
          <Td>{ipDetail.city}</Td>
        </Tr>

        <Tr>
          <Td>Timezone</Td>
          <Td>{ipDetail.timezone}</Td>
        </Tr>
      </Tbody>
    </Table>
  );
}
