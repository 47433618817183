import { Button, useToast } from "@chakra-ui/react";
import dayjs from "dayjs";
import { useState } from "react";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { getErrorMessage } from "utils/functions";
import { blockIsp, unblockIsp } from "./isp.api";
import { ispsQuery, ispsRequestIdAtom } from "./isp.state";

export function BlockISPButton({ isp }: { isp: string }) {
  const toast = useToast();
  const [isLoading, toggleLoading] = useState(false);
  const setRequestId = useSetRecoilState(ispsRequestIdAtom);

  const isps = useRecoilValue(ispsQuery(isp));

  const handleBlock = async () => {
    toggleLoading(true);
    try {
      await blockIsp(isp);
      toggleLoading(false);
      setRequestId(dayjs().valueOf());
    } catch (error) {
      // handle error
      toggleLoading(false);
      toast({
        title: getErrorMessage(error),
        status: "error",
      });
    }
  };

  const handleUnblock = async () => {
    console.log(isps);
    if (isps.length === 0) return;

    toggleLoading(true);

    try {
      await unblockIsp(isps[0].id);
      toggleLoading(false);
      setRequestId(dayjs().valueOf());
    } catch (error) {
      // handle error
      toggleLoading(false);
      toast({
        title: getErrorMessage(error),
        status: "error",
      });
    }
  };

  return (
    <>
      {isps.length > 0 ? (
        <Button
          size="sm"
          colorScheme="green"
          variant="ghost"
          onClick={handleUnblock}
          isLoading={isLoading}>
          Unblock
        </Button>
      ) : (
        <Button
          size="sm"
          colorScheme="red"
          variant="ghost"
          onClick={handleBlock}
          isLoading={isLoading}>
          Block
        </Button>
      )}
    </>
  );
}
