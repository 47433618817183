import { Stack } from "@chakra-ui/react";
import { CustomTabProps, CustomTabs } from "components";
import { TransactionsTable } from "./components/TransactionsTable";

const tabs: CustomTabProps[] = [
  { title: "All" },
  { title: "Subscriptions" },
  { title: "Boosts" },
  { title: "Super Flips" },
];

export function ReceiptTransactions() {
  return (
    <Stack>
      <CustomTabs tabs={tabs}></CustomTabs>;
      <TransactionsTable />
    </Stack>
  );
}
