import { Box, Flex, useColorModeValue } from "@chakra-ui/react";
import { Outlet } from "react-router-dom";
import { NavBar } from "./NavBar/NavBar";
import { Sidebar } from "./Sidebar/Sidebar";

// MainLayout 主布局
export const MainLayout = () => {
  const sideBarBg = useColorModeValue("purple.100", "purple.700");

  return (
    <Flex height="100vh">
      <Box
        overflowY="auto"
        bg={sideBarBg}>
        <Sidebar />
      </Box>

      <Box
        flex="1"
        overflowY="auto">
        {/* Top: MainNav */}
        <NavBar />

        <Box p={4}>
          <Outlet />
        </Box>
      </Box>
    </Flex>
  );
};
