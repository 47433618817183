export enum DeviceStatus {
  Banned,
  Normal,
  Underage,
}

export enum DeviceIMIERouteURIType {
  Devices,
  Users,
}

export enum JailbreakStatus {
  Origin,
  Jailbreak,
}
