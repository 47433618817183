import {
  fetchIAPBoosts,
  fetchIAPNotifications,
  fetchIAPProductRecords,
  fetchIAPSubscriptions,
  fetchIAPSuperFlips,
  fetchIAPTransactions,
  fetchIAPTransferRecords,
} from "api";
import { purchaseFromAtom, tabAtom, timestampsQuery } from "components";
import { IAPProductTransactionType, IAPProductType } from "constant";
import {
  IAPBoostsParams,
  IAPNotificationsParams,
  IAPProductRecordsParams,
  IAPSubscriptionsParams,
  IAPSuperFlipsParams,
  IAPTransactionsParams,
  IAPTransferRecordsParams,
} from "dto";
import { atom, selector } from "recoil";
import { pageSizeAtom, requestIdState } from "./common";
import { pageAtom, uidAtom } from "./search";

export const subscriptionsRespQuery = selector({
  key: "subscriptions/resp",
  get: async ({ get }) => {
    const page = get(pageAtom);
    const pageSize = get(pageSizeAtom);

    const params: IAPSubscriptionsParams = {
      page,
      pageSize,
    };

    const purchaseFrom = get(purchaseFromAtom);
    if (purchaseFrom !== 0) {
      params.purchaseFrom = purchaseFrom;
    }

    const uid = get(uidAtom);

    if (uid) {
      params.uid = uid;
    } else {
      const timestamps = get(timestampsQuery);
      params.createdStart = timestamps.createdStart;
      params.createdEnd = timestamps.createdEnd;
    }

    try {
      const resp = await fetchIAPSubscriptions(params);
      return resp.data;
    } catch (error) {
      // TODO: 全局捕获异常
    }
  },
});

export const subscriptionsQuery = selector({
  key: "subscriptions",
  get: ({ get }) => {
    const resp = get(subscriptionsRespQuery);

    if (resp?.error) {
      // TODO: Toast Error
    }

    return resp?.data ?? [];
  },
});

export const subscriptionsTotalQuery = selector({
  key: "subscriptions/total",
  get: ({ get }) => {
    const resp = get(subscriptionsRespQuery);
    return resp?.page?.total ?? 0;
  },
});

export const superFlipsRespQuery = selector({
  key: "superFlips/resp",
  get: async ({ get }) => {
    get(requestIdState);

    const page = get(pageAtom);
    const pageSize = get(pageSizeAtom);

    const params: IAPSuperFlipsParams = {
      page,
      pageSize,
    };

    const uid = get(uidAtom);
    if (!uid) {
      const timestamps = get(timestampsQuery);
      params.createdStart = timestamps.createdStart;
      params.createdEnd = timestamps.createdEnd;
    } else {
      params.uid = uid;
    }

    try {
      const resp = await fetchIAPSuperFlips(params);
      return resp.data;
    } catch (error) {
      // TODO: handle error
    }
  },
});

export const superFlipsQuery = selector({
  key: "superFlips",
  get: ({ get }) => {
    const resp = get(superFlipsRespQuery);

    if (resp?.error) {
      // TODO: Toast Error
    }

    return resp?.data ?? [];
  },
});

export const superFlipsTotalQuery = selector({
  key: "superFlips/total",
  get: ({ get }) => {
    const resp = get(superFlipsRespQuery);
    return resp?.page?.total ?? 0;
  },
});

export const boostsRespQuery = selector({
  key: "boosts/resp",
  get: async ({ get }) => {
    get(requestIdState);

    const page = get(pageAtom);
    const pageSize = get(pageSizeAtom);

    const params: IAPBoostsParams = {
      page,
      pageSize,
    };

    const uid = get(uidAtom);
    if (!uid) {
      const timestamps = get(timestampsQuery);
      params.createdStart = timestamps.createdStart;
      params.createdEnd = timestamps.createdEnd;
    } else {
      params.uid = uid;
    }

    try {
      const resp = await fetchIAPBoosts(params);
      return resp.data;
    } catch (error) {
      // TODO: handle error
    }
  },
});

export const boostsQuery = selector({
  key: "boosts",
  get: ({ get }) => {
    const resp = get(boostsRespQuery);

    if (resp?.error) {
      // TODO: Toast Error
    }

    return resp?.data ?? [];
  },
});

export const boostsTotalQuery = selector({
  key: "boosts/total",
  get: ({ get }) => {
    const resp = get(boostsRespQuery);
    return resp?.page?.total ?? 0;
  },
});

export const purchaseProductTypeAtom = atom({
  key: "purchaseProductType",
  default: IAPProductType.Subscription,
});

export const transactionsRespQuery = selector({
  key: "transactions/resp",
  get: async ({ get }) => {
    // refresh id

    const page = get(pageAtom);
    const pageSize = get(pageSizeAtom);
    const uid = get(uidAtom);

    // 转换product type
    const currentTab = get(tabAtom);
    var purchaseProductTypes: IAPProductType[];
    if (currentTab === 0) {
      purchaseProductTypes = [
        IAPProductType.Boost,
        IAPProductType.Subscription,
        IAPProductType.Super_Flip,
      ];
    } else {
      purchaseProductTypes = [currentTab as IAPProductType];
    }

    const params: IAPTransactionsParams = {
      page,
      pageSize,
      productSubclass: purchaseProductTypes,
    };

    const purchaseFrom = get(purchaseFromAtom);
    if (purchaseFrom !== 0) {
      params.purchaseFrom = purchaseFrom;
    }

    if (uid) {
      params.uid = uid;
    } else {
      const timestamps = get(timestampsQuery);
      params.createdStart = timestamps.createdStart;
      params.createdEnd = timestamps.createdEnd;
    }

    try {
      const resp = await fetchIAPTransactions(params);
      return resp.data;
    } catch (error) {}
  },
});

export const transactionsAtom = selector({
  key: "transactions",
  get: ({ get }) => {
    const resp = get(transactionsRespQuery);

    if (resp?.error) {
      // TODO: Toast Error
    }

    return resp?.data ?? [];
  },
});

export const transactionsTotalQuery = selector({
  key: "transactions/total",
  get: ({ get }) => {
    const resp = get(transactionsRespQuery);
    return resp?.page?.total ?? 0;
  },
});

export const transferRecordsRespQuery = selector({
  key: "transferRecords/resp",
  get: async ({ get }) => {
    const page = get(pageAtom);
    const pageSize = get(pageSizeAtom);
    const timestamps = get(timestampsQuery);
    const uid = get(uidAtom);

    const params: IAPTransferRecordsParams = {
      page,
      pageSize,
    };

    if (uid) {
      params.uid = uid;
    } else {
      params.createdStart = timestamps.createdStart;
      params.createdEnd = timestamps.createdEnd;
    }

    try {
      const resp = await fetchIAPTransferRecords(params);
      return resp.data;
    } catch (error) {
      // TODO: handle error
    }
  },
});

export const transferRecordsQuery = selector({
  key: "transferRecords",
  get: ({ get }) => {
    const resp = get(transferRecordsRespQuery);

    if (resp?.error) {
      // TODO: handle error
    }

    return resp?.data ?? [];
  },
});

export const transferRecordsTotalQuery = selector({
  key: "transferRecords/total",
  get: ({ get }) => {
    const resp = get(transferRecordsRespQuery);
    return resp?.page?.total ?? 0;
  },
});

export const productTransactionsRespQuery = selector({
  key: "productTransactions/resp",
  get: async ({ get }) => {
    const page = get(pageAtom);
    const pageSize = get(pageSizeAtom);

    // 转换记录类型
    const currentTab = get(tabAtom);
    var productTransactionTypes: IAPProductTransactionType[] = [];
    if (currentTab === 0) {
      // Boosts
      productTransactionTypes = [
        IAPProductTransactionType.BoostPurchase,
        IAPProductTransactionType.BoostConsume,
        IAPProductTransactionType.BoostReward,
      ];
    } else if (currentTab === 1) {
      productTransactionTypes = [
        IAPProductTransactionType.SuperFlipPurchase,
        IAPProductTransactionType.SuperFlipConsume,
        IAPProductTransactionType.SuperFlipReward,
      ];
    }

    const params: IAPProductRecordsParams = {
      page,
      pageSize,
      types: productTransactionTypes,
    };

    const uid = get(uidAtom);
    if (uid) {
      params.uid = uid;
    } else {
      const timestamps = get(timestampsQuery);
      params.createdStart = timestamps.createdStart;
      params.createdEnd = timestamps.createdEnd;
    }

    try {
      const resp = await fetchIAPProductRecords(params);
      return resp.data;
    } catch (error) {
      // TODO: handle error
    }
  },
});

export const productTransactionsQuery = selector({
  key: "productTransactions",
  get: ({ get }) => {
    const resp = get(productTransactionsRespQuery);

    if (resp?.error) {
      // TODO: handle error
    }

    return resp?.data ?? [];
  },
});

export const productTransactionsTotalQuery = selector({
  key: "productTransactions/total",
  get: ({ get }) => {
    const resp = get(productTransactionsRespQuery);
    return resp?.page?.total ?? 0;
  },
});

export const notificationsRespQuery = selector({
  key: "notifications/resp",
  get: async ({ get }) => {
    const page = get(pageAtom);
    const pageSize = get(pageSizeAtom);
    const timestamps = get(timestampsQuery);

    const params: IAPNotificationsParams = {
      page,
      pageSize,
    };

    const purchaseFrom = get(purchaseFromAtom);
    if (purchaseFrom !== 0) {
      params.purchaseFrom = purchaseFrom;
    }

    params.createdStart = timestamps.createdStart;
    params.createdEnd = timestamps.createdEnd;

    try {
      const resp = await fetchIAPNotifications(params);
      return resp.data;
    } catch (error) {
      // TODO: handle error
    }
  },
});

export const notificationsQuery = selector({
  key: "notifications",
  get: ({ get }) => {
    const resp = get(notificationsRespQuery);

    if (resp?.error) {
      // TODO: handle error
    }

    return resp?.data ?? [];
  },
});

export const notificationsTotalQuery = selector({
  key: "notifications/total",
  get: ({ get }) => {
    const resp = get(notificationsRespQuery);
    return resp?.page?.total ?? 0;
  },
});
