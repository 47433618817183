import { Box, Select } from "@chakra-ui/react";
import React from "react";
import { RecoilState, useRecoilState, useResetRecoilState } from "recoil";
import { pageAtom } from "state";

export interface FilterSelectorOption {
  label: string;
  value: number;
}

interface FilterSelectorProps {
  placeholder?: string;
  state: RecoilState<number>;
  options?: FilterSelectorOption[];
}


export function FilterSelector(props: FilterSelectorProps) {

  const [value, setValue] = useRecoilState(props.state)
  const resetPage = useResetRecoilState(pageAtom)

  const handleChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const newValue = e.target.value
    setValue(+newValue)
    resetPage()
  }

  return (
    <Box>
      <Select
        placeholder={props.placeholder}
        defaultValue={value}
        onChange={handleChange}>
        {props.options?.map((opt, index) => (
          // value需从1开始
          <option key={index} value={opt.value}>{opt.label}</option>
        ))}
      </Select>
    </Box>
  );
}