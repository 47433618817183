import { Button, ButtonProps } from "@chakra-ui/react";
import { reviewUser } from "api";
import dayjs from "dayjs";
import { UserToReview } from "dto";
import { useState } from "react";
import { useResetRecoilState, useSetRecoilState } from "recoil";
import { setRecoil } from "recoil-nexus";
import {
  bioListToRejectAtom,
  errorState,
  photosToRejectAtom,
  questionsToRejectAtom,
  requestIdState,
  updatedProfilesAtom,
  usersToRejectAtom,
  verifyImagesToRejectAtom,
  voicesToRejectAtom,
} from "state";
import { getErrorMessage } from "utils/functions";
import { reviewHistoriesRequestIdAtom } from "../review-history";

export type ReviewButtonWrapperProps = {
  userToReview: UserToReview;
};

export function ReviewButtonWrapper(
  props: ReviewButtonWrapperProps & ButtonProps
) {
  const { userToReview, children, ...restButtonProps } = props;

  // local state
  const [isSubmitting, setIsSubmitting] = useState(false);

  const refreshRequestId = useSetRecoilState(requestIdState);
  const refreshReviewHistoriesRequestId = useSetRecoilState(
    reviewHistoriesRequestIdAtom
  );

  const resetUsersToReject = useResetRecoilState(usersToRejectAtom);
  const resetPhotosToReject = useResetRecoilState(photosToRejectAtom);
  const resetVerifyImagesToReject = useResetRecoilState(
    verifyImagesToRejectAtom
  );
  const resetBioListToReject = useResetRecoilState(bioListToRejectAtom);
  const resetQuestionsToReject = useResetRecoilState(questionsToRejectAtom);
  const resetVoicesToReject = useResetRecoilState(voicesToRejectAtom);
  const resetUpdatedProfiles = useResetRecoilState(updatedProfilesAtom);

  const refresh = () => {
    // 更新时间戳，触发review 列表自动拉取数据
    refreshRequestId(dayjs().valueOf());
    refreshReviewHistoriesRequestId(dayjs().valueOf());
  };

  const reset = () => {
    resetUsersToReject();
    resetPhotosToReject();
    resetBioListToReject();
    resetQuestionsToReject();
    resetVoicesToReject();
    resetVerifyImagesToReject();
    resetUpdatedProfiles();
  };

  const onSubmit = async () => {
    try {
      setIsSubmitting(true);

      console.log(userToReview);
      await reviewUser(userToReview);
      refresh();
      reset();
    } catch (error) {
      setRecoil(errorState, getErrorMessage(error));
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <Button
      onClick={onSubmit}
      isLoading={isSubmitting}
      {...restButtonProps}>
      {children}
    </Button>
  );
}
