import { fetchMember } from "api";
import { Member } from "dto";
import { atom, selectorFamily } from "recoil";

export const membersState = atom<Member[]>({
  key: "membersState",
  default: [],
});

export const memberQuery = selectorFamily({
  key: "memberQuery",
  get:
    (memberId: number) =>
    async ({ get }) => {
      let member: Member | undefined;

      const members = get(membersState);
      member = members.find((item) => item.id === memberId);

      if (!member) {
        try {
          const res = await fetchMember(memberId);
          member = res.data.data;
        } catch (error) {
          throw error;
        }
      }

      return member;
    },
});
