import {
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  HStack,
  Input,
  Stack,
  useToast,
} from "@chakra-ui/react";
import { yupResolver } from "@hookform/resolvers/yup";
import { createMember } from "api";
import { MemberToCreate } from "dto";
import { Controller, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { getErrorMessage } from "utils/functions";
import * as yup from "yup";

const schema = yup
  .object({
    username: yup.string().required(),
    email: yup.string().email().required(),
  })
  .required();

export function CreateMemberPage() {
  const navigate = useNavigate();
  const toast = useToast();

  const { control, formState, handleSubmit } = useForm<MemberToCreate>({
    // 必须指定默认值，否则console报错给你看
    defaultValues: {
      username: "",
      email: "",
    },
    // 绑定yup验证规则
    resolver: yupResolver(schema),
  });

  const onSubmit = handleSubmit(async (data) => {
    try {
      // 数据库登记
      await createMember(data);

      // toast
      toast({
        title: "Create member success",
        status: "success",
      });
      // back
      navigate(-1);
    } catch (error) {
      toast({
        title: "Create member failed",
        description: getErrorMessage(error),
        status: "error",
      });
    }
  });

  return (
    <Stack spacing={8}>
      <Heading>New Member</Heading>

      <form onSubmit={onSubmit}>
        <Stack spacing={8}>
          <Controller
            name="username"
            control={control}
            render={({ field }) => (
              <FormControl
                maxW="375px"
                // isRequired
                isInvalid={!!formState.errors.username}>
                <FormLabel htmlFor="username">Username</FormLabel>
                <Input
                  type="text"
                  id="username"
                  placeholder="Username"
                  {...field}
                />
                <FormErrorMessage>
                  {formState.errors.username?.message}
                </FormErrorMessage>
              </FormControl>
            )}
          />

          <Controller
            name="email"
            control={control}
            render={({ field }) => (
              <FormControl
                maxW="375px"
                // isRequired
                isInvalid={!!formState.errors.email}>
                <FormLabel htmlFor="email">Email</FormLabel>
                <Input
                  type="text"
                  id="email"
                  placeholder="Email"
                  {...field}
                />
                <FormErrorMessage>
                  {formState.errors.email?.message}
                </FormErrorMessage>
              </FormControl>
            )}
          />

          <HStack>
            <Button
              onClick={() => navigate(-1)}
              variant="ghost">
              Cancel
            </Button>
            <Button
              isLoading={formState.isSubmitting}
              type="submit">
              Create
            </Button>
          </HStack>
        </Stack>
      </form>
    </Stack>
  );
}
