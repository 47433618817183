import { Box, Spinner, Stack, useColorModeValue } from "@chakra-ui/react";
import { Suspense } from "react";
import { useRecoilValue } from "recoil";
import { sessionsQuery } from "state";
import { ChannelCell } from "./ChannelCell";
import { DateRangeRadioGroup } from "./TimeWindowRadioGroup";

export function ChannelsView() {

  return (
    <Stack px={2}>
      <ChannelsViewHeader />
      <Suspense fallback={<Spinner />}>
        <SessionsViewList />
      </Suspense>
    </Stack>
  );
}

function ChannelsViewHeader() {
  const bg = useColorModeValue("white", "gray.700")

  return (
    <Box py={2} position="sticky" top="0" bg={bg} zIndex={5}>
      <DateRangeRadioGroup />
    </Box>
  )
}

function SessionsViewList() {

  const sessions = useRecoilValue(sessionsQuery)

  if (!sessions) return <></>

  return (
    <Stack pb={2}>
      {sessions.sessions.map((item, i) => <ChannelCell key={i} session={item} />)}
    </Stack>
  )
}