import { SimpleGrid, Stack } from "@chakra-ui/react";
import { CustomStat, CustomStatLabel, CustomStatValue } from "components";
import {
  BodyType,
  Drinking,
  Drugs,
  Education,
  Ethnicity,
  RelationshipStatus,
  Religion,
  Smoking,
  STDCheck,
  STDStatus,
} from "constant";
import { useRecoilValue } from "recoil";
import { userInfoExtraQuery } from "state";
import { inchesToHeightInFeetAndInches } from "utils/functions";
import { Caption } from "./components";

export function ExtraPanel() {
  const extra = useRecoilValue(userInfoExtraQuery);

  return (
    <Stack>
      <Caption title="Extra" />
      <SimpleGrid
        spacing={4}
        columns={4}>
        <CustomStat>
          <CustomStatLabel>Height</CustomStatLabel>
          <CustomStatValue>
            {extra?.height
              ? inchesToHeightInFeetAndInches(extra?.height)
              : "--"}
          </CustomStatValue>
        </CustomStat>

        <CustomStat>
          <CustomStatLabel>Ethnicity</CustomStatLabel>
          <CustomStatValue>
            {extra?.ethnicity ? Ethnicity[extra?.ethnicity] : "--"}
          </CustomStatValue>
        </CustomStat>
        <CustomStat>
          <CustomStatLabel>Body Type</CustomStatLabel>
          <CustomStatValue>
            {extra?.bodyType ? BodyType[extra?.bodyType] : "--"}
          </CustomStatValue>
        </CustomStat>
        <CustomStat>
          <CustomStatLabel>Relationship Status</CustomStatLabel>
          <CustomStatValue>
            {extra?.relationshipStatus
              ? RelationshipStatus[extra?.relationshipStatus]
              : "--"}
          </CustomStatValue>
        </CustomStat>
        <CustomStat>
          <CustomStatLabel>Religion</CustomStatLabel>
          <CustomStatValue>
            {extra?.religion ? Religion[extra?.religion] : "--"}
          </CustomStatValue>
        </CustomStat>
        <CustomStat>
          <CustomStatLabel>Drinking</CustomStatLabel>
          <CustomStatValue>
            {extra?.drinking ? Drinking[extra?.drinking] : "--"}
          </CustomStatValue>
        </CustomStat>
        <CustomStat>
          <CustomStatLabel>Smoking</CustomStatLabel>
          <CustomStatValue>
            {extra?.smoking ? Smoking[extra?.smoking] : "--"}
          </CustomStatValue>
        </CustomStat>
        <CustomStat>
          <CustomStatLabel>Drugs</CustomStatLabel>
          <CustomStatValue>
            {extra?.drugs ? Drugs[extra?.drugs] : "--"}
          </CustomStatValue>
        </CustomStat>
        <CustomStat>
          <CustomStatLabel>Std Status</CustomStatLabel>
          <CustomStatValue>
            {extra?.stdStatus ? STDStatus[extra?.stdStatus] : "--"}
          </CustomStatValue>
        </CustomStat>
        <CustomStat>
          <CustomStatLabel>Std Check</CustomStatLabel>
          <CustomStatValue>
            {extra?.stdCheck ? STDCheck[extra?.stdCheck] : "--"}
          </CustomStatValue>
        </CustomStat>
        <CustomStat>
          <CustomStatLabel>Education</CustomStatLabel>
          <CustomStatValue>
            {extra?.education ? Education[extra?.education] : "--"}
          </CustomStatValue>
        </CustomStat>
        <CustomStat>
          <CustomStatLabel>Job Title</CustomStatLabel>
          <CustomStatValue>{extra?.jobTitle || "--"}</CustomStatValue>
        </CustomStat>
      </SimpleGrid>
    </Stack>
  );
}
