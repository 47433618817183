/* 转换时间戳，计算年龄，获取指定日期的时间戳 */

import { DayjsKey } from "constant";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import { DateOption, Timestamp, TimestampParams } from "dto";

dayjs.extend(customParseFormat);

// timestampMsToDateStr 将毫秒时间戳转换为日期字符串
// 格式MMM DD, YYYY HH:mm:ss
export const timestampMsToDateStr = (timestampMs?: number): string => {
  return !timestampMs
    ? "--"
    : dayjs(timestampMs).format("MMM DD, YYYY HH:mm:ss");
};

// 生日转毫秒时间戳
// Feb 2, 2021 => 1635930000
export const birthdayToTimestampMs = (birthday: string): number => {
  return dayjs(birthday, DayjsKey.BirthdayFormat).valueOf();
};

// 时间戳转生日
// 1635930000 => Feb 2, 2021
export const timestampMsToBirthday = (timestampMs: number): string => {
  return dayjs(timestampMs).format(DayjsKey.BirthdayFormat);
};

// 时间戳转年龄
export const timestampMsToAge = (timestampMs: number): number => {
  return dayjs().diff(dayjs(timestampMs), "year");
};

export const formatBirthday = (timestampMs: Timestamp) => {
  return (
    timestampMsToBirthday(timestampMs) + " / " + timestampMsToAge(timestampMs)
  );
};

// 获取指定月起始时间戳
// month: 0-11
export const getFirstDayTimestampMsOfTheMonth = (month: number): number => {
  return dayjs().month(month).day(1).valueOf();
};

// 获取指定月最后时间戳
// month: 0-11
export const getFirstDayTimestampMsOfNextMonth = (month: number): number => {
  return getFirstDayTimestampMsOfTheMonth(month + 1);
};

// 获取当前月起始时间戳
export const getFirstDayTimestampMsOfCurrentMonth = (): number => {
  return dayjs().day(1).valueOf();
};

export const getTheStartAndEndTimestamps = (
  month: string | number
): TimestampParams => {
  const date = dayjs(String(month), DayjsKey.DatePickerValueFormat);

  return {
    createdStart: date.valueOf() as Timestamp,
    // 不要指定day(1), 会有bug
    createdEnd: date.month(date.month() + 1).valueOf() as Timestamp,
  };
};

// interface DateRangeTimestampMs {
//   startTimestampMs: number,
//   endTimestampMs: number
// }

// 获取指定年月的起止时间戳
// 指定年月格式：YYYY-MM
// export const getTimestampMsRangeOfTheMonth = (dateStr: string): DateRangeTimestampMs => {
//   const date = dayjs(dateStr, "YYYY-MM")
//
//   const abc: DateRangeTimestampMs = {
//     startTimestampMs: getFirstDa
//   }
// }

// generateDateOptions 更具指定的起止年月字符串，生成形如{title: "June 2021", value: 2021-06}的连续数组
// start: October 2017
// end: June 2021
// return: [
//  {title: "June 2021", value: 2021-06}
// ]
export const generateDateOptions = (
  startDateStr: string,
  endDateStr: string,
  format: string
): DateOption[] => {
  // 解析起止时间字符串
  const startDate = dayjs(startDateStr, format);
  const endDate = dayjs(endDateStr, format);

  // 返回值容器
  let dateOptions: DateOption[] = [];

  // 循环生成DateOption
  let tmpDate = endDate.clone();
  while (startDate.isBefore(tmpDate)) {
    // 格式化输出数据
    const dateOption: DateOption = {
      label: tmpDate.format(format),
      value: +tmpDate.format("YYYYMM"),
    };
    // 结果放入dateOptions
    dateOptions.push(dateOption);

    // 减一月，下一轮
    tmpDate = tmpDate.subtract(1, "month");
  }

  return dateOptions;
};

export function convertTimeWindowToTimestamps(timeWindow: string) {
  const endTime = dayjs();

  // 默认为当天的时间
  let startTime: dayjs.Dayjs;

  // ["1 Month", "3 Months", "6 Months", "1 Year"];
  if (timeWindow === "1 Month") {
    startTime = endTime.month(endTime.month() - 1);
  } else if (timeWindow === "3 Months") {
    startTime = endTime.month(endTime.month() - 3);
  } else if (timeWindow === "6 Months") {
    startTime = endTime.month(endTime.month() - 6);
  } else if (timeWindow === "1 Year") {
    startTime = endTime.year(endTime.year() - 1);
  } else {
    startTime = endTime;
  }

  return {
    createdStart: startTime.valueOf() as Timestamp,
    createdEnd: endTime.valueOf() as Timestamp,
  };
}
