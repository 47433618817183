import { Link, LinkProps } from "@chakra-ui/react";
import { tabAtom } from "components";
import { useNavigate } from "react-router-dom";
import { useSetRecoilState } from "recoil";
import { RouterURI } from "router/RouterURI";
import { uidAtom } from "state";

export interface UserPurchasesLinkProps {
  uid: number;
  routeURI: RouterURI;
  tab?: number; // products, orders, records, notifications
}

export function UserPurchasesLink(props: UserPurchasesLinkProps & LinkProps) {
  const { title, uid, tab, routeURI, ...linkProps } = props;

  // 跳转时，指定tab index 为 tab
  const setTab = useSetRecoilState(tabAtom);
  const setUID = useSetRecoilState(uidAtom);
  const navigation = useNavigate();

  const handleClick = () => {
    setTab(tab ?? 0);
    setUID(uid);
    navigation(routeURI);
  };

  return (
    <Link
      onClick={handleClick}
      {...linkProps}>
      {title ?? "--"}
    </Link>
  );
}
