import {
  Badge,
  Box,
  Heading,
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Select,
  Spinner,
  Stack,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import { editDevice } from "api";
import { DeviceStatus } from "constant";
import { Device } from "dto";
import { useState } from "react";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { setRecoil } from "recoil-nexus";
import { errorState } from "state";
import { getErrorMessage } from "utils/functions";
import { updatedDevicesAtom } from "./state";

export function DeviceStatusBadge({ device }: { device: Device }) {
  let badgeColor = undefined;
  let variant: string | undefined = "solid";

  const { isOpen, onOpen, onClose } = useDisclosure();
  const updatedDevices = useRecoilValue(updatedDevicesAtom);

  const targetDevice =
    updatedDevices.find((item) => item.id === device.id) ?? device;

  switch (targetDevice.status) {
    case DeviceStatus.Banned:
      badgeColor = "gray";
      variant = undefined;
      break;
    case DeviceStatus.Normal:
      badgeColor = "green";
      break;
    case DeviceStatus.Underage:
      badgeColor = "yellow";
      break;
  }
  return (
    <Box>
      <Badge
        pointerEvents={"auto"}
        colorScheme={badgeColor}
        variant={variant}
        cursor="pointer"
        onClick={onOpen}>
        {DeviceStatus[targetDevice.status]}
      </Badge>

      <Modal
        isOpen={isOpen}
        onClose={onClose}
        autoFocus={false}
        scrollBehavior="inside">
        <ModalOverlay />
        <ModalContent>
          <ModalCloseButton />
          <ModalHeader>
            <HStack>
              <Text>Device Status</Text>
              <DeviceStatusSelector device={targetDevice} />
            </HStack>
          </ModalHeader>
          <ModalBody>
            <Stack
              spacing={4}
              py={4}>
              <Heading
                as="h2"
                size="sm">
                Change Logs:
              </Heading>
            </Stack>
          </ModalBody>
        </ModalContent>
      </Modal>
    </Box>
  );
}

export function DeviceStatusSelector({ device }: { device: Device }) {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [status, setStatus] = useState(device.status);

  const setUpdatedDevices = useSetRecoilState(updatedDevicesAtom);

  const syncDeviceStatusToState = (status: DeviceStatus) => {
    setStatus(status);

    setUpdatedDevices((prev) => {
      const updatedDevicesMap = new Map(prev.map((item) => [item.id, item]));

      const updatedDevice = updatedDevicesMap.get(device.id) ?? device;
      updatedDevicesMap.set(device.id, {
        ...updatedDevice,
        status,
      });

      return Array.from(updatedDevicesMap.values());
    });
  };

  const handleChange = async (e: React.ChangeEvent<HTMLSelectElement>) => {
    setIsSubmitting(true);

    let prevStatus = status;
    let targetStatus = +e.target.value as DeviceStatus;

    try {
      syncDeviceStatusToState(targetStatus);
      await editDevice(device.imei, {
        status: targetStatus,
      });
    } catch (error) {
      syncDeviceStatusToState(prevStatus);
      setRecoil(errorState, getErrorMessage(error));
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <HStack>
      <Select
        w="auto"
        onChange={handleChange}
        value={status}>
        <option value="0">Banned</option>
        <option value="1">Normal</option>
        <option value="2">Underage</option>
      </Select>
      {isSubmitting && <Spinner size="sm" />}
    </HStack>
  );
}
