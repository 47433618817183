import { getIdToken, signOutFromFirebase } from "api";
import axios, { AxiosError } from "axios";
import { IS_DEBUG } from "utils/functions";
import { StatusCode } from "./code";

// axios 实例
export const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_BASEURL,
  timeout: 60000,
});

axiosInstance.interceptors.request.use(
  async (config) => {
    if (IS_DEBUG) {
      return config;
    }

    try {
      const idToken = await getIdToken();

      // 为headers追加idToken
      config.headers.set("authorization", idToken);
    } catch (err) {
      throw err;
    }

    return config;
  },
  (err) => {
    throw err;
  }
);

axiosInstance.interceptors.response.use(
  async (response) => {
    // 全局拦截错误码
    const { code, error: message }: { code: StatusCode; error: string } =
      response.data;

    switch (code) {
      case StatusCode.Ok:
        // ok
        return response;
      case StatusCode.NotLoggedIn:
        await signOutFromFirebase();
        throw new Error("Invalid User");
      default:
        // 其他错误
        throw new Error(message);
    }
  },
  (error: AxiosError) => {
    // 原生http错误
    // 40x, 50x
    throw error;
  }
);
