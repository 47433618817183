import {
  Button,
  Checkbox,
  CheckboxGroup,
  FormControl,
  FormLabel,
  HStack,
  IconButton,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Select,
  Stack,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { editUser } from "api";
import { Gender } from "constant";
import { GenderToUpdate, User } from "dto";
import _ from "lodash";
import { useState } from "react";
import { CiEdit } from "react-icons/ci";
import { useRecoilState } from "recoil";
import { updatedProfilesAtom } from "state";
import { getErrorMessage } from "utils/functions";

const genders = [
  Gender.Woman,
  Gender.Man,
  Gender.Couple,
  Gender.Couple_2W,
  Gender.Couple_2M,
  Gender.Trans,
];

export type GenderEditorProps = {
  useIcon?: boolean;
  user: User;
};

export function GenderEditor({ useIcon, user }: GenderEditorProps) {
  const toast = useToast();

  const { isOpen, onOpen, onClose } = useDisclosure();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const [updatedProfiles, updateProfiles] = useRecoilState(updatedProfilesAtom);

  const updatedProfile = updatedProfiles.find((item) => item.uid === user.uid);
  const gender = updatedProfile?.gender ?? user.gender;
  const likeGenders = updatedProfile?.likeGenders ?? user.likeGenders;

  const [genderToUpdate, updateGenderToUpdate] = useState<GenderToUpdate>({
    uid: user.uid,
    gender,
    likeGenders,
  });

  // handlers
  const handleChange = ({
    gender,
    seekingIn,
  }: {
    gender?: Gender;
    seekingIn?: Gender[];
  }) => {
    updateGenderToUpdate((curr) => {
      const update = _.cloneDeep(curr);

      if (gender) {
        update.gender = gender;
      }
      if (seekingIn) {
        update.likeGenders = seekingIn;
      }

      return update;
    });
  };

  const handleChangeGender = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const gender = parseInt(e.target.value) as Gender;
    handleChange({ gender });
  };

  const handleChangeSeekingIn = (values: string[]) => {
    const seekingIn = values.map((value) => parseInt(value) as Gender);
    handleChange({ seekingIn });
  };

  const handleSubmit = async () => {
    if (!genderToUpdate) return;

    setIsSubmitting(true);

    try {
      await editUser(genderToUpdate.uid, {
        gender: genderToUpdate.gender,
        likeGenders: genderToUpdate.likeGenders,
      });

      setIsSubmitting(false);

      updateProfiles((curr) => {
        const updatedProfiles = new Map(curr.map((item) => [item.uid, item]));

        const profile = updatedProfiles.get(user.uid) ?? user;

        updatedProfiles.set(user.uid, {
          ...profile,
          gender: genderToUpdate.gender,
          likeGenders: genderToUpdate.likeGenders,
        });

        return Array.from(updatedProfiles.values());
      });

      // 关闭modal
      onClose();

      // 弹窗提示
      toast({
        title: "Edit Gender Success",
        status: "success",
      });
    } catch (error) {
      setIsSubmitting(false);

      toast({
        title: getErrorMessage(error),
        status: "error",
      });
    }
  };

  const handleCancel = () => {
    onClose();
    updateGenderToUpdate({
      uid: user.uid,
      gender,
      likeGenders,
    });
  };

  return (
    <>
      {useIcon ? (
        <IconButton
          icon={<CiEdit />}
          aria-label="edit"
          variant="ghost"
          size="sm"
          pointerEvents="auto"
          onClick={onOpen}
        />
      ) : (
        <Button
          onClick={onOpen}
          size="xs"
          variant="outline"
          pointerEvents="auto">
          Change
        </Button>
      )}

      <Modal
        size="lg"
        isOpen={isOpen}
        onClose={handleCancel}>
        <ModalOverlay />

        <ModalContent>
          <ModalHeader>Change Gender</ModalHeader>

          <ModalBody>
            <Stack>
              <FormControl>
                <FormLabel>Gender</FormLabel>
                <Select
                  onChange={handleChangeGender}
                  defaultValue={genderToUpdate?.gender}>
                  {genders.map((gender, index) => (
                    <option
                      key={index}
                      value={gender.toString()}>
                      {Gender[gender]}
                    </option>
                  ))}
                </Select>
              </FormControl>

              <FormControl>
                <FormLabel as="legend">Seeking In</FormLabel>
                <CheckboxGroup
                  defaultValue={genderToUpdate?.likeGenders?.map((gender) =>
                    gender.toString()
                  )}
                  onChange={handleChangeSeekingIn}>
                  <Stack>
                    {genders.map((g, index) => (
                      <Checkbox
                        id={Gender[g]}
                        key={index}
                        value={g.toString()}>
                        {Gender[g]}
                      </Checkbox>
                    ))}
                  </Stack>
                </CheckboxGroup>
              </FormControl>
            </Stack>
          </ModalBody>

          <ModalFooter>
            <HStack>
              <Button
                onClick={handleCancel}
                variant="ghost">
                Cancel
              </Button>

              <Button
                onClick={handleSubmit}
                isLoading={isSubmitting}>
                Save
              </Button>
            </HStack>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}
