export enum TimeWindowType {
  "24 Hours",
  "7 Days",
  "2 Weeks",
  "1 Month",
}

export interface LocationState {
  from: { pathname: string };
}

// 接口响应体中的时间戳
export type Timestamp = number;

// 起止时间戳参数
export interface TimestampParams {
  createdStart?: Timestamp;
  createdEnd?: Timestamp;
}

// 列表通用查询参数
export interface ListParams extends PageParams, TimestampParams {}

export interface Resp<T> {
  code: number;
  data?: T;
  error?: string;
  page?: PageResp;
}

// 响应body中的page信息
export interface PageResp {
  total: number;
  page: number;
  pageSize: number;
}

// response包含createdAt和updatedAt
export interface TimestampsResp {
  createdAt: Timestamp;
  updatedAt: Timestamp;
}

// Page参数
export interface PageParams {
  page: number;
  pageSize: number;
}

export interface DateOption {
  label: string;
  value: number;
}
