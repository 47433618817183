import { Spinner, Table, Tbody, Td, Th, Thead, Tr } from "@chakra-ui/react";
import {
  BioStatusTag,
  CustomHighlighter,
  CustomTr,
  SelectedView,
  UserId,
  ZStack,
} from "components";
import { BioStatus } from "constant";
import { Bio } from "dto";
import { Suspense } from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import { bioListQuery, bioListToRejectAtom } from "state";
import { timestampMsToDateStr, toggleItemOfArr } from "utils/functions";

export function BioPage() {
  return (
    <Suspense fallback={<Spinner />}>
      <BioListTable />
    </Suspense>
  );
}

function BioListTable() {
  const bioList = useRecoilValue(bioListQuery);

  return (
    <Table variant="simple">
      <Thead>
        <Tr>
          <Th>UID</Th>
          <Th>Content</Th>
          <Th>Status</Th>
          <Th>Created At</Th>
          <Th>Updated At</Th>
        </Tr>
      </Thead>

      <Tbody>
        {bioList.map((bio, i) => (
          <BioListTableItem
            key={i}
            bio={bio}
          />
        ))}
      </Tbody>
    </Table>
  );
}

function BioListTableItem({ bio }: { bio: Bio }) {
  const [bioListToReject, setBioListToReject] =
    useRecoilState(bioListToRejectAtom);

  const isSelected = bioListToReject.includes(bio.id);

  const handleSelect = () => {
    console.log("selected bio id: ", bio.id);

    if ([BioStatus.Pending, BioStatus.Approved].includes(bio.status)) {
      setBioListToReject((curr) => toggleItemOfArr(curr, bio.id));
    }
  };

  return (
    <CustomTr
      onClick={handleSelect}
      isSelected={isSelected}>
      <Td w={1}>
        <UserId
          uid={bio.uid}
          isExternal
        />
      </Td>

      <Td bg={isSelected ? "red.100" : undefined}>
        <ZStack>
          <CustomHighlighter content={bio.content} />
          {isSelected && <SelectedView />}
        </ZStack>
      </Td>

      <Td w={1}>{<BioStatusTag status={bio.status} />}</Td>
      <Td
        w={1}
        whiteSpace="nowrap">
        {timestampMsToDateStr(bio.createdAt)}
      </Td>
      <Td
        w={1}
        whiteSpace="nowrap">
        {timestampMsToDateStr(bio.updatedAt)}
      </Td>
    </CustomTr>
  );
}
