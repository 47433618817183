import { number } from "@recoiljs/refine";
import { QuestionStatus } from "constant";
import { atom, selector } from "recoil";
import { syncEffect } from "recoil-sync";
import { FilterSelector, FilterSelectorOption } from "./FilterSelector";

const statuses = [
  QuestionStatus.Pending,
  QuestionStatus.Approved,
  QuestionStatus.Rejected,
  QuestionStatus.Deleted,
]

const options = statuses.map((status, index) => {
  const option: FilterSelectorOption = {
    label: QuestionStatus[status],
    value: index + 1
  }
  return option
})

const questionStatusAtom = atom<number>({
  key: "question_s",
  default: 1, // 从1开始
  effects: [syncEffect({ refine: number() })]
})

export const questionStatusQuery = selector<QuestionStatus[] | undefined>({
  key: "questionStatus",
  get: ({ get }) => {
    const value = get(questionStatusAtom)
    return value ? [statuses[value - 1]] : undefined
  }
})

export function QuestionStatusFilter() {
  return <FilterSelector
    placeholder="Select Status"
    state={questionStatusAtom}
    options={options}
  />
}