import { HStack, Spacer, Stack, useColorModeValue } from "@chakra-ui/react";
import React from "react";
import { Breadcrumbs } from "./Breadcrumbs";
import { ColorModeSwitcher } from "./ColorModeSwitcher";
import { SearchBar } from "./SearchBar";
import { UserMenu } from "./UserMenu";

// MainNav 顶部导航
export function NavBar() {
  const navBg = useColorModeValue("white", "gray.800");

  return (
    <Stack
      as="header"
      h="44px"
      w="100%"
      position="sticky"
      top={0}
      left={0}
      right={0}
      bg={navBg}
      zIndex={10}>
      <HStack mx={4} spacing={2} justifyContent="end" flex="1">
        <Breadcrumbs />
        <Spacer />
        <SearchBar />
        <ColorModeSwitcher />
        <UserMenu />
      </HStack>
    </Stack>
  );
}
