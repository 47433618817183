import { number } from "@recoiljs/refine";
import { DeviceStatus } from "constant";
import { atom, selector } from "recoil";
import { syncEffect } from "recoil-sync";
import { FilterSelector, FilterSelectorOption } from "./FilterSelector";

const statuses = [
  DeviceStatus.Normal,
  DeviceStatus.Banned,
  DeviceStatus.Underage,
];

const options = statuses.map((status, index) => {
  const option: FilterSelectorOption = {
    label: DeviceStatus[status],
    value: index + 1,
  };
  return option;
});

const deviceStatusAtom = atom<number>({
  key: "device_s",
  default: 0,
  effects: [syncEffect({ refine: number() })],
});

export const deviceStatusQuery = selector<DeviceStatus[] | undefined>({
  key: "deviceStatus",
  get: ({ get }) => {
    const value = get(deviceStatusAtom);
    return value ? [statuses[value - 1]] : undefined;
  },
});

export function DeviceStatusFilter() {
  return (
    <FilterSelector
      placeholder="Select Status"
      state={deviceStatusAtom}
      options={options}
    />
  );
}
