import { BioStatus } from "constant";
import { StatusColor } from "./color";
import { NewStatusTag } from "./NewStatusTag";

const colors: StatusColor[] = [
  { status: BioStatus.Approved, color: "green" },
  { status: BioStatus.Pending, color: "red" },
  { status: BioStatus.Rejected, color: "gray" },
  { status: BioStatus.Deleted, color: "gray" },
];

interface BIoStatusTagProps {
  status: BioStatus;
}

// export 
export function BioStatusTag(props: BIoStatusTagProps) {

  const getColor = () => {
    return colors.find(color => color.status as BioStatus === props.status)?.color;
  };

  return (
    <NewStatusTag
      title={BioStatus[props.status]}
      color={getColor()}
      isDeleted={props.status === (BioStatus.Deleted)} />
  );
}

