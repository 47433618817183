import {
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  TabPanelsProps,
  Tabs,
} from "@chakra-ui/react";
import { number } from "@recoiljs/refine";
import { TotalView } from "components";
import React, { Suspense } from "react";
import {
  atom,
  RecoilValueReadOnly,
  useRecoilState,
  useSetRecoilState,
} from "recoil";
import { syncEffect } from "recoil-sync";
import { pageAtom } from "state";

export interface CustomTabProps {
  title: string;
  totalState?: RecoilValueReadOnly<number>;
}

interface CustomTabsProps {
  tabs: CustomTabProps[];
}

export const tabAtom = atom<number>({
  key: "tab",
  default: 0,
  effects: [syncEffect({ refine: number() })],
});

export function CustomTabs(props: CustomTabsProps & TabPanelsProps) {
  const [tab, setTab] = useRecoilState(tabAtom);
  const setPage = useSetRecoilState(pageAtom);

  const handleChange = (value: number) => {
    setTab(value);
    setPage(1);
  };

  return (
    <Tabs
      isLazy
      index={tab}
      onChange={handleChange}>
      <TabList>
        {props.tabs.map((tab, index) => (
          <Tab key={index}>
            {tab.title}
            {tab.totalState && (
              <Suspense fallback={<>...</>}>
                <TotalView state={tab.totalState} />
              </Suspense>
            )}
          </Tab>
        ))}
      </TabList>

      <TabPanels>
        {React.Children.map(props.children, (child) => (
          <TabPanel px={0}>{child}</TabPanel>
        ))}
      </TabPanels>
    </Tabs>
  );
}
