export enum Platform {
  iOS,
  Android,
}

export enum PurchaseFrom {
  All = 0,
  iOS = 1,
  Android = 2,
  Console = 5,
}

export enum CacheKey {
  NeedForceRefreshIdToken = "needForceRefreshIdToken",
  EmailForSignIn = "emailForSignIn",
  LoginFrom = "loginFrom",
}

// 采用下划线编码方式
export enum SearchParam {
  Page = "page",
  Month = "month",
  Status = "status",
  Gender = "gender_tab",
  RiskStatus = "risk_status_tab",
  UID = "uid",
  ShortUUID = "s_uuid",
  UUID = "uuid",
  IMEI = "imei",
  PaymentType = "payment_type",
  IPVersion = "ip_version",
  NSFWType = "nsfw_type",
  Session = "session",
  TimeWindow = "time_window",
  PurchaseFrom = "purchase_from",
}

export enum DayjsKey {
  DatePickerDisplayFormat = "MMMM YYYY",
  DatePickerValueFormat = "YYYYMM",
  BirthdayValueFormat = "YYYY-MM-DD", // 2022-12-25
  BirthdayFormat = "MMM DD, YYYY", // Dec 25, 2022
}
