import { fetchMessages, fetchSessions } from "api";
import { MessagesResponse, Resp, SessionsResponse } from "dto";
import { atom, selector } from "recoil";
import { setRecoil } from "recoil-nexus";
import { getErrorMessage } from "utils/functions";
import { errorState } from "./common";
import { timeWindowRangeQuery } from "./search";
import { userInfoQuery } from "./user";

export const currentSessionIdAtom = atom<string>({
  key: "currentSessionId",
  default: "",
});

export const sessionsRespQuery = selector<Resp<SessionsResponse> | undefined>({
  key: "sessions/resp",
  get: async ({ get }) => {
    const userInfo = get(userInfoQuery);

    if (!userInfo) return;

    const timestamps = get(timeWindowRangeQuery);

    try {
      const resp = await fetchSessions({
        imId: userInfo.uuid,
        startTime: timestamps.createdStart,
        endTime: timestamps.createdEnd,
      });
      return resp.data;
    } catch (error) {
      setRecoil(errorState, getErrorMessage(error));
    }
  },
});

export const sessionsQuery = selector<SessionsResponse | undefined>({
  key: "sessions",
  get: ({ get }) => {
    return get(sessionsRespQuery)?.data;
  },
});

export const messagesRespQuery = selector<Resp<MessagesResponse> | undefined>({
  key: "messages/resp",
  get: async ({ get }) => {
    const userInfo = get(userInfoQuery);
    const currentSessionId = get(currentSessionIdAtom);

    if (!userInfo || currentSessionId === "") return;

    const timestamps = get(timeWindowRangeQuery);

    try {
      const resp = await fetchMessages({
        fromIMId: userInfo.uuid,
        toIMId: currentSessionId,
        startTime: timestamps.createdStart,
        endTime: timestamps.createdEnd,
      });
      return resp.data;
    } catch (error) {
      setRecoil(errorState, getErrorMessage(error));
    }
  },
});

export const messagesQuery = selector<MessagesResponse | undefined>({
  key: "messages",
  get: ({ get }) => {
    return get(messagesRespQuery)?.data;
  },
});
