import { number } from "@recoiljs/refine";
import { RiskStatus } from "constant";
import { atom, selector } from "recoil";
import { syncEffect } from "recoil-sync";
import { FilterSelector, FilterSelectorOption } from "./FilterSelector";

const statuses = [
  RiskStatus.Normal,
  RiskStatus.Confirmed,
  RiskStatus.Suspended,
  RiskStatus.Banned,
  RiskStatus.Underage,
];

const options = statuses.map((status, index) => {
  const option: FilterSelectorOption = {
    label: RiskStatus[status],
    value: index + 1,
  };
  return option;
});

const riskStatusSelectorValueAtom = atom<number>({
  key: "risk_status",
  default: 0,
  effects: [syncEffect({ refine: number() })],
});

export const riskStatusQuery = selector<RiskStatus[] | undefined>({
  key: "riskStatuses",
  get: ({ get }) => {
    const index = get(riskStatusSelectorValueAtom);
    return index ? [statuses[index - 1]] : undefined;
  },
});

export function RiskStatusFilter() {
  return (
    <FilterSelector
      placeholder="Risk Status"
      state={riskStatusSelectorValueAtom}
      options={options}
    />
  );
}
