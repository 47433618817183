import {
  Badge,
  Box,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  useDisclosure,
} from "@chakra-ui/react";
import { RiskStatus } from "constant";
import { Suspicious, User } from "dto";
import { useRecoilValue } from "recoil";
import { updatedProfilesAtom } from "state";
import { timestampMsToDateStr } from "utils/functions";

type RiskStatusBadgeProps = {
  user: User;
  suspicious?: Suspicious[];
};

export function RiskStatusBadge(props: RiskStatusBadgeProps) {
  const { user, suspicious } = props;

  let badgeColor = undefined;
  let variant: string | undefined = "solid";

  const { isOpen, onOpen, onClose } = useDisclosure();
  const updatedProfiles = useRecoilValue(updatedProfilesAtom);

  const targetProfile =
    updatedProfiles.find((item) => item.uid === user.uid) ?? user;

  switch (targetProfile.riskStatus) {
    case RiskStatus.Normal:
      badgeColor = "gray";
      variant = undefined;
      break;
    case RiskStatus.Confirmed:
      badgeColor = "green";
      break;
    case RiskStatus.Suspended:
      badgeColor = "red";
      break;
    case RiskStatus.Banned:
      badgeColor = "gray";
      break;
    case RiskStatus.Underage:
      badgeColor = "yellow";
  }

  return (
    <Box>
      <Badge
        pointerEvents={"auto"}
        colorScheme={badgeColor}
        variant={variant}
        cursor="pointer"
        onClick={onOpen}>
        {RiskStatus[targetProfile.riskStatus]}
      </Badge>

      <Modal
        isOpen={isOpen}
        onClose={onClose}
        autoFocus={false}
        scrollBehavior="inside"
        size={"xl"}>
        <ModalOverlay />
        <ModalContent>
          <ModalCloseButton />
          <ModalHeader>Suspicious</ModalHeader>
          <ModalBody>
            <Table variant="simple">
              <Thead>
                <Tr>
                  <Th>Reason</Th>
                  <Th>At</Th>
                </Tr>
              </Thead>
              <Tbody>
                {suspicious?.map((item, index) => (
                  <Tr key={index}>
                    <Td>{item.reason}</Td>
                    <Td>{timestampMsToDateStr(item.createdAt)}</Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
          </ModalBody>
        </ModalContent>
      </Modal>
    </Box>
  );
}
