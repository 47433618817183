import { Platform } from "constant";
import { Resp } from "dto";

export enum ReleaseStatus {
  Published,
  "In Review",
}

export interface VersionWhitelistSetting {
  channel: Platform;
  version: string;
  isOpen: ReleaseStatus;
}

export type VersionWhitelistSettingResp = Resp<VersionWhitelistSetting[]>;
