import { useToast } from "@chakra-ui/react";
import { useEffect } from "react";
import { useRecoilState } from "recoil";
import { errorState } from "state";

export function ErrorToast() {
  const toast = useToast();
  const [error, setError] = useRecoilState(errorState);

  useEffect(() => {
    error &&
      toast({
        title: error,
        status: "error",
        onCloseComplete: () => setError(undefined),
      });
  }, [toast, error, setError]);

  return <></>;
}
