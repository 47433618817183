import {
  Heading,
  HStack,
  Image,
  Stack,
  useColorModeValue
} from "@chakra-ui/react";
import { logo } from "assets";

export function Logo() {
  const bg = useColorModeValue("purple.100", "purple.700");

  return (
    <Stack
      h="44px"
      position="sticky"
      top={0}
      left={0}
      right={0}
      bg={bg}
      zIndex={2}
      justifyContent="center">
      <HStack px={4}>
        <Image w="24px" src={logo} borderRadius={4} />
        <Heading size="md">{process.env.REACT_APP_NAME ?? ""}</Heading>
      </HStack>
    </Stack>
  );
}
