// 英尺转身高
export const inchesToHeightInFeetAndInches = (heightInches: number): string => {

  // 身高为0，返回--
  if (heightInches === 0) return "--";

  // 计算feet
  const feet = Math.floor(heightInches / 12);

  // 计算inches
  const inches = heightInches % 12;

  return `${feet}'${inches}''`;
};

// 厘米转身高
const cmToInchesRate = 0.393700787;
export const cmToHeightInFeetAndInches = (heightCm: number): string => {

  if (heightCm === 0) return "--";

  // 将cm换算为inches，使用+将string转换为number
  const inches: number = +(heightCm * cmToInchesRate).toFixed(0);

  // 调用inchesToHeightInFeetAndInches将inches格式化为 1'23'' 身高格式
  return inchesToHeightInFeetAndInches(inches);
};