import { Spinner, Table, Tbody, Td, Th, Thead, Tr } from "@chakra-ui/react";
import { CustomTr } from "components";
import { Suspense } from "react";
import { useRecoilValue } from "recoil";
import { timestampMsToDateStr } from "utils/functions";
import { ISP } from "./isp.interface";
import { ispsQuery } from "./isp.state";
import { UnblockIspButton } from "./isp.unblock-button";

export function ISPsTableWrapper() {
  return (
    <Suspense fallback={<Spinner />}>
      <ISPsTable />
    </Suspense>
  );
}

function ISPsTable() {
  const isps = useRecoilValue(ispsQuery(""));
  return (
    <Table variant="simple">
      <Thead>
        <Tr>
          <Th>#</Th>
          <Th>ISP</Th>
          <Th>Count</Th>
          <Th>Created At</Th>
          <Th>Updated At</Th>
          <Th></Th>
        </Tr>
      </Thead>

      <Tbody>
        {isps.map((isp, index) => (
          <ISPsTableBody
            key={index}
            data={isp}
          />
        ))}
      </Tbody>
    </Table>
  );
}

function ISPsTableBody({ data }: { data: ISP }) {
  return (
    <CustomTr>
      <Td>{data.id}</Td>
      <Td>{data.isp}</Td>
      <Td>{data.quantity}</Td>
      <Td>{timestampMsToDateStr(data.createdAt)}</Td>
      <Td>{timestampMsToDateStr(data.updatedAt)}</Td>
      <Td w={1}>
        <UnblockIspButton id={data.id} />
      </Td>
    </CustomTr>
  );
}
