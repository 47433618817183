import { axiosInstance } from "api/common/axios";
import { ISPsResp, SearchIspParams } from "./isp.interface";

const BLOCK_ISP = "/isp/block/add";
const UNBLOCK_ISP = "/isp/block/del";
const SEARCH_ISP = "/isp/block/search";

// 封禁ISP
export const blockIsp = (isp: string) => {
  return axiosInstance.post(BLOCK_ISP, { isp });
};
// 解禁ISP
export const unblockIsp = (id: number) => {
  return axiosInstance.post(UNBLOCK_ISP, { id });
};
// 获取ISP封禁列表
export const searchIsp = (params: SearchIspParams) => {
  return axiosInstance.post<ISPsResp>(SEARCH_ISP, params);
};
