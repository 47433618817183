import {
  HStack,
  IconButton,
  Input,
  InputGroup,
  InputLeftElement,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  Select,
  useDisclosure,
} from "@chakra-ui/react";
import { Controller, useForm } from "react-hook-form";
import { BsSearch } from "react-icons/bs";
import { useNavigate } from "react-router-dom";
import { useResetRecoilState, useSetRecoilState } from "recoil";
import { RouterURI } from "router/RouterURI";
import { imeiAtom, pageAtom, shortUUIDAtom, uidAtom, uuidAtom } from "state";

interface SearchBarForm {
  type: string;
  keywords: string;
}

enum SearchType {
  ShortUUID,
  UUID,
  UID,
  IMEI,
}

const searchTypes = [
  SearchType.ShortUUID,
  SearchType.UUID,
  SearchType.UID,
  SearchType.IMEI,
];

export function SearchBar() {
  const navigate = useNavigate();

  const { isOpen, onOpen, onClose } = useDisclosure();
  const { control, handleSubmit } = useForm<SearchBarForm>();

  const setShortUUID = useSetRecoilState(shortUUIDAtom);
  const setUUID = useSetRecoilState(uuidAtom);
  const setUID = useSetRecoilState(uidAtom);
  const setIMEI = useSetRecoilState(imeiAtom);

  const resetPage = useResetRecoilState(pageAtom);
  const resetShortUUID = useResetRecoilState(shortUUIDAtom);
  const resetUUID = useResetRecoilState(uuidAtom);
  const resetUID = useResetRecoilState(uidAtom);
  const resetIMEI = useResetRecoilState(imeiAtom);

  const handleSearch = handleSubmit(async (data) => {
    const { type, keywords } = data;
    const searchType = parseInt(type) as SearchType;

    onClose();

    // 清空其他搜索参数
    resetPage();
    resetShortUUID();
    resetUUID();
    resetUID();
    resetIMEI();

    switch (searchType) {
      case SearchType.ShortUUID:
        setShortUUID(keywords);
        navigate(RouterURI.Users);
        return;
      case SearchType.UUID:
        setUUID(keywords);
        navigate(RouterURI.Users);
        return;
      case SearchType.UID:
        setUID(parseInt(keywords));
        navigate(RouterURI.Users);
        return;
      case SearchType.IMEI:
        setIMEI(keywords);
        navigate(RouterURI.Devices);
        return;
    }
  });

  return (
    <>
      <IconButton
        aria-label="Search"
        variant="ghost"
        icon={<BsSearch />}
        onClick={onOpen}
      />

      <Modal
        isOpen={isOpen}
        onClose={onClose}
        size="xl">
        <ModalOverlay />

        <ModalContent>
          <ModalBody>
            <form onSubmit={handleSearch}>
              <HStack>
                <Controller
                  name="type"
                  defaultValue={SearchType.ShortUUID.toString()}
                  control={control}
                  render={({ field }) => (
                    <Select
                      size="lg"
                      w="144px"
                      variant="flushed"
                      {...field}>
                      {searchTypes.map((type, index) => (
                        <option
                          value={type.toString()}
                          key={index}>
                          {SearchType[type]}
                        </option>
                      ))}
                    </Select>
                  )}
                />

                <Controller
                  name="keywords"
                  control={control}
                  defaultValue={""}
                  render={({ field }) => (
                    <InputGroup
                      size="lg"
                      flex={1}
                      variant="flushed"
                      {...field}>
                      <InputLeftElement
                        pointerEvents="none"
                        children={<BsSearch color="gray.300" />}
                      />

                      <Input
                        type="text"
                        placeholder="Search"
                      />
                    </InputGroup>
                  )}
                />
              </HStack>
            </form>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
}
