import { HStack, StackProps, useColorModeValue } from "@chakra-ui/react";

export function HeaderStack(props: StackProps) {
  const headerBg = useColorModeValue("white", "gray.800");

  return (
    <HStack position="sticky" top="44px" zIndex={9} w="100%" bg={headerBg} py={2} spacing={4}>
      {props.children}
    </HStack>
  );
}
