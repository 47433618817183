import {
  Spacer,
  Spinner,
  Stack,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import { CustomTr, HeaderStack, MainHeader, Pagination } from "components";
import {
  AddWordButton,
  DeleteKeywordButton,
  Word,
  WordType,
  wordsQuery,
  wordsTotalQuery,
} from "features/highlight-words";
import { Suspense } from "react";
import { useRecoilValue } from "recoil";
import { timestampMsToDateStr } from "utils/functions";

export function HighlightWordsPage() {
  return (
    <Stack>
      {/* Header */}
      <HeaderStack>
        <MainHeader totalState={wordsTotalQuery} />
        <Spacer />

        <AddWordButton />
      </HeaderStack>

      <Suspense fallback={<Spinner />}>
        <HighlightWordsTable />
      </Suspense>
      <Pagination state={wordsTotalQuery} />
    </Stack>
  );
}

export function HighlightWordsTable() {
  const words = useRecoilValue(wordsQuery);

  return (
    <Table variant="simple">
      <Thead>
        <Tr>
          <Th>#</Th>
          <Th>Type</Th>
          <Th>Content</Th>
          <Th>Created At</Th>
          <Th></Th>
        </Tr>
      </Thead>

      <Tbody>
        {words.map((word, index) => (
          <HighlightWordsTableItem
            key={index}
            data={word}
          />
        ))}
      </Tbody>
    </Table>
  );
}

export function HighlightWordsTableItem({ data }: { data: Word }) {
  return (
    <CustomTr>
      <Td>{data.id}</Td>
      <Td>{WordType[data.type]}</Td>
      <Td>{data.word}</Td>
      <Td>{timestampMsToDateStr(data.createdAt)}</Td>
      <Td w={1}>
        <DeleteKeywordButton id={data.id} />
      </Td>
    </CustomTr>
  );
}
