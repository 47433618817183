import { number } from "@recoiljs/refine";
import { DayjsKey, SearchParam } from "constant";
import dayjs from "dayjs";
import { TimestampParams } from "dto";
import { atom, selector } from "recoil";
import { syncEffect } from "recoil-sync";
import {
  generateDateOptions,
  getTheStartAndEndTimestamps,
} from "utils/functions";
import { FilterSelector, FilterSelectorOption } from "./FilterSelector";

const dateOptions = generateDateOptions(
  "October 2017",
  dayjs().format(DayjsKey.DatePickerDisplayFormat),
  DayjsKey.DatePickerDisplayFormat
).map((opt) => opt as FilterSelectorOption);

const monthAtom = atom<number>({
  key: SearchParam.Month,
  default: +dayjs().format(DayjsKey.DatePickerValueFormat),
  effects: [syncEffect({ refine: number() })],
});

export const timestampsQuery = selector<TimestampParams>({
  key: "timestamps",
  get: ({ get }) => {
    const month = get(monthAtom);
    return getTheStartAndEndTimestamps(month);
  },
});

export function DateFilter() {
  return (
    <FilterSelector
      state={monthAtom}
      options={dateOptions}
    />
  );
}
