import { Link, LinkProps, Text } from "@chakra-ui/react";
import { DeviceIMIERouteURIType, SearchParam } from "constant";
import { Link as ReactRouterLink } from "react-router-dom";
import { RouterURI } from "router/RouterURI";

export function IMEILink(props: IMEILinkProps & LinkProps) {
  const { imei, action, children, ...restProps } = props;

  let uri: string;
  switch (action) {
    case DeviceIMIERouteURIType.Devices:
      uri = RouterURI.Devices;
      break;
    case DeviceIMIERouteURIType.Users:
      uri = RouterURI.Users;
      break;
    default:
      uri = RouterURI.Devices;
      break;
  }

  return (
    <Link
      as={ReactRouterLink}
      to={{
        pathname: uri,
        // 使用key="value"，方式避免参数解析失败的bug
        search: `${SearchParam.IMEI}="${props.imei}"`,
      }}
      isExternal
      onClick={(e) => e.stopPropagation()}
      {...restProps}>
      {!children ? <Text>{imei}</Text> : children}
    </Link>
  );
}

interface IMEILinkProps {
  imei: string;
  action?: DeviceIMIERouteURIType;
}
