import {
  Box,
  Spinner,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import {
  IAPProductTransactionType,
  ProductRecordDirection,
  ProductRecordType,
} from "constant";
import { IAPProductRecord } from "dto";

import { CustomTr, UserId } from "components";
import { Suspense } from "react";
import { useRecoilValue } from "recoil";
import { productTransactionsQuery } from "state";
import { timestampMsToDateStr } from "utils/functions";

export function ProductRecordsTable() {
  return (
    <Suspense fallback={<Spinner />}>
      <Box overflowX="scroll">
        <Table variant="simple">
          <ProductRecordsTableHead />
          <ProductRecordsTableBody />
        </Table>
      </Box>
    </Suspense>
  );
}

function ProductRecordsTableHead() {
  return (
    <Thead>
      <Tr>
        <Th>#</Th>
        <Th>User</Th>
        <Th>Type</Th>
        <Th>Change</Th>
        <Th>After</Th>
        <Th>Note</Th>
        <Th>Created At</Th>
        <Th>Updated At</Th>
      </Tr>
    </Thead>
  );
}

function ProductRecordsTableBody() {
  const productRecords = useRecoilValue(productTransactionsQuery);
  return (
    <Tbody>
      {productRecords.map((productRecord, index) => (
        <TransferRecordsTableCell
          key={index}
          productRecord={productRecord}
        />
      ))}
    </Tbody>
  );
}

interface TransferRecordsTableCellProps {
  productRecord: IAPProductRecord;
}

function TransferRecordsTableCell(props: TransferRecordsTableCellProps) {
  const { productRecord } = props;

  const _getDirectionIcon = () => {
    if (productRecord.direction === ProductRecordDirection.In) {
      return <Text color="green">+ {productRecord.quantity}</Text>;
    } else if (productRecord.direction === ProductRecordDirection.Out) {
      return <Text color="red">- {productRecord.quantity}</Text>;
    } else {
      return <Text color="yellow">? {productRecord.quantity}</Text>;
    }
  };

  function _getProductRecordType(type: IAPProductTransactionType) {
    let productRecordType: ProductRecordType;

    switch (type) {
      case IAPProductTransactionType.BoostPurchase:
      case IAPProductTransactionType.SuperFlipPurchase:
        productRecordType = ProductRecordType.Purchase;
        break;
      case IAPProductTransactionType.BoostConsume:
      case IAPProductTransactionType.SuperFlipConsume:
        productRecordType = ProductRecordType.Consume;
        break;
      case IAPProductTransactionType.BoostReward:
      case IAPProductTransactionType.SuperFlipReward:
        productRecordType = ProductRecordType.Reward;
        break;
      default:
        productRecordType = ProductRecordType.Unknown;
    }

    return ProductRecordType[productRecordType];
  }

  return (
    <CustomTr>
      <Td>{productRecord.id}</Td>
      <Td>
        <UserId uid={productRecord.uid} />
      </Td>
      <Td>{_getProductRecordType(productRecord.type)}</Td>
      <Td>{_getDirectionIcon()}</Td>
      <Td>{productRecord.subTitle}</Td>
      <Td>{productRecord.externalId}</Td>
      <Td>{timestampMsToDateStr(productRecord.createdAt)}</Td>
      <Td>{timestampMsToDateStr(productRecord.updatedAt)}</Td>
    </CustomTr>
  );
}
