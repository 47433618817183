import { Gender } from "constant";
import _ from "lodash";

export const getSeekingIn = (genders: Gender[]) => {
  if (genders.length > 0) {
    const _genders = _.cloneDeep(genders);
    _genders.sort((a, b) => a - b);

    return _genders
      .map((gender) => {
        return Gender[gender];
      })
      .join(", ");
  } else {
    return "--";
  }
};
