import { Box, Divider, Flex } from "@chakra-ui/react";
import { ChannelsView } from "./ChannelsView";
import { MessagesView } from "./MessagesView";

export function ChatsPage() {
  return (
    <Flex h="100vh">
      {/* left - sidebar */}
      <Box overflowY="auto">
        <ChannelsView />
      </Box>

      <Divider orientation="vertical" />

      {/* right - content */}
      <Box overflowY="auto" flex="1" >
        <MessagesView />
      </Box>
    </Flex >
  )
}

