import { Box, Stack, StackProps, Text, TextProps } from "@chakra-ui/react";

export function CustomStatValue(props: TextProps) {
  const { children, ...restProps } = props;

  return (
    <Box
      fontSize="lg"
      {...restProps}>
      {children}
    </Box>
  );
}

export function CustomStatLabel(props: TextProps) {
  const { children, ...restProps } = props;
  return (
    <Text
      fontSize="xs"
      opacity={0.8}
      textTransform="uppercase"
      {...restProps}>
      {children}
    </Text>
  );
}

export function CustomStat(props: StackProps) {
  const { children, ...restProps } = props;

  return (
    <Stack
      alignItems="left"
      spacing={2}
      {...restProps}>
      {children}
    </Stack>
  );
}
