export enum IPVersion {
  IPv4,
  IPv6,
}

export enum CIDRActiveStatus {
  Disabled,
  Enabled,
}

export enum CIDRDetectStatus {
  Invalided,
  Valid,
  Rejected,
}
