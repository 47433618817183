import { selector } from "recoil";
import { pageAtom, pageSizeAtom, requestIdState } from "state";
import { listWord, searchWord } from "./word.api";
import { WordType } from "./word.enum";

export const wordsRespQuery = selector({
  key: "words/resp",
  get: async ({ get }) => {
    get(requestIdState);
    const page = get(pageAtom);
    const pageSize = get(pageSizeAtom);
    try {
      const resp = await searchWord({
        page,
        pageSize,
      });
      return resp.data;
    } catch (error) {
      // TODO: handle error
    }
  },
});

export const wordsQuery = selector({
  key: "words",
  get: ({ get }) => {
    const resp = get(wordsRespQuery);

    if (resp?.error) {
      // handle error
    }

    return resp?.data ?? [];
  },
});

export const wordsTotalQuery = selector({
  key: "words/total",
  get: ({ get }) => {
    const resp = get(wordsRespQuery);

    if (resp?.error) {
      // handle error
    }

    return resp?.page?.total ?? 0;
  },
});

export const listWordsResp = selector({
  key: "listWords/resp",
  get: async () => {
    try {
      const resp = await listWord();
      return resp.data;
    } catch (error) {
      // TODO: handle error
    }
  },
});

export const listWordsQuery = selector({
  key: "listWords",
  get: async ({ get }) => {
    const resp = get(listWordsResp);
    if (resp?.error) {
      // handle error
    }
    return resp?.data ?? [];
  },
});

export const spamWordsQuery = selector({
  key: "words/spam",
  get: ({ get }) => {
    const words = get(listWordsQuery);
    return words.filter((w) => w.type === WordType.Spam);
  },
});

export const offensiveWordsQuery = selector({
  key: "words/offensive",
  get: ({ get }) => {
    const words = get(listWordsQuery);
    return words.filter((w) => w.type === WordType.Offensive);
  },
});
