import { axiosInstance } from "api/common/axios";
import { BlockedImagesResp, SearchBlockedImageParams } from "./image.interface";

const BLOCK_IMAGE = "/media/black/add";
const UNBLOCK_IMAGE = "/media/black/del";
const SEARCH_IMAGE = "/media/black/search";

// 封禁ISP
export const blockImage = (mediaId: number) => {
  return axiosInstance.post(BLOCK_IMAGE, { mediaId });
};

// 解禁ISP
export const unblockImage = (mediaId: number) => {
  return axiosInstance.post(UNBLOCK_IMAGE, { mediaId });
};

// 获取ISP封禁列表
export const searchBlockedImages = (params: SearchBlockedImageParams) => {
  return axiosInstance.post<BlockedImagesResp>(SEARCH_IMAGE, params);
};
