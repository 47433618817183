export enum IAPProductType {
  Subscription = 1,
  Boost = 2,
  Super_Flip = 3,
}

export enum ProductRecordDirection {
  In = 1,
  Out = 2,
}

export enum IAPProductTransactionType {
  BoostPurchase = 1,
  SubscriptionPurchase = 2,
  BoostConsume = 3,
  BoostReward = 4,
  SuperFlipPurchase = 5,
  SuperFlipConsume = 6,
  SuperFlipReward = 7,
}

// 流水记录展示类型
export enum ProductRecordType {
  Unknown = 0,
  Purchase = 1,
  Reward = 2,
  Consume = 3,
}

export enum MemberLevel {
  Normal = 0,
  Plus = 1,
  Normal_v2 = 2,
  Elite = 3,
  PlusToElite = 4,
}
