
import { MediaStatus } from "constant";
import { StatusColor } from "./color";
import { NewStatusTag } from "./NewStatusTag";

const colors: StatusColor[] = [
  { status: MediaStatus.Approved, color: "green" },
  { status: MediaStatus.Pending, color: "red" },
  { status: MediaStatus.Rejected, color: "gray" },
  { status: MediaStatus.Deleted, color: "gray" },
];

interface MediaStatusTagProps {
  status: MediaStatus;
}

// export 
export function MediaStatusTag(props: MediaStatusTagProps) {

  const getColor = () => {
    return colors.find(color => color.status as MediaStatus === props.status)?.color;
  };

  return (
    <NewStatusTag
      title={MediaStatus[props.status]}
      color={getColor()}
      isDeleted={props.status === (MediaStatus.Deleted)} />
  );
}

