// 媒体类型
export enum MediaType {
  Photo = 1,
  Thumb = 2,
  Voice = 3,
  VerifyImage = 4,
}

// 媒体状态
export enum MediaStatus {
  Rejected = -1,
  Pending = 0,
  Approved = 1,
  Deleted = 99,
}

export enum AutoStatus {
  Pending = 1,
  Checked = 2,
}

// ========== Verify Gesture ==========
export enum VerifyGesture {
  Yeah,
  Like,
  Ok,
  Hi,
}

const gestures = [
  { label: "👋", gesture: VerifyGesture.Yeah },
  { label: "👍", gesture: VerifyGesture.Like },
  { label: "👌", gesture: VerifyGesture.Ok },
  { label: "✋", gesture: VerifyGesture.Hi },
];

export function getGestureLabel(gesture?: VerifyGesture) {
  return gestures.find((item) => item.gesture === gesture)?.label;
}
