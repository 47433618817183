import {
  Avatar,
  Divider,
  HStack,
  Stack,
  Tag,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import { Gender, RelationshipType } from "constant";
import { RelationshipResps } from "dto";

import { UserIdWrapper } from "components";
import { BsArrowDown, BsArrowUp } from "react-icons/bs";
import { useRecoilValue } from "recoil";
import { messagesQuery, userInfoQuery } from "state";

export function MessagesViewHeader() {
  const grayBg = useColorModeValue("white", "gray.700");

  return (
    <Stack
      position="sticky"
      zIndex={98}
      top="0px"
      bg={grayBg}>
      <ChannelMemberInfo />
      <Divider />
    </Stack>
  );
}

function ChannelMemberInfo() {
  const messages = useRecoilValue(messagesQuery);

  const userInfo = messages?.userInfo;

  if (!userInfo) {
    return <Text>User Info: NULL</Text>;
  }

  return (
    <HStack py={2}>
      <UserIdWrapper
        uuid={userInfo.toUid}
        isExternal={true}>
        <Avatar
          name={userInfo.city}
          src={userInfo.avatarUrl}
        />
      </UserIdWrapper>
      <Stack spacing={0}>
        <HStack>
          <Text>
            {Gender[userInfo.gender]}. {userInfo.age}
          </Text>
          <RelationshipTags relationships={userInfo.relationshipResps} />
        </HStack>
        <Text fontSize="12px">{userInfo.city}</Text>
      </Stack>
    </HStack>
  );
}

function RelationshipTags(props: RelationshipTagsProps) {
  return (
    <HStack>
      {props.relationships.map((item, i) => (
        <RelationshipTag
          key={i}
          relationship={item}
        />
      ))}
    </HStack>
  );
}

interface RelationshipTagsProps {
  relationships: RelationshipResps[];
}

function RelationshipTag(props: RelationshipTagProps) {
  const grayBg = useColorModeValue("gray.100", "gray.600");
  const userInfo = useRecoilValue(userInfoQuery);

  if (!userInfo) return <></>;

  const outgoing = userInfo.uuid === props.relationship.fromIMId;

  return (
    <Tag
      bg={outgoing ? "teal" : grayBg}
      color={outgoing ? "white" : ""}>
      {RelationshipType[props.relationship.type]}
      {outgoing ? <BsArrowUp /> : <BsArrowDown />}
    </Tag>
  );
}

interface RelationshipTagProps {
  relationship: RelationshipResps;
}
